
//package imports
import React, { useState ,useEffect} from 'react'


//component imports
import MuiCustomTabel from '../../utils/reusableComponents/MuiCustomTabel';
import { ReusableSearch } from '../../utils/reusableComponents/ReusableSearch';
import { clientProject1 } from '../../utils/constants/TableData';
import BreadcrumbsHeader from '../../utils/reusableComponents/BreadCrumbsHeader';
import { getProjectsList } from '../../services/apiServices/GetServices';
import { getData } from '../../utils/AsyncStorage';


const Project = (props) => {

    const { location } = props;
    const [data, setData] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const userData = getData("client_id"); 
    const parsedUserData = JSON.parse(userData); 
    const [filteredData,setFilteredData] = useState([])
    const [projectsData,setProjectsData] = useState([])

    const id = parsedUserData
     
   

    useEffect(() => {
        if(id != undefined){
           callProjectlistApi()  
        }
    
      }, []);


      useEffect ( () => {
        if(filteredData.length != 0) {
          setData(filteredData)
        }
      },[])

    const heading = {
        heading1: "Projects",
        heading2: props?.location?.state?.title
    }
    const pathName = `${location?.pathname}/particularList`


     //function to call list api
   const callProjectlistApi =  async () => {
    const res  = await getProjectsList(`admin/project/${id}`)
    if(res?.success){
        setFilteredData(res?.data)
        setProjectsData(res?.data)
    }else{
     console.log("res",res?.message)
    }
 }



 //function to handle date format.
 const handleDateFormatSearch = (dateValue) => {

    let date = new Date(dateValue);
    let day =  date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `-${day}/${month}/${date.getFullYear()}`
    return dateMDY
  }


 const handleSearch = (val) => {


    const searchVal = val
    if(searchVal === ""){
        callProjectlistApi()  
       }else{
        setSearchTerm(searchVal);
        const filterdata = projectsData.filter((item) => {
          if (item?.Project_name.toLowerCase()
          
              .includes(searchVal.toLowerCase()) || handleDateFormatSearch(item?.Start_date)
              .includes(searchVal) || handleDateFormatSearch(item?.End_date)
              .includes(searchVal) ) { return item; }
      })
    
    //   setData(filterdata);
      setFilteredData(filterdata)
       }
}

const handleSearchClear = () => {
    callProjectlistApi();
  }
    return (
        <div class="flex flex-col ml-10 mr-4 w-full ">
            <div class="flex mt-2 self-start">
                <BreadcrumbsHeader heading1={"Projects List"}

                />
            </div>
            <div class="flex justify-between my-2  flex-row w-full  ">

                <ReusableSearch onChange={handleSearch} onClear = {handleSearchClear} />

            </div>
            <div class="w-full h-4/5">
                <MuiCustomTabel
                    path={pathName}
                    name={heading}
                    data={filteredData}
                    columnHeader={clientProject1}
                />
            </div>

        </div>
    )
}

export default Project