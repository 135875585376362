
import {
    CalendarIcon,
    FolderIcon,
    HomeIcon,
    UserGroupIcon,
    CogIcon
  } from "@heroicons/react/24/outline";

  import homeIcon from '../../assests/icons/HomeIcon.svg'
import dataIcon from '../../assests/icons/DataIcon.svg'
import dataIconChange from '../../assests/icons/masterdata.svg'
import projectIconChange from "../../assests/icons/ProjectsIcon.svg"
import projectIconAdmin from "../../assests/icons/Project.svg"
import cilentIcon from '../../assests/icons/clientIcon.svg'
import settingIcon from '../../assests/icons/SettingIcon.svg'
import signOutIcon from '../../assests/icons/SignOutIcon.svg'
import homeOnChangeIcon from '../../assests/icons/onChangeHome.svg'
import onChangeClientIcon from '../../assests/icons/onChangeClientIcon.svg'
import projectIcon from '../../assests/icons/projectIcon.svg'
import supportIcon from "../../assests/icons/support.svg"


export  const navItemsPanel =   [
    {
      label: "Dashboard",
      href:`/panel/dashboard`,
      icon: homeIcon ,
      iconOnchange: homeOnChangeIcon
    },
    {
      label: "Master Data",
      href:  `/panel/masterData`,
      icon: dataIcon ,
      iconOnchange: dataIconChange
    },
    {
      label: "Clients",
      href:  `/panel/clients`,
      icon: cilentIcon,
      iconOnchange: onChangeClientIcon
    },
    {
      label: "Projects",
      href:  `/panel/allProjects`,
      icon: projectIconAdmin,
      iconOnchange: projectIconChange
    },
    {
      label: "Settings",
      href:  `/panel/settings`,
      icon: settingIcon ,
      iconOnchange: settingIcon
    },
    // {
    //   label: "Signout",
    //   href:   `/panel/signout`,
    //   icon: signOutIcon ,
    //   iconOnchange: signOutIcon
      
    // }
  ];


  export  const navItemsClients =   [
    {
      label: "Dashboard",
      href:`/client/clientDashboard`,
      icon: homeIcon ,
      iconOnchange: homeOnChangeIcon
    },
    {
      label: "Projects",
      href:  `/client/projects`,
      icon: projectIcon ,
      iconOnchange: projectIcon
    },
    {
      label: "Settings",
      href:  `/client/settings`,
      icon: settingIcon ,
      iconOnchange: settingIcon
    },
    {
      label: "Support",
      href:  `/client/clientSupport`,
      icon: supportIcon ,
      iconOnchange: supportIcon
    },
    // {
    //   label: "Signout",
    //   href:   `/panel/signout`,
    //   icon: signOutIcon ,
    //   iconOnchange: signOutIcon
      
    // }
  ];