import React,{useState,useEffect} from 'react'
import Sidebar from './sidebar'
import classNames from "classnames";
import { withRouter,useHistory } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronDown, faChevronUp, faL } from '@fortawesome/free-solid-svg-icons'
import CustomButton from '../../utils/reusableComponents/CustomButton';
import creatIcon from '../../assests/icons/BoardIcon.svg'
import tradeImg from '../../assests/images/trade-icon.png'
import ModalWithTextFields from '../../utils/reusableComponents/ModalWithTextFields';
import { CustomModal } from '../../utils/reusableComponents/CustomModal';
import { sample,columnsClient,columnsDashBoard,columnsTested} from '../../utils/constants/TableData';
import {Chart,ArcElement} from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getDashboardDetails ,getProjectsList} from '../../services/apiServices/GetServices';
import { getTestedResults } from '../../services/apiServices/PostServices';
import MuiCustomTabel from '../../utils/reusableComponents/MuiCustomTabel';
Chart.register(ChartDataLabels);
Chart.register(ArcElement);

const Dashboard = (props) => {
  const history = useHistory();
  const [showModal, setshowModal] = useState(false);
  const { location } = props;
  const [dataArray,setDataArray]=useState([])
  const [change,setChange] = useState(false)
  const [endSubscription,setEndSubscription]=useState(false)
  const [tested,setTested] = useState(false)
  const [projectDeactiveated,setProjectsDeactivated] = useState([])
  const [projectActive , setProjectActive] = useState([])
  const [testedData,setTestedData]=useState([])

  const heading = "Clients List" 
  const pathName = `/panel/clients/clientProject`


const projectDeactivatedCount = projectDeactiveated.length

  const handleClose = () => {
    setshowModal(false)
  }

  const onCreat = () => {
    callDashboardApi()
  }


  useEffect(() => {
    callDashboardApi()
    callProjectsEnded()
    callProjectlistApi()
    callTestedResult()
  }, []);




  //function to handle date format.
  const handleDateFormat = (dateValue) => {

    let date = new Date(dateValue);

    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `${date.getFullYear()}-${month}-${day}`

    return dateMDY
  }


  const data  = projectActive.map((data) => {
    const date1 = new Date() 
    const date2 = new Date (data?.End_date)

    const timeDifference = date2.getTime() - date1.getTime();
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    if(daysDifference > 0 && daysDifference < 30){
      return data
    }
  }) 

  const DataVal = data.filter((item) => item != undefined )

  const ProjectAboutToEndCount  = DataVal.length

//------------------------------------------------Api function calls ---------------------------------------------------
//function to call list api
const callDashboardApi =  async () => {
  const res  = await getDashboardDetails("admin/dashboard")
  if(res?.success){
    setDataArray(res?.data[0])
  }else{
   console.log("res",res?.message)
  }
}


   //function to call list api
   const callProjectlistApi =  async () => {
    const res  = await getProjectsList(`admin/dashboard/subscription`)
    
    if(res?.success){
      setProjectsDeactivated(res?.data.filter((data) => {
        return data?.is_active === 0
    }))

    }else{
     console.log("res",res?.message)
    }
 }

 //function to call projects about to end 
 const  callProjectsEnded = async () => {
  const res  = await getProjectsList(`admin/project`);
  if(res?.success){
    setProjectActive(res?.data.filter((data) => {
      return data?.is_active === 1
  }))

  }else{
   console.log("res",res?.message)
  }
 }

 //function to call tested result 

const callTestedResult = async() => {
 const req_body ={
  date : handleDateFormat(new Date())
 }

  const res = await getTestedResults ("admin/dailyResult",req_body);

   if(res?.success){
    setTestedData(res?.data)
   }else{
    console.log("res",res?.message)
   }
}

//---------------------------------------------------------------------------------------------------------------------------
const handleChangeIcon = () => {
  setChange(!change)
  setTested(false)
  
}

const handleChangeEndSubscription = () => {
  setEndSubscription(!endSubscription)
  setTested(false)
}

const handleTestedData = () => {
  setTested(!tested)
  setChange(false)
  setEndSubscription(false)
}

  return (

    dataArray.Total_Clients != 0 ?
    <div class="flex flex-col w-full max-h-screen  overflow-y-auto">
      
    <div class="flex flex-col justify-start items-start mt-4" >

      <div class="flex flex-row  gap-x-2 w-full ">
      <div class="flex flex-col bg-colorTotalClient border-borderColorClient border w-3/4 h-28 rounded-md justify-center items-center mx-2  " >
        
        <h1 class="text-2xl text-boxText1 font-bold my-2" >Total Clients</h1>
        <h1 class="text-2xl text-boxText1 font-bold mx-4">{dataArray != undefined ?  dataArray?.Total_Clients : 0}</h1>
      </div>

      <div class="flex flex-col bg-colorTotalProjects border-borderColorProjects border w-3/4 h-28 rounded-md justify-center items-center mx-2  " >
        
        <h1 class="text-2xl text-boxText2 font-bold my-2" >Total Projects</h1>
        <h1 class="text-2xl text-boxText2 font-bold mx-4 ">{dataArray != undefined ? dataArray?.Total_Projects : 0}</h1>
      </div>

      <div class="flex flex-col bg-colorHealthyStatus border-borderColorHealthy border w-3/4 h-28 rounded-md justify-center items-center mx-2  " >
        
        <h1 class="text-2xl text-boxText3 font-bold my-2" >Active Projects</h1>
        <h1 class="text-2xl text-boxText3 font-bold">{dataArray != undefined  ? dataArray?.total_no_of_active_projects : 0}</h1>
      </div>
      </div>

    </div>

    
    
    <div class="flex flex-row justify-between w-5/5 mt-2 ml-2 ">

{/*----------------------------------------------------Projects Subscription Ended table ------------------------------------------------------- */}
<div class="flex flex-col my-2 mx-2 self-start w-1/2 max-h-min mx-2 border-primaryColor border-2 rounded-xl justify-between items-center ">
  <div class="flex flex-row w-full h-10 my-2 justify-between items-center">
 <h1 class="mx-2 text-1xl font-bold "> Projects Subscription Ended : <span class="text-1xl  ml-10"> {projectDeactivatedCount}  </span> </h1>
 <div onClick={ () =>   projectDeactivatedCount === 0 ? null : handleChangeIcon()} class=  { projectDeactivatedCount === 0 ? "flex w-8 h-8 rounded-2xl justify-center items-center bg-slate-300 mr-4 shadow-sm shadow-black cursor-pointer" : "flex w-8 h-8 rounded-2xl justify-center items-center mr-4 shadow-sm shadow-black cursor-pointer"}>
 {
  projectDeactivatedCount === 0 ? <FontAwesomeIcon icon={faChevronDown} class="h-4 w-4 text-slate-200" /> :
 <FontAwesomeIcon icon={change? faChevronUp:faChevronDown} class="h-4 w-4 " />
 }
 
 

 </div>
 </div>
 {
  change ? 
  <div class="w-full  pl-2 pr-2">
  <MuiCustomTabel
   path={pathName}
    name={heading}
    data={projectDeactiveated}
    columnHeader={columnsDashBoard}
    fromDashboard = {true}
    color="#036572"
   />
   </div>

   :
   null
 }

       



</div>

{/*----------------------------------------------------Projects Subscription Expires in 30 Days table------------------------------------------------------- */}
<div class="flex flex-col my-2 mx-2 self-start w-1/2 max-h-min mx-2 border-primaryColor border-2 rounded-xl justify-between items-center ">
  <div class="flex flex-row w-full  h-10 my-2 justify-between items-center">
 <h1 class=" mx-2 text-1xl font-bold ">  Projects Subscription Expires in 30 Days : <span class="text-1xl  ml-10"> {ProjectAboutToEndCount}  </span> </h1>
 <div onClick= {() => ProjectAboutToEndCount === 0 ? null : handleChangeEndSubscription()} class=  { ProjectAboutToEndCount === 0 ? "flex w-8 h-8 rounded-2xl justify-center items-center bg-slate-300 mr-4 shadow-sm shadow-black cursor-pointer" : "flex w-8 h-8 rounded-2xl justify-center items-center mr-4 shadow-sm shadow-black cursor-pointer"}>
  {
    ProjectAboutToEndCount === 0 ? <FontAwesomeIcon icon={faChevronDown} class="h-4 w-4 text-slate-200" /> : 
    <FontAwesomeIcon icon={endSubscription? faChevronUp:faChevronDown} class="h-4 w-4 " />
  }
 
 </div>
 </div>
 {
  endSubscription ? 
  <div class="w-full  pl-2 pr-2">
  <MuiCustomTabel
   path={pathName}
    name={heading}
    data={DataVal}
    columnHeader={columnsDashBoard}
    clientName={"Ayyappa"}
    fromDashboard = {true}
    color="#036572"
   />
   </div>

   :
   null
 }
</div>


</div>

<div class="flex flex-col w-full pl-4 pr-2  justify-center items-center mt-2  ">



{/*----------------------------------------------------Projects Tested Today table------------------------------------------------------- */}
<div class="flex flex-col my-2  w-full max-h-min mx-1 border-primaryColor border-2 rounded-xl justify-between items-center ">
  <div class="flex flex-row w-full  h-10 my-2 justify-between items-center">
 <h1 class="mx-2 text-1xl font-bold ">Projects Tested Today : <span class="text-1xl  ml-10"> {testedData.length}  </span> </h1>
 <div onClick={() => testedData.length === 0 ? null : handleTestedData()} class=  { testedData.length === 0 ? "flex w-8 h-8 rounded-2xl justify-center items-center bg-slate-300 mr-4 shadow-sm shadow-black cursor-pointer" : "flex w-8 h-8 rounded-2xl justify-center items-center mr-4 shadow-sm shadow-black cursor-pointer"} >
 {
  testedData.length === 0 ? <FontAwesomeIcon icon={faChevronDown} class="h-4 w-4 text-slate-200" /> : 
  <FontAwesomeIcon icon={tested? faChevronUp:faChevronDown} class="h-4 w-4 "/>
 }
 
 </div>
 </div>
 {
  tested ? 
  <div class="w-full pl-2 pr-2">
  <MuiCustomTabel
 path={pathName}
    name={heading}
    data={testedData}
    columnHeader={columnsTested}
    fromDashboard = {true}
    color="#036572"
   />
   </div>

   :
   null
 }

</div>

</div>




    

    
   
    </div>

  :

    <div class="flex flex-col justify-center items-center mt-4 w-full mt-10 ">
  <div>
   
   <img src={tradeImg} class="w-20 h-20" />
   </div>

   <div class="my-4">
   <h1 class="font-bold">You have not created any client yet</h1>
   <p class="text-gray-200">All the projects will appear in the list</p>
   </div> 

   <div class="my-7"  >
           <CustomButton
             children={"Create Client"}
             onClick={() => setshowModal(true)}
             classVal={
               "bg-teal-800 text-white w-36 h-10 hover:bg-teal-700 rounded-lg mt-12	"
             } 
            // loading={loading}
           />
         </div>

         <CustomModal
>
 <ModalWithTextFields
      width = {"w-3/5"}
      height = {"h-5/5"}
      shouldShow={showModal} 
      closeModal={handleClose} 
      onAction={onCreat}
      title={"Create Client"} 
 />
</CustomModal> 

    </div>
// null

  )
}

export default Dashboard