import "./App.css";
import { BrowserRouter, Switch, Redirect, Route } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

import { Login } from "./components/auth/Login";
import PublicRoute from "./router/PublicRoute";
import Registration from "./components/auth/Registration";
import Panel from "./components/panel";
import ClientPanel from  "./components/ClientPanel";
import PrivateRoute from "./router/PrivateRoute";
import { PageNotFound } from "./components/errorHandles/PageNotFound";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <ToastContainer
          autoClose={2000}
          hideProgressBar={true}
          position={toast.POSITION.TOP_CENTER}
        />
        <Switch>
          {/* To directly route to auth components */}
          <Route exact path="/" render={() => <Redirect to="/auth" />} />
          {/* ***Public routes is a component which has non authenticated path*** */}
          <PublicRoute restricted={true} component={Login} path="/auth" />
          <PublicRoute
            restricted={false}
            component={Registration}
            path="/registration"
            exact
          />

          <PrivateRoute restricted={true} component={Panel} path="/panel" />
          <PrivateRoute restricted={true} component={ClientPanel} path="/client"  />
          <PublicRoute component={PageNotFound} path="*" exact />
          {/* <PublicRoute
          restricted={true}
          component={Dashboard}
          path={`/dashboard`}
          exact
        /> */}
        </Switch>
      </BrowserRouter>
    </div>

  );
}

export default App;
