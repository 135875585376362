//package imports
import React, { useState, useMemo } from 'react'
import { useForm } from "react-hook-form";
import { Typography, Grid, Paper } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import debounce from "lodash.debounce";

//component imports
import { APP_CONSTANTS } from '../../utils/constants/AppConstants'
import { AuthConstants } from '../../utils/constants/AuthConstnats';
import BackIcon from "../../assests/icons/BackIcon.svg"
import CustomTextField from '../../utils/reusableComponents/CustomTextField'
import CustomButton from '../../utils/reusableComponents/CustomButton';
import { forgotPasswordApi } from '../../services/apiServices/PostServices';
import { CustomModal, Modal } from '../../utils/reusableComponents/CustomModal';
import { showToast } from '../../utils/constants/ToastConstants';
import { ValidationConstants } from "../../utils/constants/ValidationConstants"
import { getData } from "../../utils/AsyncStorage";





const ForgotPassword = () => {
  const [mail, setMail] = useState("");
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  const userData = getData("userInfo"); 
  const parsedUserData = JSON.parse(userData);

  const userType = parsedUserData?.role_id === 1 ? "admin" : "client"
  
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      checkbox: false,
    },
  });



  // Form submit
  const handleSendEmail = async () => {
    setLoading(true);
    const reqbody = {
      email: mail,
      userType: "admin",
    };

    

    const res = await forgotPasswordApi("auth/forgot_password", reqbody);
    if (res?.success) {
      showToast(1, res?.message);
      setClicked(true);
    } else {
      showToast(3, res?.message || res?.errors[0]?.msg);
    }
    setLoading(false);
  };

  // Restricting multiple api hits used debounce
  const debouncedResults = useMemo(() => {
    return debounce(handleSendEmail, 1000)
  }, [handleSendEmail])

  

  return (
    <div >

      <form onSubmit={handleSubmit(handleSendEmail)}>

        <div>
          <Paper elevation={0} className={`paper_margin`} class="w-2/4 m-auto">

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} justifyContent={"center"} class="my-5">
              <CustomTextField
                control={control}
                header={"Email ID"}
                required
                name="email"
                size="small"
                value={mail}
                placeholder={AuthConstants?.PLACEHOLDER_EMAIL}
                borderRadius={"0.75em"}
                rules={{
                  required: AuthConstants.REQUIRED_SIGNIN_MESSAGE,
                  pattern: {
                    value: ValidationConstants.TypeConstants.EmailType,
                    message: AuthConstants.NOT_VALID_EMAIL,
                  },
                }}
                InputProps={{ sx: { borderRadius: "0.75em" } }}
                onChange={(e) => {
                  setMail(e);
                }}
              />
            </Grid>

            <div class="my-7"  >
              <CustomButton
                children={"Submit"}
                classVal={
                  mail !== "" && !clicked
                    ? "bg-teal-800 text-white w-36 h-10 hover:bg-teal-700 rounded-lg mt-12	"
                    : "bg-disabledColor  w-36 h-10 rounded-lg shadow-md text-white mt-12 cursor-not-allowed"
                } 
                type={"submit"}
                disabled={clicked}
                loading={loading}
              />
            </div>

            <div>
              <p >
                {APP_CONSTANTS?.DIDNOT_RECEIVE_MAIL}
                {
                  // errorMsgEmail === "" &&
                  mail !== "" && clicked ? (
                    <>
                      <span onClick={debouncedResults} class="text-blue-600  cursor-pointer">
                        {APP_CONSTANTS?.CLICK_TO_RESEND}
                      </span>
                    </>
                  ) : (
                    <> {APP_CONSTANTS?.CLICK_TO_RESEND}</>
                  )
                }
              </p>

              <div  class="flex flex-row justify-center items-center my-5" >
               
                <Link to="/" class='flex flex-row text-gray hover:text-primaryColor'>
                <img
                  src={BackIcon}
                class="w-4"
                />
                  {APP_CONSTANTS?.BACK_TO_LOGIN}
                </Link>

              </div>
            </div>
          </Paper>
        </div >
      </form>
   
    </div>
  )
}

export default ForgotPassword