import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, Grid, IconButton, Paper, } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';


//component imports 
import CustomTextField from "../../utils/reusableComponents/CustomTextField";
import { getData } from "../../utils/AsyncStorage";
import CustomButton from "../../utils/reusableComponents/CustomButton"
import { AuthConstants } from "../../utils/constants/AuthConstnats";
import { activateOrResetPassword, createOrResetPassword, change_Password } from "../../services/apiServices/PostServices";
import { ValidationConstants } from "../../utils/constants/ValidationConstants";
import { showToast } from '../../utils/constants/ToastConstants';
import PasswordEncryption from "../../utils/constants/PasswordEncryption";



const PasswordSettigs = (props) => {

   

    const [oldPassword, setOldPassword] = useState("")
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
  
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [show_oldPassword, setShowOldPassword] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const data = JSON.parse(getData("userInfo"));
  
    const heading = "Settings"
  
  
    //const history = useHistory()
    const {
      register,
      handleSubmit,
      setValue,
      control,
      formState: { errors },
      trigger,
    } = useForm({
      defaultValues: {
        checkbox: false,
      },
    });
  
    // To view password
    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    // To view confirm password
    const handleClickShowConfirmPassword = () =>
      setShowConfirmPassword((show) => !show);
  
  
    const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);
  
    const onSubmit = async () => {
  
  
      const secretPass = 'StrId@AhM_@aHM*2023'
      const encodedOldPassword = `WEB|${oldPassword?.trim()}|aHm$*2@2309_aHm@`;
      const encodedNewPassword = `WEB|${password?.trim()}|aHm$*2@2309_aHm@`;
      const encodedConfirmPassword = `WEB|${confirmPassword?.trim()}|aHm$*2@2309_aHm@`;
  
      const OldPassswordEncoded = PasswordEncryption(encodedOldPassword)
  
      const PasswordEncoded = PasswordEncryption(encodedNewPassword)
      const ConfirmPasswordEncoded = PasswordEncryption(encodedConfirmPassword)
  
      const reqbody = {
        email: data?.email,
        currentPassword: OldPassswordEncoded,
        newPassword: PasswordEncoded,
        confirmPassword: ConfirmPasswordEncoded,
      };
  
      const res = await change_Password("auth/change_password", reqbody);
      if (res?.success) {
        showToast(1, res?.message);
  
      } else {
        showToast(3, res?.message || res?.errors[0]?.msg);
      }
      setLoading(false);
  
    }




    return (
       
       <div class="flex flex-row w-5/5 h-5/5 mx-20 my-6 py-2 bg-teal-50  justify-center items-center">
        <div class="w-2/5">


          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Paper elevation={0} className={`paper_margin`} class="m-auto">
              <Grid >

                <h1 class="text-2xl  font-bold leading-6  mb-2">
                  Change Password
                </h1>

                <div >

                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"} class=" mt-1.5">
                    <CustomTextField
                      control={control}
                      header={AuthConstants?.CHANGE_OLDPASSWORD_HEADER}
                      required
                      name="  Old_password"
                      placeholder={AuthConstants?.Old_PASSWORD_PlACEHOLDER}
                      size="small"
                      type={show_oldPassword ? "text" : "password"}
                      value={oldPassword}
                      borderRadius={"0.75em"}
                      inputProps={{ maxLength: 15, sx: { borderRadius: "0.75em" } }}
                      rules={{
                        required:
                          AuthConstants?.SET_OLDPASSWORD_REQUIRED,
                            }}

                      InputProps={{
                        sx: { borderRadius: "0.75em" },
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowOldPassword}
                            edge="end"
                          >
                            {show_oldPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        ),

                      }}
                      onChange={(e) => {
                        setOldPassword(e);
                      }}
                    />
                  </Grid>


                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} justifyContent={"center"} class="  mt-1.5">
                    <CustomTextField
                      control={control}
                      header={AuthConstants?.SET_PASSWORD_HEADER}
                      required
                      name="current_password"
                      placeholder={AuthConstants?.SET_PASSWORD_HEADER}
                      size="small"
                      type={showPassword ? "text" : "password"}
                      value={password}
                      borderRadius={"0.75em"}
                      inputProps={{ maxLength: 15, sx: { borderRadius: "0.75em" } }}
                      rules={{
                        required:
                          AuthConstants?.SET_PASSWORD_REQUIRED,
                        pattern: {
                          // eslint-disable-next-line no-useless-escape
                          value: ValidationConstants.TypeConstants.RegexPassword,
                          message:
                            AuthConstants?.PASSWORD_VALIDATION_MESSAGE,
                        },
                        minLength: {
                          value:
                            AuthConstants?.SET_NEW_PASSWORD_REQUIRED_MINVALUE,
                          message:
                            AuthConstants?.SET_MINVALUE_MESSGAGE,
                        },
                      }}

                      InputProps={{
                        sx: { borderRadius: "0.75em" },
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            edge="end"
                          >
                            {showPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                      onChange={(e) => {
                        setPassword(e);
                      }}
                    />
                  </Grid>

                  <Grid item xs={6} sm={6} md={6} lg={6} xl={6} justifyContent={"center"} class="mt-1.5">
                    <CustomTextField
                      control={control}
                      header={AuthConstants?.SET_CONFIRM_PASSWORD_HEADER}
                      required
                      name="password_repeat"
                      placeholder={AuthConstants?.SET_CONFIRM_PASSWORD_HEADER}
                      size="small"
                      type={showConfirmPassword ? "text" : "password"}
                      value={confirmPassword}
                      borderRadius={"0.75em"}
                      inputProps={{ maxLength: 15, sx: { borderRadius: "0.75em" } }}
                      rules={{

                        required:
                          AuthConstants?.SET_CONFIRM_PASSWORD_VALIDATE,
                        validate: (value) => {
                          return value.trim() == password
                            ? true
                            : confirmPassword && AuthConstants?.SET_CONFIRM_PASSWORD_VALIDATE;
                        },
                      }}
                      InputProps={{
                        sx: { borderRadius: "0.75em" },
                        endAdornment: (
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        ),
                      }}
                      onChange={(e) => {
                        setConfirmPassword(e);
                      }}
                    />


                  </Grid>
                </div>
                <div>
                  <CustomButton
                    children={"Update Password"}
                    classVal={
                      password !== "" && confirmPassword !== ""
                        ? "bg-teal-800 text-white w-36 h-10 rounded-lg mt-6	mb-2"
                        : "bg-disabledColor  w-36 h-10 rounded-lg shadow-md text-white mt-6 mb-2	"
                    }
                    type={"submit"}

                  />
                </div>
              </Grid>
            </Paper>
          </form>
        </div>

      </div> 
    )
}

export default PasswordSettigs