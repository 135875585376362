import { useState,useEffect } from "react";
import { columnsMetaData, sample,occuranceData } from "../../../utils/constants/TableData";
import MuiCustomTabel from "../../../utils/reusableComponents/MuiCustomTabel";
import CustomButton from "../../../utils/reusableComponents/CustomButton";
import { CustomModal } from "../../../utils/reusableComponents/CustomModal";
import SimpleModal from "../../../utils/reusableComponents/SimpleModal";
import { getOccuranceList } from "../../../services/apiServices/GetServices";
import { createOccurance } from "../../../services/apiServices/PostServices";
import { showToast } from "../../../utils/constants/ToastConstants";
import { useHistory } from "react-router-dom";



const Occurrence = (props) => {
    const [showModal, setshowModal] = useState(false);
    const [data,setData]=useState([])
    const [update,setUpdate]=useState(false)
    const [title,setTitle] = useState("Create Occurrence")
    const [predefinedData,setPredefinedData] = useState([])
    const history = useHistory();
    const [statusValue,setStatusValue]=useState(1)
    const [updateData,setUpdateData]=useState([])
    const [showStatus,setShowStatus] = useState(false)
    const [declineStatus,setDeclineStatus]=useState("")
  

    useEffect(() => {
      callOccuranceList()
      
    }, []);


     //function to call list api
  const callOccuranceList =  async () => {
    const res  = await getOccuranceList("master/occurrence")
    if(res?.success){
      setData(res?.data)
    }else{
     console.log("res",res?.message)
    }
 }

 //function call to edit 
 const handleEdit = (data,status) => {
  
  //setStatusValue(status)
  setPredefinedData(data)
  setTitle("Edit Occurrence")
  setUpdate(true)
  setshowModal(true)
 }

 

 //function to close modal after api success.
 const handleModalFalse = () => {
  callOccuranceList()
  setshowModal(false)
 }



    const handleApi = async (value,updateBody) => {

     const body = {
        name : value
      }
    const update_reqBody = {
      name : updateBody?.name,
      status : statusValue
    }
    let req_body = update? update_reqBody : body


    
    let endPoint = update ?  `master/occurrence/${updateBody?.id}` : "master/occurrence"
    
     const res = await createOccurance(endPoint,req_body) 
      if(res?.success){
        showToast(1, res?.message);
        handleModalFalse()
      }else{
        showToast(3, res?.message);

      } 

     }
      



//updated status 

const updateStatus = async() => {
  const  req_body = {
    name : updateData?.name,
    status : statusValue
  }

  const res = await createOccurance(`master/occurrence/${updateData?.id}`,req_body) 
  if(res?.success){
    showToast(1, "Status Updated");
    callOccuranceList()
    setShowStatus(false)
  }else{
    showToast(3, res?.message);
  } 
}
   
     



    //function to open modal
    const handleCreateOccurence = () => {
      setUpdate(false)
      setTitle("Create Occurrence")
        setshowModal(true)
    }
   
    //function to close modal
    const modalClose = ()  => {
      setshowModal(false)
    } 
   
    //function to call onDelete close modal 
    const onStatusClose = () => {
      setDeclineStatus("true")
      if(statusValue === 1){
        setStatusValue(0)
      }else{
        setStatusValue(1)
      }
      setShowStatus(false)
    }

    //function to call onCreate Occurence
    const onCreateOccurence = (value,body) => {
      handleApi(value,body)

    }



    //function to call onDelete Occurence
const handleStatus = (status,data) => {
  setUpdateData(data)
  setStatusValue(status)
  setShowStatus(true)
  setDeclineStatus("")
}

   


    const heading = {
    heading1: "Clients List",
    heading2: props?.location?.state?.title,
  };
  return (
    <>
    <div class="flex justify-between items-center my-1">
        <h1
        >
        Occurrence 
        </h1>
        <div class="  flex gap-4 ">
          <CustomButton
            children={"Create Occurrence"}
            classVal="bg-teal-800 text-white w-48 h-10 hover:bg-teal-700 rounded-lg font-sans "
            onClick={() => handleCreateOccurence()}
          />
        </div>
      </div>
      <MuiCustomTabel
        name={heading}
        data={data}
        columnHeader={columnsMetaData}
        onEditMasterfield = {handleEdit}
        height = {props}
        onStausChange={handleStatus}
        switchDecline= {declineStatus}
        bottomPagination = {false}
      />
     
     <CustomModal>
      <SimpleModal 
       title={title}
       width={"w-1/4"}
       height={"h-5/5"}
       shouldShow={showModal}
       closeModal={modalClose} 
       onAction={onCreateOccurence}
       inputFiled={true}
       inputHeader={"Occurrence"}
       inputPlaceholder={"Occurrence"}
       stateUpdate={update}
       data={predefinedData}
      />
     </CustomModal>


     <CustomModal>
      <SimpleModal 
       title={"Are you sure you want to update status"}
       shouldShow={showStatus}
       closeModal={onStatusClose} 
       onAction={updateStatus}
      />
     </CustomModal>

    </>
  );
};
export default Occurrence;
