// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.coverImage{
    /* display: flex; */
    flex-direction: row;
    /* justify-content:'center'; */

    /* justify-content: flex-start; */
    /* background-image: url('../../assests/images/CoverImage.png'); */
    background-color: #036572;
  height: 100vh;
  background-repeat: no-repeat;
    background-attachment: fixed;
  background-size: 50% 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/auth/Login.css"],"names":[],"mappings":";;AAEA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;;IAE9B,iCAAiC;IACjC,kEAAkE;IAClE,yBAAyB;EAC3B,aAAa;EACb,4BAA4B;IAC1B,4BAA4B;EAC9B,yBAAyB;AAC3B","sourcesContent":["\n\n.coverImage{\n    /* display: flex; */\n    flex-direction: row;\n    /* justify-content:'center'; */\n\n    /* justify-content: flex-start; */\n    /* background-image: url('../../assests/images/CoverImage.png'); */\n    background-color: #036572;\n  height: 100vh;\n  background-repeat: no-repeat;\n    background-attachment: fixed;\n  background-size: 50% 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
