import React, { useState,useEffect } from 'react'

import classNames from "classnames";
import { useRouteMatch } from "react-router-dom";
import Dashboard from './dashboard/Dashboard';
import { useHistory } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/outline";
import PublicRoute from "../.././src/router/PublicRoute";
import PrivateRoute from "../.././src/router/PrivateRoute";
import Sidebar from './dashboard/sidebar';
import Clients from './dashboard/Clients';
import AllProjects from './dashboard/AllProjects';
import MasterData from './dashboard/MasterData';
import Settings from './dashboard/Settings';
import { getData } from '../utils/AsyncStorage';
import ClientProject from './projectList/ClientProject';
import Particular from './projectList/Particular';
import FormParticulars from './dashboard/FormParticulars';


const Panel = (props) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const [collapsed, setSidebarCollapsed] = useState(false);
    const data = JSON.parse(getData("userInfo"));
    const userLoggedIn = `Logged in as ${data?.name}`


    return (
        <div >
            <div class="flex flex-row " >

                <div
                    className={classNames({
                        // 👇 use grid layout
                        "grid min-h-screen": true,
                        // 👇 toggle the width of the sidebar depending on the state
                        // "grid-cols-sidebar  ": !collapsed,
                        "grid-cols-sidebar-collapsed": collapsed,
                        // 👇 transition animation classes
                        "transition-[grid-template-columns] duration-300 ease-in-out": true,
                    })}

                >
                    <Sidebar
                        collapsed={collapsed}
                       
                    // setCollapsed={() => setSidebarCollapsed((prev) => !prev)}
                    />
                </div>

                <div class="flex-1  flex-col  w-4/5  ">
                    <div>
                    <nav
                        class="relative flex  w-full  items-center justify-between bg-primaryColor py-2 text-neutral-500 shadow-lg hover:text-neutral-700 focus:text-neutral-700 dark:bg-neutral-600 lg:py-4 h-11">
                        <div class="flex w-full flex-wrap items-center justify-between px-3">
                            <div>
                                <a
                                    class="mx-2 my-1 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 lg:mb-0 lg:mt-0"
                                   >
                                    <div className="bg-primaryColor text-white">
                                        <button onClick={() => setSidebarCollapsed((prev) => !prev)}>
                                            <Bars3Icon className="w-6 h-6 mt-1.5 mx-2" />
                                        </button>
                                    </div>
                                    <span class="font-medium text-white"
                                    >Stridefuture Technologies</span>
                                </a>
                            </div>
                            <div class="flex flex-row">
                                <h1 class="text-white">
                                {userLoggedIn}
                                </h1>
                                {/* <div >
                                <img
            src="https://tecdn.b-cdn.net/img/new/avatars/2.jpg"
            class="rounded-full"
            style={{height: "25px",width: "25px"}}
            alt=""/>
                                </div> */}
                                
                            </div>
                        </div>
                    </nav>
                    </div>
                    
                    <div class=" flex flex-row   h-5/5 ">
                        
                    
                    <PrivateRoute
                        restricted={true}
                        component={Dashboard}
                        path={`${path}/dashboard`} 
                    />

                    <PrivateRoute
                        restricted={true}
                        component={Clients}
                        path={`${path}/clients`}
                        exact
                    />

                    <PrivateRoute
                        restricted={true}
                        component={AllProjects}
                        path={`${path}/allProjects`}
                        exact
                    />

                    <PrivateRoute
                        restricted={true}
                        component={ClientProject}
                        path={`${path}/clients/clientProject`}
                        exact
                    />

                   <PrivateRoute
                        restricted={true}
                        component={Particular}
                        path={`${path}/clients/clientProject/particular`}
                        exact
                    />
                    <PrivateRoute
                        restricted={true}
                        component={MasterData}
                        path={`${path}/masterData`}
                        exact
                    />
                    <PublicRoute
                        restricted={false}
                        component={Settings}
                        path={`${path}/settings`}
                        exact
                    />
                     
                       <PrivateRoute
                        restricted={true}
                        component={FormParticulars}
                        path={`${path}/clients/clientProject/particular/formParticular`}
                        exact
                    />

                    {/* <PublicRoute
                        restricted={false}
                        component={SignOut}
                        path={`${path}/signout`}
                        exact
                    /> */}

                    </div>
                  
                </div>



            </div>

        </div>
    )
}

export default Panel