/** This is to store the data in local storage */
export const storeData = async (key, value) => {
  try {
    await localStorage.setItem(`${key}`, `${value}`);
  } catch (e) {
    console.log(e);
  }
};

/** This is to get the data from local storage */
export const getData = (key) => localStorage.getItem(`${key}`);

// This is used to remove the data that stored in local storage
export const removeData = async (key) => localStorage.removeItem(`${key}`);

/** This is to store the data in session storage */
export const SessionStoreData = async (key, value) => {
  try {
    await sessionStorage.setItem(`${key}`, `${value}`);
  } catch (e) {
    console.log(e);
  }
};

/** This is to get the data from session storage */
export const SessionGetData = (key) => sessionStorage.getItem(`${key}`);