import { Button, InputAdornment, Grid } from "@mui/material";
import React, { useState } from "react";
import CustomTextField from "../../../utils/reusableComponents/CustomTextField";
import { useForm } from "react-hook-form";
import tickicon from "../../../assests/images/saveIcon.png";
import crossicon from "../../../assests/images/DeleteIcon.png";
const Accounts = () => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const ParticularsData = "Particulars";
  const occurrenceData = "Occurrence";
  const frequencyData = "Frequency";
  const statusData = "Status";

  const [particulars, setParticulars] = useState(ParticularsData);
  const [occurrence, setOccurrence] = useState(occurrenceData);
  const [frequency, setFrequency] = useState(frequencyData);
  const [status, setStatus] = useState(statusData);

  return (
    <>
      <Grid style={{ width: "30vw" }}>

        <div
          style={{
            display: "flex",
            justifyContent: "left",
            alignContent: "center",
          }}
        >
          Fields
        </div>
        <Grid
          item
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          justifyContent={"center"}
          class=" mt-1.5"
        >
          <CustomTextField
            control={control}
            classStyle={"outlinedemoji-picker outlinedemoji-picker-with-emoji"}
            name="Particulars"
            placeholder={"Particulars"}
            size="small"
            rows={4}
            value={particulars}
            backgroundColor={"white"}
            InputProps={{
       
              maxLength: 70,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                  
                    <img
                      src={tickicon}
                      onClick={() => {
                        setParticulars(particulars);
                      }}
                      style={{cursor:"pointer"}}
                    />
                  </InputAdornment>
                  <InputAdornment position="end">
                    <img
                      src={crossicon}
                      onClick={() => {
                        setParticulars(ParticularsData);
                      }}
                      style={{cursor:"pointer"}}
                    />
                  </InputAdornment>
                </>
              ),
            }}
            rules={{
              required: "required field",
              minLength: {
                value: 3,
                message: "min length",
              },
              maxLength: {
                value: 100,
                message: "max length",
              },
            }}
            onChange={(e) => {
              setParticulars(e);
            }}
          />
        </Grid>


        <Grid
          item
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          justifyContent={"center"}
          class=" mt-1.5"
        >
          <CustomTextField
            control={control}
            classStyle={"outlinedemoji-picker outlinedemoji-picker-with-emoji"}
            name="Occurrence"
            placeholder={"Occurrence"}
            size="small"
            rows={4}
            value={occurrence}
            backgroundColor={"white"}
            InputProps={{
       
              maxLength: 70,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                  
                    <img
                      src={tickicon}
                      onClick={() => {
                        setOccurrence(occurrence);
                      }}
                      style={{cursor:"pointer"}}
                    />
                  </InputAdornment>
                  <InputAdornment position="end">
                    <img
                      src={crossicon}
                      onClick={() => {
                        setOccurrence(occurrenceData);
                      }}
                      style={{cursor:"pointer"}}
                    />
                  </InputAdornment>
                </>
              ),
            }}
            rules={{
              required: "required field",
              minLength: {
                value: 3,
                message: "min length",
              },
              maxLength: {
                value: 100,
                message: "max length",
              },
            }}
            onChange={(e) => {
                setOccurrence(e);
            }}
          />

        </Grid>


        <Grid
          item
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          justifyContent={"center"}
          class=" mt-1.5"
        >
          <CustomTextField
            control={control}
            classStyle={"outlinedemoji-picker outlinedemoji-picker-with-emoji"}
            name="frequency"
            placeholder={"Frequency"}
            size="small"
            rows={4}
            value={frequency}
            backgroundColor={"white"}
            InputProps={{
       
              maxLength: 70,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                  
                    <img
                      src={tickicon}
                      onClick={() => {
                        setFrequency(frequency);
                      }}
                      style={{cursor:"pointer"}}
                    />
                  </InputAdornment>
                  <InputAdornment position="end">
                    <img
                      src={crossicon}
                      onClick={() => {
                        setFrequency(frequencyData);
                      }}
                      style={{cursor:"pointer"}}
                    />
                  </InputAdornment>
                </>
              ),
            }}
            rules={{
              required: "required field",
              minLength: {
                value: 3,
                message: "min length",
              },
              maxLength: {
                value: 100,
                message: "max length",
              },
            }}
            onChange={(e) => {
                setFrequency(e);
            }}
          />
        </Grid>

        <Grid
          item
          xs={7}
          sm={7}
          md={7}
          lg={7}
          xl={7}
          justifyContent={"center"}
          class=" mt-1.5"
        >
          <CustomTextField
            control={control}
            classStyle={"outlinedemoji-picker outlinedemoji-picker-with-emoji"}
            name="Status"
            placeholder={"Status"}
            size="small"
            rows={4}
            value={status}
            backgroundColor={"white"}
            InputProps={{
       
              maxLength: 70,
              endAdornment: (
                <>
                  <InputAdornment position="end">
                  
                    <img
                      src={tickicon}
                      onClick={() => {
                        setStatus(status);
                      }}
                      style={{cursor:"pointer"}}
                    />
                  </InputAdornment>
                  <InputAdornment position="end">
                    <img
                      src={crossicon}
                      onClick={() => {
                        setStatus(statusData);
                      }}
                      style={{cursor:"pointer"}}
                    />
                  </InputAdornment>
                </>
              ),
            }}
            rules={{
              required: "required field",
              minLength: {
                value: 3,
                message: "min length",
              },
              maxLength: {
                value: 100,
                message: "max length",
              },
            }}
            onChange={(e) => {
                setStatus(e);
            }}
          />

        </Grid>
      </Grid>
    </>
  );
};

export default Accounts;
