//package imports
import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, Grid, IconButton, Paper, TextField } from "@mui/material";

//component imports
import CustomTextField from "../../utils/reusableComponents/CustomTextField";
import { AuthConstants } from "../../utils/constants/AuthConstnats";
import { APP_CONSTANTS } from "../../utils/constants/AppConstants";
import { useHistory } from "react-router-dom";
import { ValidationConstants } from "../../utils/constants/ValidationConstants";
import { createClient,updateClient } from "../../services/apiServices/PostServices";
import { getClientDetails } from "../../services/apiServices/GetServices";
import { showToast } from '../../utils/constants/ToastConstants';
import { getData } from "../../utils/AsyncStorage";





const EditClient = () => {
  
    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        trigger,
    } = useForm({
        defaultValues: {
            checkbox: false,
        },
    });

    const [organizationName, setOrganizationName] = useState("")
    const [email, setEmail] = useState("")
    const [clientName, setClientName] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [address, SetAddress] = useState("")
    const [data,setData]=useState([])
    const [disabledUpdate,setDisabledUpdate]=useState(true)

    const userData = getData("client_id"); 
    const parsedUserData = JSON.parse(userData); 
    


    const id = parsedUserData 


    useEffect(() => {
        if(id != undefined){
            callClientDetailstApi()  
        }
    
      }, []); 

      useEffect (() => {
        if(data.length != 0){
            onUpdate()
            setValue("Client",data?.organisation_name)
            setValue("Contact Person Name",data?.name)
            setValue("email",data?.email)
            setValue("Number",data?.mobile)
            setValue("Address",data?.address)
          
        }
      },[data])


      //function to update data for text fileds
const onUpdate = () => {
    setOrganizationName(data?.organisation_name)
        setEmail(data?.email)
        setClientName(data?.name)
        setMobileNumber(data?.mobile)
        SetAddress(data?.address)
}


   //function to call list api
  const callClientDetailstApi =  async () => {
    const res  = await getClientDetails(`admin/client/${id}`)
    if(res?.success){
        setData(res?.data[0])
    }else{
     console.log("res",res?.message)
    }
 }



  //function to handle submit api 
    const onSubmit = async () => {
       
        const req_body = {
            organisation_name: organizationName.trimEnd(),
            name : clientName.trimEnd(),
            mobile: mobileNumber,
            email: email.trimEnd(),
            address: address.trimEnd(),
            is_active:1
        }

        const res = await updateClient(`admin/client/${id}`,req_body);

        if(res?.success){
        showToast(1, res?.message);
        setDisabledUpdate(true)
        }else{
            showToast(3, res?.errors?.msg);
            res?.errors?.map((data) =>{ 
                showToast(3, data?.msg);
            })
        }
                       
    }


  //function for string validation
    const checkString = (string) => {
        let string1=string 
        return   /^[a-zA-Z0-9\s]*$/.test(string1)  
    }
 
    const checkAddress = (value) => {
        let string1=value 
        return   /^[A-Za-z0-9.,/\s]*$/.test(string1)  
    }

    const handleClientName = (e) => {
        setDisabledUpdate(false)
        let name = e
        if(checkString(name)){
         setClientName(name)
        }
      
    }

    const handleOraganizationName = (value) => {
        setDisabledUpdate(false)
        let valueName = value 
        if(checkString(valueName)){
            setOrganizationName(valueName)
        }
    }

    const handleEmail = (value) => {
        setDisabledUpdate(false)
        setEmail(value)
    }

    const handleMobileNumber = (value) => {
        setDisabledUpdate(false)
        setMobileNumber(value)
    }

    const handleAdress = (value) => {
        setDisabledUpdate(false)
        let valueName = value  
        if(checkAddress(valueName)){
            SetAddress(valueName)
        }
    }

    return (
        <div class=" flex flex-col w-full justify-center items-center">
           <div class="self-start ml-6">
            <h1 class="text-2xl text-zinc-600 font-bold Inter var">
                Edit Profile
            </h1>
            </div>
            <div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Paper elevation={0} className={`paper_margin`} class=" m-auto my-3">
                <Grid container xs={12} direction={"column"} justifyContent={"center"} class=" flex flex-col ">


                    <div class="flex flex-row flex-wrap h-2/5 my-2 " >

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class=" w-2/5 mx-5 my-1 ">
                            <CustomTextField
                                control={control}
                                required
                                header={APP_CONSTANTS?.CLIENT_NAME}
                                name="Client"
                                size="small"
                                value={organizationName}
                                placeholder={APP_CONSTANTS?.CLIENT_NAME}
                                borderRadius={"0.75em"}
                                inputProps={{minLength : 3 , maxLength: AuthConstants?.CLIENTNAME_MAXLENGTH }}
                                rules={{
                                    required: APP_CONSTANTS?.CLIENT_NAME_REQUIRED,
                                      pattern: {
                                        value:  /^[a-zA-Z\s]+[a-zA-Z0-9,.@!#$%^&*()?:"}{}]*$/,
                                        message: "Please enter valid client name",
                                      },
                                    minLength: {
                                        value: 3,
                                        message: "Enter minimum 3 charaters",
                                      }
                                }}
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    handleOraganizationName(e);
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class=" w-2/5  mx-5 my-1 ">
                            <CustomTextField
                                control={control}
                                required
                                header={APP_CONSTANTS?.CONTACT_PERSON_NAME}
                                name="Contact Person Name"
                                size="small"
                                value={clientName}
                                placeholder={APP_CONSTANTS?.CONTACT_PERSON_NAME}
                                borderRadius={"0.75em"}
                                inputProps={{minLength : 3 ,  maxLength: AuthConstants?.CLIENTNAME_MAXLENGTH }}
                                rules={{
                                    required: APP_CONSTANTS?.CONTACT_PERSON_NAME_REQUIRED,
                                    pattern: {
                                        value:  /^[a-zA-Z\s]+[a-zA-Z0-9,.@!#$%^&*()?:"}{}]*$/,
                                        message: "Please enter valid contact person name",
                                      },
                                    minLength: {
                                        value: 3,
                                        message: "Enter minimum 3 charaters",
                                      }
                                }}
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    handleClientName(e);
                                }}
                            />


                        </Grid>


                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class="w-2/5 mx-5  my-1  ">
                            <CustomTextField
                                control={control}
                                required
                                header={"Email ID"}
                                name="email"
                                size="small"
                                value={email}
                                placeholder={"Email ID*"}
                                borderRadius={"0.75em"}
                                rules={{
                                    required: APP_CONSTANTS?.EMAIL_REQUIRED,
                                    pattern: {
                                        value: ValidationConstants.TypeConstants.EmailType,
                                        message: AuthConstants.NOT_VALID_EMAIL,
                                    },
                                }}
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    handleEmail(e);
                                }}
                            />


                        </Grid>


                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class="w-2/5 mx-5  my-1 ">
                            <CustomTextField
                                control={control}
                                required
                                header={" Mobile Number"}
                                name="Number"
                                size="small"
                                value={mobileNumber}
                                placeholder={"Mobile Number"}
                                borderRadius={"0.75em"}
                                rules={{
                                    required: mobileNumber === "" &&
                                    APP_CONSTANTS?.NUMBER_REQUIRED,
                                    minLength: {
                                        value: 10,
                                        message: "Enter minimum 10 numbers",
                                      }
                                }}
                                inputProps={{
                                    min: 10,
                                    maxLength: AuthConstants?.NUMBER_MAXLENGTH }}
                               
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    const result = e.replace(/\D/g, "");
                                    handleMobileNumber(result);
                                }}
                            />


                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class=" w-2/5  self-start mx-5  ">
                            <CustomTextField
                                control={control}
                                required
                                header={"Address"}
                                // maxWidth={"90%"}
                                name="Address"
                                size="small"
                                value={address}
                                placeholder={"Address"}
                                borderRadius={"0.75em"}
                                multiline={true}
                                rows={3}
                                rules={{
                                    required: APP_CONSTANTS?.ADDRESS_REQUIRED_Message,
                                    minLength: {
                                        value: 3,
                                        message: "Enter minimum 3 charaters ",
                                      }
                                }}
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    handleAdress(e);
                                }}
                            />


                        </Grid>

                    </div>

                    <div class="flex justify-center items-center w-2/5  self-end  mt-2" >
                       <div>

                        <button type="submit" class={ disabledUpdate  ?  "bg-slate-400 w-32 h-10 rounded-lg shadow-md text-white" : "bg-primaryColor w-32 h-10 rounded-lg shadow-md text-white"} disabled={disabledUpdate} >
                           Update
                        </button>
                        </div>
                    </div>

                </Grid>
            </Paper>
        </form>
        </div>
        </div>
    )
}

export default EditClient