
//package imports
import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

//component imports 
import MuiCustomTabel from "../../../utils/reusableComponents/MuiCustomTabel";
import {
  columnMetaDataStatus,
  columnsMetaDataFrequency,
  statusData,
  sample,
} from "../../../utils/constants/TableData";
import CustomButton from "../../../utils/reusableComponents/CustomButton";
import { CustomModal } from "../../../utils/reusableComponents/CustomModal";
import SimpleModal from "../../../utils/reusableComponents/SimpleModal";
import { getOccuranceList } from "../../../services/apiServices/GetServices";
import { createOccurance } from "../../../services/apiServices/PostServices";
import { showToast } from "../../../utils/constants/ToastConstants";


const Status = (props) => {
  // const { location } = props;
  const history = useHistory();
  const [showModal, setshowModal] = useState(false);
  const [statusData,setStatusData]=useState([])
  const [update,setUpdate]=useState(false)
  const [predefinedData,setPredefinedData] = useState([])
  const [title,setTitle] = useState("Create Status")
  const [statusValue,setStatusValue]=useState(1)
  const [updateData,setUpdateData]=useState([])
  const [showStatus,setShowStatus] = useState(false)
  const [declineStatus,setDeclineStatus]=useState("")

  const reload = ()=>window.location.reload();

   useEffect(() => {
      callStatusList()
    }, []);
    
     //function to call list api
     const callStatusList =  async () => {
      const res  = await getOccuranceList("master/status")
      if(res?.success){
        setStatusData(res?.data)
      }else{
       console.log("res",res?.message)
      }
   }

  

   //function call to edit 
   const handleEdit = (data,status) => {
    setPredefinedData(data)
    setTitle("Edit Status")
    setUpdate(true)
    setshowModal(true)
   }
  
   //function to close modal after api success.
   const handleModalFalse = () => {
    callStatusList()
    setshowModal(false)
   }


  //function to call onDelete Occurence
const handleStatus = (status,data) => {

  setUpdateData(data)
  setStatusValue(status)
  setShowStatus(true)
  setDeclineStatus("")
}
   

    //function to open modal
    const handleCreateStatus = () => {
      setTitle("Create Status")
      setUpdate(false)
      setshowModal(true)
  }
  
  //function to close modal
  const modalClose = ()  => {
    setshowModal(false)
  }


//function to call api
  const handleApi = async (value,updateBody) => {
    const body = {
       name : value
     }
   const update_reqBody = {
     name : updateBody?.name,
     status : statusValue
   }
   let req_body = update? update_reqBody : body
   
   let endPoint = update ?  `master/status/${updateBody?.id}` : "master/status"
   
    const res = await createOccurance(endPoint,req_body) 

     if(res?.success){
     
       showToast(1, res?.message);
       handleModalFalse()
       
     }else{
       showToast(3, res?.message);
       console.log("res",res?.message)
     } 
    

    }


    //updated status 

const updateStatus = async() => {
  const  req_body = {
    name : updateData?.name,
    status : statusValue
  }



  const res = await createOccurance(`master/status/${updateData?.id}`,req_body) 
  if(res?.success){
    showToast(1, "Status Updated");
    callStatusList()
    setShowStatus(false)
  }else{
    showToast(3, res?.message);
  } 
}
  
  //function call to create status
  const onCreateStatus = (value,body) => {

    handleApi(value,body)   
  }

 

    //function to call onDelete close modal 
    const onStatusClose = () => {
      setDeclineStatus("true")
      if(statusValue === 1){
        setStatusValue(0)
      }else{
        setStatusValue(1)
      }
      setShowStatus(false)
      
    }

 

  const heading = {
    heading1: "Clients List",
    // heading2: props?.location?.state?.title,
  };

  return (
    <>
      <div class="flex justify-between items-center ">
        <h1
        // class=" text-black  font-bold text-2xl"
        >
          Status
        </h1>
        <div class="  flex gap-4 items-center my-1">
          <CustomButton
            children={"Create Status"}
            classVal="bg-teal-800 text-white w-48 h-10 hover:bg-teal-700 rounded-lg font-normal font-sans"
            onClick={() => handleCreateStatus()}
          />
        </div>
      </div>

      <div class="w-full ">

      <CustomModal>
      <SimpleModal 
       title={title}
       width={"w-1/4"}
       height={"h-5/5"}
       shouldShow={showModal}
       closeModal={modalClose} 
       onAction={onCreateStatus}
       inputFiled={true}
       inputHeader={"Status"}
       inputPlaceholder={"Status"}
       stateUpdate={update}
       data={predefinedData}
      />
     </CustomModal>

     <CustomModal>
      <SimpleModal 
       title={"Are you sure you want to update status ?"}
       shouldShow={showStatus}
       closeModal={onStatusClose} 
       onAction={updateStatus}
      />
     </CustomModal>

        <MuiCustomTabel
          name={heading}
          data={statusData}
          columnHeader={columnMetaDataStatus}
          onEditMasterfield = {handleEdit}
          height = {props}
          onStausChange={handleStatus}
          switchDecline= {declineStatus}
          bottomPagination = {false}
        />
      </div>
    </>
  );
};

export default Status;
