
//package imports
import React, { useState,useEffect} from 'react'
import { useHistory } from "react-router-dom";


//Component imports
import MuiCustomTabel from '../../utils/reusableComponents/MuiCustomTabel';
import { ReusableSearch } from '../../utils/reusableComponents/ReusableSearch';
import { sample, columnsProject, sampleData } from '../../utils/constants/TableData';
import BreadcrumbsHeader from '../../utils/reusableComponents/BreadCrumbsHeader';
import { getParticularList } from '../../services/apiServices/GetServices';


const ParticularList = (props) => {
    const { location } = props;
    const [data, setData] = useState([])
    const history = useHistory();
    const [totalParticulars,setTotalParticular]=useState(0)
    const [filteredData,setFilteredData]=useState([])
    const [particularData,setParticularData]=useState([])
    const heading = {
        heading1: props?.location?.state?.heading2,
        heading2: "Projects"
    }

    let projectId = props?.location?.state?.info?.id  || props?.location?.state?.projectId

    let pathName = `${location?.pathname}/particularInfo`


    const ProjectsPath = `clients/projects`

    useEffect(() => {
        if(projectId != undefined){
          callParticularlistApi()
        }
       
      }, [projectId]);


      useEffect ( () => {
        if(filteredData.length != 0) {
          setData(filteredData)
        }
      },[filteredData.length])

    const callParticularlistApi =  async () => {
        const res  = await getParticularList(`admin/project_status/${projectId}`)
        if(res?.success){
         setFilteredData(res?.data)
         setParticularData(res?.data)
         setTotalParticular(res?.total_particulars)
        }else{
         console.log("res",res?.message)
        }
     }



    const handleSearchClick = (searchVal) => {

        const newSearchTerm = searchVal;

        if (newSearchTerm === "") {  callParticularlistApi(); return; }
        else{
            const filterBySearch = particularData.filter((item) => {
                if (item?.date.toLowerCase()
                    .includes(newSearchTerm.toLowerCase())) { return item; }
            })
            setFilteredData(filterBySearch);
        }
     
    }

    const handleSearchClear = () => {
        callParticularlistApi()
      }


    return (
        <div class="flex flex-col ml-10 mr-4 w-full ">
            <div class="flex mt-2 self-start">
                <BreadcrumbsHeader
                    heading1={props?.location?.state?.heading1?.heading1}
                    heading2={props?.location?.state?.heading2}
                    path={ProjectsPath}
                />

            </div>
            <div class="flex justify-between my-2  flex-row w-full  ">

                <ReusableSearch onChange={handleSearchClick} onClear = {handleSearchClear}/>

            </div>
            <div class="w-full h-4/5">
                <MuiCustomTabel
                    path={pathName}
                    name={heading}
                    data={filteredData}
                    columnHeader={columnsProject}
                    particularCount={totalParticulars}
                    projectId={projectId}
                />
            </div>

        </div>
    )
}

export default ParticularList