import React, { useState, useEffect } from "react";


//component imports 
import { getData } from "../../utils/AsyncStorage";

import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Box, Tab } from "@mui/material";
import PasswordSettigs from "../auth/PasswordSettigs";
import EditClient from "../clientDashboard/EditClient";




const Settings = (props) => {
  
  
  const { role_id } = JSON.parse(getData("userInfo"));
  const defaultValues = role_id !== 1 ? "1" : "2";
  const [value, setValueTab] = React.useState(defaultValues);

  
  // Onchange tab selection
  const handleChange = (event, newValue) => {
    setValueTab(newValue);
  };


  return (
    <div class="flex flex-col w-full h-5/5">

<TabContext style={{ textTransform: "capitalize" }} value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {
              role_id != 1 ? <Tab label="Account Settings" value="1"  sx={{ textTransform: "capitalize" , fontFamily: "inter"}}/> : null
            }
            <Tab label="Password" value="2"  sx={{ textTransform: "capitalize" , fontFamily: "inter"}}/>
          </TabList>
        </Box>
        <TabPanel
          value="1"
          className="AccountSettings_panel"
          style={{
            
            fontSize: "18px",
            textTransform: "capitalize",
            height:"100%"
           
          }}
        >
          {defaultValues === "1" ? (
           role_id == 1 ? PasswordSettigs() : EditClient()
          ) : (
            <TabPanel
            value="2"
            className="AccountSettings_panel"
            style={{
             
              fontSize: "18px",
              textTransform: "capitalize",
              
             
            }}
          >
            {PasswordSettigs()}{" "}
          </TabPanel>
          )}
        </TabPanel>
        <TabPanel
          value="2"
          className="AccountSettings_panel"
          style={{
            
            fontSize: "18px",
            textTransform: "capitalize",
            height:"100%",
            padding:0
            
          }}
        >
          {PasswordSettigs()}{" "}
        </TabPanel>
      </TabContext>
     
    </div>

  )

}

export default Settings