
//package imports
import React, { useState, useEffect, useMemo } from "react";
import {
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  Paper,
} from "@mui/material";
import { ReusableDropDown } from "../../utils/reusableComponents/ReusableDropDown";
import { Controller, useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useHistory } from "react-router-dom";
import parse from 'html-react-parser';

//component imports
import CustomButton from "../../utils/reusableComponents/CustomButton";
import CalendarIcon from "../../assests/images/calenderIcon.png";
import { getParticularsForm, submitParticularForm } from "../../services/apiServices/PostServices";
import { showToast } from "../../utils/constants/ToastConstants";
import { getOccuranceList } from "../../services/apiServices/GetServices";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faChevronRight } from '@fortawesome/free-solid-svg-icons'



const FormParticulars = (props) => {

  const [description, setDescription] = useState("");
  const [mainDescription, setMainDescription] = useState("");
  const [particularData, setParticularData] = useState([])
  const formCreatedDate = props?.location?.state?.info?.start_date
  const [selectedData, setSelectedData] = useState([])
  const [lenght, setLength] = useState(0)
  const history = useHistory();
  const [overAllStatus, setOverAllStatus] = useState("")
  const [statusData, setStatusData] = useState([])
  const [checked, setChecked] = useState(false)
  const [statusNotes, setStatusNotes] = useState("")
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [particularInfoData, setParticularInfoData] = useState([])
  const [comment, setComment] = useState("")
  const [createdDate, setCreatedDate] = useState("")


  const parsedString = parse(comment)

  const id = props?.location?.state?.projectId

  const user_id = props?.location?.state?.clientId

  const details = props?.location?.state?.clientDetails

  const email = props?.location?.state?.clientDetails?.email

  const name = props?.location?.state?.clientDetails?.name

  const organisation_name = props?.location?.state?.clientDetails?.organisation_name

  let result = props?.location?.state?.info?.date.substring(0, 10)

  const date = new Date(); // Create a new Date object

const options = { weekday: 'short' };
const day = date.toLocaleString('en-US', options);

  const classUnhealthy =
    " flex text-orange-400 font-bold border border-orange-400 w-24 h-6 justify-center items-center rounded-md mr-20";

  const classHealthy =
    "flex text-lime-500 font-bold border border-teal w-24 h-6 justify-center items-center rounded-md mr-20 ";

  const classDegraded =
    "flex  text-rose-500 border font-bold border-rose-500 w-24 h-6 justify-center items-center rounded-md mr-20";

  const classNa =  "flex  text-rose-500 border font-bold border-rose-500 w-24 h-6 justify-center items-center rounded-md mr-20"

  //function to handle date format.
  const handleDateFormat = (dateValue) => {

    let date = new Date(dateValue);

    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `${date.getFullYear()}-${month}-${day}`

    return dateMDY
  }


  //function to change timeformat
  const handleTimeFormat = (dateValue) => {
    let date = new Date(dateValue);
    let hours = date.getUTCHours() < 10 ? `0${date.getUTCHours()}` : `${date.getUTCHours()}` 
    let minutes = date.getUTCMinutes() < 10 ? `0${date.getUTCMinutes()}` : `${date.getUTCMinutes()}` 
    let seconds = date.getUTCSeconds() < 10 ? `0${date.getUTCSeconds()}` : `${date.getUTCSeconds()}`
    const amPm = hours >= 12 ? 'PM' : 'AM';
    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `${hours}:${minutes}:${seconds} ${amPm}`


    return dateMDY
  }


  const handleConvertDate = (value) => {
    let dateParts = result != undefined ? value.split("/") : []

    const dateValue = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`
    let date = new Date(dateValue);
    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `${date.getFullYear()}-${month}-${day}`

    return dateMDY

  }



  const {
    register,
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm();


  useEffect(() => {
    if (id != undefined) {
      getParticularForm()
      callStatusList()
    }
    if (result != undefined) {
      getFormDetails()
    }

  }, []);


  //function to call get particularFormdetails api
  const getParticularForm = async () => {

    const req_body = {
      date: handleDateFormat(new Date())
    }

    const res = await getParticularsForm(`admin/project_particulars/${id}`, req_body)
    if (res?.success) {
      setParticularData(res?.data)
    } else {
      console.log("res", res?.message)
    }

  }


  const getFormDetails = async () => {

    const req_body = {
      date: handleConvertDate(result)
    }

    const res = await getParticularsForm(`admin/client/project_status/${id}`, req_body)
    if (res?.success) {
      setParticularInfoData(res?.data)
      setComment(res?.data[0]?.comment)
      setCreatedDate(res?.data[0]?.created)
    } else {
      console.log("res", res?.message)
    }

  }


  const handleStatusData = (data) => {
    let filteredData = data.filter((data) => {
      return data?.status === 1
    })

    return filteredData
  }


  //function to call list api
  const callStatusList = async () => {
    const res = await getOccuranceList("master/status")
    if (res?.success) {
      setStatusData(handleStatusData(res?.data))
    } else {
      console.log("res", res?.message)
    }
  }

  const handleChangeDescription = (e) => {

    if (e === "<p><br></p>") {
      setDescription("");
      setValue();
      setValue("description", "");
      setMainDescription("")


    } else {
      const entireDataCutting = e.replace(/<\/?(?:p|span)[^>]*>/g, "");
      setMainDescription(e);
      setDescription(entireDataCutting);
      setValue("description", entireDataCutting);
    }


  };

  const submitConndition = (selectedData.length > 0 && overAllStatus != "" && description != "" && ((checked == true && statusNotes != "") || checked == false))


  //function to handle submit api
  const handleSubmitBody = async () => {
    if (checked == true && statusNotes == "") {
      showToast(3, "Please enter description for the checked status");
    }
    if (selectedData.length > 0 && overAllStatus != "" && description != "" && ((checked == true && statusNotes != "") || checked == false)) {
      const req_body = {
        health_report: selectedData,
        comment: description,
        overallStatus: overAllStatus,
        sendEmail: checked ? 1 : 0,
        organisation_name: organisation_name,
        email: checked ? email : "",
        sendMessage: statusNotes
      }

      const res = await submitParticularForm(`admin/health_report`, req_body)
      if (res?.success) {
        setButtonDisabled(true)
        showToast(1, res?.message);
        history.push({
          pathname: '/panel/clients/clientProject/particular',
          state: { heading1: "Client List", heading2: props?.location?.state?.name[0]?.heading1, heading3: props?.location?.state?.name[0]?.heading2, projectId: id, clientid: user_id, client_info: details }
        });
      } else {
        showToast(3, res?.message);
      }

    } else {
      showToast(3, "Please fill the required fields");
    }

  }



  //function to handle text onChange status
  const handleChange = async (e, id) => {
    const req_body = {
      particular_id: id,
      is_text: 1,
      value: e,
    }



    const newData = selectedData.map((data) => {
      if (data?.particular_id === id) {
        return { ...data, ...req_body }
      }
      return data
    })

    const val = selectedData.length != 0 ? selectedData.map((data) => {
      if (data?.particular_id === id && data?.value != "") {
        return true
      } else {
        return undefined
      }

    }) : []


    const filteredVal = val.find((data) => data != undefined)

    if (selectedData.length === 1 && req_body?.value === "") {
      setSelectedData([])
    } else {
      if ((selectedData.length != 0 && filteredVal)) {
        setSelectedData(newData)
      } else {
        setSelectedData([...selectedData, req_body])
      }
    }








    // setSelectedData([...selectedData, req_body])
  };

  let timeout = 0
  //function to handle Textfield
  const handleTextFiled = (evt, id) => {
    var Text = evt.target.value; // this is the search text
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      //search function
      handleChange(Text, id)
    }, 1000);
  }


  //function to handle statusNotes 
  const handleStatusNotes = (e) => {
    setStatusNotes(e.target.value)
  }


  //function to handle dropDown value
  const handleDropdown = (e, value, id) => {

    setLength(lenght + 1)
    const particular_id = id
    const req_body = {
      particular_id: id,
      is_text: 0,
      value: "",
    }

    req_body.value = e.target.value


    const newData = selectedData.map((data) => {
      if (data?.particular_id === id) {
        return { ...data, ...req_body }
      }
      return data
    })

    const val = selectedData.length != 0 ? selectedData.map((data) => {
      if (data?.particular_id === id) {
        return true
      } else {
        return undefined
      }

    }) : []


    const filteredVal = val.find((data) => data != undefined)

    if ((selectedData.length != 0 && filteredVal)) {
      setSelectedData(newData)
    } else {
      setSelectedData([...selectedData, req_body])
    }
  }

  //function to handle overall status value
  const handleOverAllStatus = (e) => {
    setOverAllStatus(e?.target?.value)
    if (e.target.value === 9) {
      setChecked(false)
    }
  }

  //function to handle defaultBehaviour of the form
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent the default Enter key behavior
      // Add your custom logic here
    }
  }

  //function to handle checkbox for the email triggering.
  const handleCheck = (e) => {
    if (e.target.checked) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }

  //function to change time 
  const handleDateStatusFormat = (dateValue) => {
    let date = new Date(dateValue);
    let hours = date.getUTCHours();
    const amPm = hours >= 12 ? 'PM' : 'AM';
    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `${day}/${month}/${date.getFullYear()}`


    return dateMDY
  }


  const returnParticularForm = () => {
    return (
      <div
        class="flex flex-col   mx-6 my-2    bg-white "
      >
        <div class="flex flex-row justify-between  mt-2">
          <div class="flex  w-1/5 2xl:w-96 justify-between  flex-row ml-4 ">
            <h1 class="text-black  font-bold text-1xl  ml-4 2xl:ml-12 " >S.No </h1>
            <h1 class="text-black 2xl:w-96 font-bold text-1xl mr-10 2xl:mr-20">Particulars</h1>
          </div>
          <div class="flex flex-row mr-4 ">
            <img src={CalendarIcon} class="mr-4" />
            <h1 class="text-primaryColor  font-bold text-1xl">{handleDateFormat(result || new Date())}</h1>
          </div>
        </div>
        <div>

          <div class="flex flex-col">
            <div class="self-start ml-5 w-full">
              {
                particularData.length !== 0 ?

                  <div>
                    {
                      particularData.map((particularData, i) => {

                        return (

                          <div class="flex flex-row  w-full justify-between my-2" key={particularData?.particular_id}>
                            <div class="flex flex-row w-2/4 justify-around ">
                              <div class="flex w-26 items-center">
                                <h1>{i + 1}</h1>
                              </div>
                              <div class="flex w-3/4 2xl:mr-2 items-center  ">
                                <h1 class="text-left " >{particularData?.particular_name}</h1>
                              </div>
                            </div>
                            <div class=" w-1/4 h-1/4 ">
                              {
                                particularData.is_text === 1 ?
                                  //<input placeholder="input" class="w-full" />
                                  <div class="full h-1/4">
                                    <TextField
                                      fullWidth
                                      placeholder="Enter Text"
                                      onChange={(e) => handleTextFiled(e, particularData?.particular_id)}
                                      inputProps={{
                                        style: {
                                          height: "10px",
                                        },
                                        maxLength: 150
                                      }}
                                      disabled={particularData?.testedCount.toString() >= particularData.frequency_name ? true : false}
                                    />
                                  </div>
                                  :
                                  <ReusableDropDown data={particularData?.json}
                                    disable={particularData?.testedCount.toString() >= particularData.frequency_name || (particularData?.occurrence_name === "Weekly" &&  day != "Mon") ? true : false}

                                    onChange={(e) =>

                                      handleDropdown(e, particularData?.json, particularData?.particular_id)
                                    }

                                  // colorChange={{
                                  //   "& .MuiInputBase-root": {
                                  //     color:handleChangeColor(particularData?.json,particularValue,particularData?.particular_id)
                                  //   }
                                  // }}
                                  />


                              }

                            </div>
                            <div class="flex w-48 h-12 ml-10 justify-start items-center ">
                              <h1> {particularData?.occurrence_name} </h1>
                              <h1 class="ml-2"> {particularData?.testedCount > particularData?.frequency_name ? particularData?.frequency_name : particularData?.testedCount}/{particularData?.frequency_name}</h1>
                            </div>
                          </div>
                        )
                      })

                    }
                  </div> :
                  <div>
                    ""
                  </div>
              }

            </div>
          </div>

        </div>
        <div class="flex flex-row mx-12 my-2 2xl:mx-16 max-w-screen-lg  2xl:max-w-4xl  ">


          <div class="w-full">
            <Controller
              name="desc"
              control={control}
              {...register("description", {
                required: description == "" && "Description is Required",

                minLength: {
                  value: 4,
                  message: "min description ",
                },
                maxLength: {
                  value: 300,
                  message: "max description required",
                },
              })}
              render={({ field: { value, onChange } }) => (
                <ReactQuill
                  theme="snow"
                  className="Description-padding description_helper"
                  value={mainDescription || description}
                  placeholder={"Enter Description"}
                  onChange={handleChangeDescription}
                  error={description === "" && Boolean(errors?.description)}
                />
              )}
            />

          </div>
        </div>

        <div class="flex w-48 h-4 mt-4 2xl:mx-6  justify-center items-center">
          <h1>Over All Status </h1>
        </div>

        <div class="flex  w-3/5 mt-2 my-2 mx-12 2xl:mx-16 2xl:max-w-4xl ">
          <ReusableDropDown
            control={control}
            data={statusData}
            value={overAllStatus}
            onChange={(e) => (handleOverAllStatus(e))}
            rules={{
              required: overAllStatus === "" && "bastionHost is required",
            }}
            colorChange={{
              "& .MuiInputBase-root": {
                "& input": {
                  textAlign: "right"
                },
                // color: handleChangeColor(statusDropDown?.name),
              }
            }}
          // disabled={idJob !== ""}

          />
          {overAllStatus != 9 && overAllStatus != "" ?
            <div class="flex flex-row mx-4">
              <input onKeyDown={handleKeyPress} type="checkbox" class="w-14 h-6 mt-2" onChange={(e) => handleCheck(e)} />
              <h1 class="w-40 h-10 mt-2 text-start">Trigger mail</h1>
            </div> :
            null
          }

        </div>

        {
          checked && overAllStatus != 9 ?
            <div class="flex flex-col w-3/5 mt-4 mx-12 2xl:mx-16  2xl:max-w-4xl">
              <h1 class="self-start">Enter Status Notes</h1>
              <TextField
                fullWidth
                placeholder="Enter Text"
                onChange={(e) => handleStatusNotes(e)}
                inputProps={{
                  style: {
                    height: "10px",
                  },
                  maxLength: 150
                }}
              // disabled={particularData?.testedCount.toString() >= particularData.frequency_name ? true : false}
              />

            </div>

            :
            null
        }



        <div class="mb-4 self-end mr-2 my-8">
          <CustomButton
            children={"Save"}
            disabled={!submitConndition && !buttonDisabled ? true : false}
            onClick={() => {
              handleSubmitBody()
            }}
            classVal={
              submitConndition ? "bg-teal-800 text-white w-36 h-10 hover:bg-teal-700 rounded-lg mt-8	" :
                "bg-slate-300 text-white w-36 h-10 cursor-default rounded-lg mt-8	"
            }
          // loading={loading}
          />
        </div>
      </div>
    )
  }

  const returnStatusForm = () => {

    return (
      <div class="flex justify-center items-center bg-white">
        {
          particularInfoData.length != 0 ?

            <div class="flex flex-col w-4/5 justify-center items-center   my-4">
              <div>
                <h1 class="font-bold text-2xl mr-4" > {handleTimeFormat(createdDate)}</h1>
              </div>
            
              <div class="flex flex-col w-4/5 justify-center items-start p-1 max-h-18  bg-cyan-50 mb-4 mt-2 border-2 border-cyan-50 ">
                 <h1 class="font-bold"> Message : {parsedString}</h1>
              </div>

              <div class="flex w-4/5 justify-between bg-tableHeaderColor  border flex-row  ">
                <div class="flex">
                <h1 class="text-black font-semibold   text-1xl ml-4" >S.No </h1>
                <h1 class="text-black font-semibold  w-60 text-left  text-1xl mx-16  ">Particulars/items</h1>
                  </div>
              
                <h1 class="text-black font-semibold  text-1xl mr-24">Status</h1>
              </div>


              {
                particularInfoData.map((data, index) => {
                  return (
                    <div class="flex flex-row  w-4/5 h-10 justify-between items-center   border border-t-0 border-zinc-300 " >
                      <div class="flex items-center justify-between w-90">
                        <h1 class="text-black text-left w-5 ml-4 text-1xl">{index + 1}</h1>
                        <h1 class="text-black w-60 text-left   ml-16   text-1xl">{data?.data?.name}</h1>
                      </div>

                      
                      <h1 class={
                        (data?.data?.status === "Degraded" && `${classDegraded}`)
                        || (data.data?.status === "Healthy" && `${classHealthy}`)
                        || (data.data?.status === "Unhealthy" && `${classUnhealthy}`) || (data.data?.status === "NA" && `${classNa}` )}>{data?.data?.status}</h1>
                    </div>
                  )
                })
              }




             

            </div>



            :
            null
        }
      </div>
    )
  }

  return (
    <div class="flex flex-col w-full  overflow-y-auto   ">
      <div class="bg-teal-50  w-full h-5/5  ">
        <div class="flex my-2 ml-6 self-start ">

          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            <Link to={{
              pathname: "/panel/clients/clientProject",
              state: { heading1: "Client List", heading2: props?.location?.state?.name[0]?.heading1, heading3: props?.location?.state?.name?.heading2, info: user_id }
            }}>
              <h1 class="text-black text-1xl font-bold">{props?.location?.state?.name[0]?.heading1?.heading2 || props?.location?.state?.name[0]?.heading1}</h1>
            </Link>
            <Link to={{
              pathname: "/panel/clients/clientProject/particular",
              state: { heading1: "Client List", heading2: props?.location?.state?.name[0]?.heading1, heading3: props?.location?.state?.name[0]?.heading2, projectId: id, clientid: user_id, client_info: details }
            }} >
              <h1 class="text-black text-1xl font-bold">{props?.location?.state?.name[0]?.heading2}</h1>
            </Link>
            <h1 class="text-black text-1xl font-bold">{props?.location?.state?.name[0]?.heading3}</h1>

          </Breadcrumbs>
            {
              particularInfoData.length != 0  ?
              <div class="flex h-6 justify-center items-center mx-2">
              <FontAwesomeIcon icon={faChevronRight} class="  h-2.5 w-4 mr-2"/>
              <h1 class="font-bold mr-4" >{handleDateStatusFormat(createdDate)}</h1>
            </div> :
            null
            }
          

        </div>
        {
          result === undefined ?
            returnParticularForm() :
            returnStatusForm()
        }


      </div>
    </div>

  );
};

export default FormParticulars
