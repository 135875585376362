
//package imports
import React, { useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";


//Component imports
import { sampleData, particularInfoHeader } from '../../utils/constants/TableData';
import BreadcrumbsHeader from '../../utils/reusableComponents/BreadCrumbsHeader';
import { getParticularsForm } from '../../services/apiServices/PostServices';
import parse from 'html-react-parser';


const ParticularInfo = (props) => {
  const { location } = props;
  const history = useHistory();
  const [particularInfoData, setParticularInfoData] = useState([])
  const [comment, setComment] = useState("")
  const [createdDate, setCreatedDate] = useState("")


  const parsedString = parse(comment)

  const timeProp = props?.location?.state?.date

  const timeArray = timeProp.split("-")



  //function to handle date format.
  const handleDateFormat = (dateValue) => {
    let date = new Date(dateValue);
    let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `${date.getFullYear()}-${month}-${day}`
    return dateMDY
  }


 


  //function to split date
  const handleCreatedDate = (date, val) => {
    if (date != undefined) {
      let dataDate = date.split("-")
      if (val === "date") {
        return dataDate[0]
      } else {
        return dataDate[1]
      }


    }

  }



  const id = props?.location?.state?.projectId

  let result = props?.location?.state?.info?.date.substring(0, 10)

  const date = props?.location?.state?.date

  //------------------color constants for buttons status ------------------------------------------------------------
  const classUnhealthy =
    " flex text-orange-400 font-bold border border-orange-400 w-24 h-6 justify-center items-center rounded-md mr-20";

  const classHealthy =
    " flex text-lime-500 font-bold border border-teal w-24 h-6 justify-center items-center rounded-md mr-20 ";

  const classDegraded =
    "flex text-rose-500 border font-bold border-rose-500 w-24 h-6 justify-center items-center rounded-md mr-20";


  const heading = {
    heading1: props?.location?.state?.name?.heading2,
    heading2: "Particulars"
  }

  


  let pathName = `${location?.pathname}/particularInfo`

  const ProjectsPath = `clients/projects`


  useEffect(() => {
    if (id != undefined) {
      particularInfo()
    }
  }, []);


  //function to get predefined status  
  const particularInfo = async () => {

    let dateParts = result.split("/")


    let dateValue = `${dateParts[2]}-${dateParts[0]}-${dateParts[1]}`




    const req_body = {
      date: handleDateFormat(new Date(dateValue))
    }

    const res = await getParticularsForm(`admin/client/project_status/${id}`, req_body)
    if (res?.success) {
      setParticularInfoData(res?.data)
      setComment(res?.data[0]?.comment)
      setCreatedDate(res?.data[0]?.created)

    } else {
      console.log("res", res.message)
    }
  }



  return (
    <div class="flex flex-col ml-10 mr-4 w-full ">
      <div class="max-h-2/5 scroll-auto">
        <div class="flex my-4  self-start">
          <BreadcrumbsHeader
            heading1={props?.location?.state?.name?.heading2}
            heading2={props?.location?.state?.name?.heading1}
            heading3={handleCreatedDate(date, "date")}
            path={ProjectsPath}
            projectId={id}
          />
          {/* <div class="flex h-6 justify-center items-center" >
          <FontAwesomeIcon icon={faChevronRight} class="  h-2 w-4 "/>
          <h1 class=" mx-2 text-base font-bold" > {handleCreatedDate(date, "date")}</h1>
          </div> */}
        </div>

      </div>
      <div class="flex justify-center items-center ">
        {
          particularInfoData.length != 0 ?

            <div class="flex flex-col w-4/5 justify-center items-center    my-4">
              <div>
                <h1 class="font-bold text-2xl mr-4" >{timeArray[1]}</h1>
              </div>
              <div class="flex flex-col justify-center items-center bg-cyan-50 items-start p-1 w-4/5 border-cyan-50  max-h-28  mb-4 mt-2 border  ">
            

                <h1 class="font-bold" > Message : {parsedString}</h1>
              </div>
              <div class="flex w-4/5 justify-between bg-tableHeaderColor  border  flex-row  ">
                <div class="flex">
                  <h1 class="text-black font-semibold   text-1xl ml-4" >S.No </h1>
                  <h1 class="text-black font-semibold  w-60 text-left  text-1xl mx-16  ">Particulars/items</h1>
                </div>

                <h1 class="text-black font-semibold  text-1xl mr-24">Status</h1>
              </div>


              {
                particularInfoData.map((data, index) => {
                  return (
                    <div class="flex flex-row  w-4/5 h-10 justify-between items-center border border-t-0 border-zinc-300 " >
                      <div class="flex items-center justify-between w-90">
                        <h1 class="text-black text-left w-5 ml-4 text-1xl">{index + 1}</h1>
                        <h1 class="text-black w-60 text-left   ml-16   text-1xl">{data?.data?.name}</h1>
                      </div>
                      <h1 class={
                        (data?.data?.status === "Degraded" && `${classDegraded}`)
                        || (data.data?.status === "Healthy" && `${classHealthy}`)
                        || (data.data?.status === "Unhealthy" && `${classUnhealthy}`)}>{data?.data?.status}</h1>
                    </div>
                  )
                })
              }






            </div>

            :
            null
        }
      </div>



    </div>
  )
}

export default ParticularInfo