
//package imports
import React, { useState, useEffect } from 'react'
import { withRouter, useHistory } from "react-router-dom";

//component imports
import noDataImg from '../../assests/images/nodata-icon.png'
import ModalWithTextFields from '../../utils/reusableComponents/ModalWithTextFields';
import { CustomModal } from '../../utils/reusableComponents/CustomModal';
import MuiCustomTabel from '../../utils/reusableComponents/MuiCustomTabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { clientDashBoard } from '../../utils/constants/TableData';
import { getDashboardDetails, getProjectsList } from '../../services/apiServices/GetServices';
import { getData } from "../../utils/AsyncStorage";



const ClientDashboard = () => {
    const history = useHistory();
    const [showModal, setshowModal] = useState(false);
    const [change, setChange] = useState(false)
    const [dataArray, setDataArray] = useState([])
    const [projectEndData, setProjectEndData] = useState([])

    const userData = getData("client_id");
    const parsedUserData = JSON.parse(userData);

    const id = parsedUserData

    useEffect(() => {
        if (id != undefined) {
            callDashboardApi()
            callProjectEndlistApi()
        }

    }, []);

    //function to call list api
    const callDashboardApi = async () => {
        const res = await getDashboardDetails(`admin/dashboard/${id}`)
        if (res?.success) {
            setDataArray(res?.data[0])
        } else {
            console.log("res", res?.message)
        }
    }


    //function to call list api
    const callProjectEndlistApi = async () => {
        const res = await getProjectsList(`admin/dashboard/subscription/${id}`)

        if (res?.success) {
            setProjectEndData(res?.data
            )
        } else {
            console.log("res", res?.message)
        }
    }




    const handleChangeIcon = () => {
        setChange(!change)
    }


    const projectDeactivatedCount = projectEndData?.length



    return (
        <div class="flex flex-col justify-center items-center w-full " >

            <div class="flex flex-col justify-start w-3/5 h-4/5 items-start mt-4" >

                <div class="flex flex-row  justify-center items-center gap-x-2 w-full ">
                    <div class="flex flex-col bg-colorTotalClient border-borderColorClient border w-3/5 h-28 rounded-md justify-center items-center   " >

                        <h1 class="text-2xl text-boxText1 font-bold my-2" >Total Projects</h1>
                        <h1 class="text-2xl text-boxText1 font-bold mx-4">{dataArray.length != 0 ? dataArray?.total_projects : 0}</h1>
                    </div>


                    <div class="flex flex-col bg-colorTotalProjects border-borderColorProjects border w-3/5 h-28 rounded-md justify-center items-center ml-2  " >

                        <h1 class="text-2xl text-boxText3 font-bold my-2" >Active Projects</h1>
                        <h1 class="text-2xl text-boxText3 font-bold">{dataArray.length != 0 ? dataArray?.active_projects : 0}</h1>
                    </div>
                </div>

                <div class="flex flex-col my-2   w-full max-h-min border-primaryColor border-2 rounded-xl justify-between self-center ">
                    <div class="flex flex-row w-full h-10 my-2 justify-between items-center">
                        <h1 class="mx-2 text-1xl font-bold "> Projects Subscription Ended : <span class="text-1xl  ml-10"> {projectDeactivatedCount}  </span> </h1>
                        <div onClick={() => projectDeactivatedCount === 0 ? null : handleChangeIcon()} class={projectDeactivatedCount === 0 ? "flex w-8 h-8 rounded-2xl justify-center items-center bg-slate-300 mr-4 shadow-sm shadow-black cursor-pointer" : "flex w-8 h-8 rounded-2xl justify-center items-center mr-4 shadow-sm shadow-black cursor-pointer"}>
                            {
                                projectDeactivatedCount === 0 ? <FontAwesomeIcon icon={faChevronDown} class="h-4 w-4 text-slate-200" /> :
                                    <FontAwesomeIcon icon={change ? faChevronUp : faChevronDown} class="h-4 w-4 " />
                            }
                        </div>

                    </div>
                    {
                        change ?
                            <div class="w-full  pl-2 pr-2">
                                <MuiCustomTabel
                                    //    path={pathName}
                                    //     name={heading}
                                    data={projectEndData}
                                    columnHeader={clientDashBoard}
                                    fromDashboard={true}
                                    color="#036572"
                                />
                            </div>

                            :
                            null
                    }





                </div>

            </div>


        </div>
    )
}

export default ClientDashboard