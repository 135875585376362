import debounce from "lodash.debounce";
import searchImg from "../../assests/images/searchImg.png.png" 
import searchCross from "../../assests/images/searchCrossIcon.png"
import React, { useCallback, useRef, useState } from "react";

export const ReusableSearch = (props) => {

  const [value,setValue]=useState("")


  //function to check string validation
  const checkString = (string) => {
    let string1=string 
    // return   /^[a-zA-Z0-9/s@#$%&'*+-/=?^_`{|]*$/.test(string1)
    const regex = /^\s/;
  if (regex.test(string1)) {
    return false; // Space is at the beginning
  }
  return true;
  }

//function to handle on change
  const handleChange =  (e) => {
    
  if(checkString(e)){
    props?.onChange(e)
    // props?.onChangeSearch(e)
    setValue(e)
   }
 
 
  };

  //function to clear search
  const handleClear = () => {
    props?.onClear()
    setValue("")

  }

  return (
    <div class=' flex flex-row justify-center items-center min-w-min  bg-right bg-no-repeat bg-white flex border  border-black-700 rounded-md pl-5  p-2  '>
      <input
        type="text"
        value={value}
        placeholder="Search"
         onChange={(e) => handleChange(e.target?.value)}
        class="border outline-transparent border-0 border-transparent"
      />
      <div>
        {
             value === "" ? 
             <img src={searchImg} class="w-4 h-4 mx-2" /> :
             <img onClick={() => handleClear() } src={searchCross}  class="w-4 h-4 mx-2 cursor-pointer" />
        }
      </div>
      
    </div>
  );
};
