import React from "react";
import { useRouteMatch, useHistory } from "react-router-dom";


//Local imports
import Logo from "../../assests/images/Logo.png";
import appLogo from "../../assests/images/homeLogo.png"
import PublicRoute from "../../router/PublicRoute";
import { APP_CONSTANTS } from "../../utils/constants/AppConstants";
import { DropDownCon } from "../../utils/constants/DropDownConstants";
import ForgotPassword from "./ForgotPassword";
import SetNewPassword from "./SetNewPassword";
import SignIn from "./SignIn";

//css imports
import "./Login.css";


export const Login = (props) => {
  const { path } = useRouteMatch();
  const { location } = props;
  const pathnameLast =
    location?.pathname.includes('set-password') ? "set-password" : location.pathname.substring(
      location.pathname.lastIndexOf("/") + 1
    );
  const history = useHistory();

  return (
    <div className="grid grid-cols-2 divide-x">
      <div className="coverImage">
        <div className="flex flex-col h-screen">
          <div className="relative h-32 w-32">
            <img className="absolute left-8 top-10 " src={Logo} alt="log" />
          </div>
          <div className="flex flex-col items-center text-center m-auto mt-[8%] 2xl:mt-[8%]">
            <h1 className="flex text-4xl items-center text-white text-base pb-3 font-normal 2xl:text-4xl">
              {APP_CONSTANTS?.WELCOME}
            </h1>
            <img src={appLogo} class="w-40 h-3/5" alt="logo" />
            <p className="flex items-center text-lg text-white my-4 font-light 2xl:text-4xl">
              {APP_CONSTANTS?.APP_DES}
            </p>

            <div class="text-left  text-white mt-4 ml-8 ">
              <ul class="list-disc text-base  2xl:text-2xl">
                <li>Track & monitors your application's health & performance </li>
                <li>Alerts you when your application is not performing perfectly </li>
                <li>Provides complete information about your application performance</li>
                <li>Provides the user with easy comprehensive data through its user-friendly interface design </li>
                <li>You can login and track your apps health from anywhere and everywhere </li>
                <li>Available for any kind of website, web app or mobile applications </li>
              </ul>

            </div>

            <div className="flex bg-primaryColor text-white justify-center items-center border w-28 h-10 rounded-md my-4 2xl:w-40  2xl:h-18 2xl:mt-20 ">
              <div >


                <a href="https://forms.gle/Xd96X8Re5cMJFJMPA" target="_blank">
                  <button class="2xl:text-2xl " >
                    KNOW MORE
                  </button>
                </a>




              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="flex flex-col justify-center">
        <h1 className="text-black text-3xl font-semibold">
          {DropDownCon.headerByPath[pathnameLast]}
        </h1>
        <p className="text-center w-3/5 mt-2.5 ml-auto mb-2.5 mr-auto text-gray text-lg">
          {DropDownCon.headingDes[pathnameLast]}
        </p>
        <PublicRoute restricted={true} component={SignIn} path={path} exact />
        <PublicRoute
          restricted={true}
          component={ForgotPassword}
          path={`${path}/forgot-password`}
          exact
        />
        <PublicRoute
          restricted={true}
          component={SetNewPassword}
          path={`${path}/set-password`}
        // exact
        />
      </div>
    </div>
  );
};
