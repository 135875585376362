import { toast } from "react-toastify";

export const showToast = (type, message) => {
  // 0 = warning, 1 = success 2 = info 3=warning

  switch (type) {
    case 0:
      toast.warning(message);
      break;
    case 1:
      toast.success(message);
      break;
    case 2:
      toast.info(message);
      break;
    case 3:
      toast.error(message);
      break;
    default:
      break;
  }
};