//package imports
import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useHistory } from "react-router-dom";
import dayjs from 'dayjs';

import { Checkbox, Grid, IconButton, Paper, TextField } from "@mui/material";

//component imports
import CustomTextField from "../../utils/reusableComponents/CustomTextField";
import { AuthConstants } from "../constants/AuthConstnats";
import { APP_CONSTANTS } from "../constants/AppConstants";
import { updateProject, createProject } from "../../services/apiServices/PostServices";
import { showToast } from '../../utils/constants/ToastConstants';






const ModalWithDatePicker = ({ closeModal, update, onAction, client_id, data ,status,email}) => {

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      checkbox: false,
    },
  });


  const history = useHistory();
  const [projectName, setProjectName] = useState("")
  const [endDate, setEndDate] = useState("")
  const [startDate, setStartDate] = useState("")
  const [startErrorMessage, setStartErrorMessage] = useState("")
  const [endErrorMessage, setEndErrorMessage] = useState("")

  
  useEffect(() => {
    if (update) {
      onUpdate()
      setValue("Project", data?.Project_name)
      setValue("start_date",data?.Start_date)
      setValue("end_date",data?.End_date)
    } else {
      onCreate()
    }
  }, []);



  const onUpdate = () => {
    let startDate = handleDateFormat(data?.Start_date)
    let endDate = handleDateFormat(data?.End_date)
    setProjectName(data?.Project_name)
    setStartDate(startDate)
    setEndDate(endDate)

  }

  const onCreate = () => {
    setProjectName("")
    setStartDate("")
    setEndDate("")
  }




  const handleDateFormat = (dateValue) => {

    let date = new Date(dateValue);

  let day = date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `${date.getFullYear()}-${month}-${day}`

    return dateMDY
  }



  const handleChange = (value) => {
    let formatedDate = handleDateFormat(value)
    setStartDate(formatedDate)
    setStartErrorMessage("")
  }

  const handleChangeEnd = (value) => {
    let formatedDate = handleDateFormat(value)
    setEndDate(formatedDate)
    setEndErrorMessage("")
  }

  const checkString = (string) => {
    let string1=string 
    return /^[A-Za-z0-9.-\s]*$/.test(string1);
}

  const handleProjectName = (e) => {
    let value = e
    if(checkString(value)){
      setProjectName(value)
    }
  }



  //function on create project
  const handleCreate = async () => {

    const today = new Date(); 
    const dateToCheckEndDate = new Date(endDate);

    const dateToCheckStartDate = new Date(startDate)

    if(dateToCheckEndDate < today || dateToCheckStartDate > dateToCheckEndDate ){
      showToast(3, "Please select valid end date");
    }else{
      const req_body =
      {
        Project_name: projectName,
        Start_date: startDate,
        End_date: endDate,
        client_id: client_id
      }
  
      const res = await createProject("admin/project", req_body);
      if (res?.success) {
        showToast(1, "Project created successfully");
        setProjectName("")
        setStartDate("")
        setEndDate("")
        closeModal()
        onAction()
      } else {
        showToast(3, res?.message);
      }
  
    }

   


  }

  //function on update Project 
  const handleUpdate = async () => {

    const today = new Date(); 
    const dateToCheckEndDate = new Date(endDate);

    const dateToCheckStartDate = new Date(startDate)

    if(dateToCheckEndDate < today || dateToCheckStartDate > dateToCheckEndDate ){
      showToast(3, "Please select valid end date");
    }else{
         const req_body =
    {
      Project_name: projectName,
      Start_date: startDate,
      End_date: endDate,
    }

   
    const res = await createProject(`admin/project/${data?.id || data?.project_id}`, req_body);
    if (res?.success) {
      showToast(1, "Project updated successfully");
      closeModal()
      onAction()
    } else {
      showToast(3, res?.errors?.msg);
      res?.errors?.map((data) => {
        showToast(3, data?.msg);
      })
    }
    }

   

  }


  const onSubmit = () => {
    if (startDate == "") {
      setStartErrorMessage("Enter Start Date")
    }
    if (endDate == "") {
      setEndErrorMessage("Enter End Date")
    }
    if (startDate != "" && endDate != "" && projectName != "") {
      update ? handleUpdate() : handleCreate()

    }
  }



  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <Paper elevation={0} className={`paper_margin`} class=" m-auto my-3">
        <Grid container direction={"column"} justifyContent={"center"} class=" flex flex-col ">

          <div class="mx-12" >

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} justifyContent={"center"} class=" my-5 mt-1.5">
              <CustomTextField
                control={control}
                header={"Project Name"}
                required
                //maxWidth={"90%"}
                name="Project"
                size="small"
                value={projectName.trimStart()}
                placeholder={"Project Name"}
                borderRadius={"0.25em"}
                inputProps={{ minLength: AuthConstants?.CLIENTNAME_MINLENGTH, maxLength: AuthConstants?.CLIENTNAME_MAXLENGTH }}
                rules={{
                  required: APP_CONSTANTS?.PROJECT_NAME_REQUIRED_Message,
                  // pattern: {
                  //   value: ValidationConstants.TypeConstants.EmailType,
                  //   message: AuthConstants.NOT_VALID_EMAIL,
                  // },
                  minLength: {
                    value: 3,
                    message: "Enter minimum 3 charaters",
                  }
                }}
                InputProps={{ sx: { borderRadius: "0.25em" } }}
                onChange={(e) => {
                  handleProjectName(e);
                }}
              />


            </Grid>

            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} justifyContent={"center"} class=" my-5 mt-1.5">
              <div class="flex flex-col">
                <div class="self-start" >
                  <label>Start Date<span class="text-rose-500">*</span></label>
                </div>
                
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  
                  <DesktopDatePicker
                    format="DD/MM/YYYY"
                    // disabled = {true}
                    disablePast={true}
                    slotProps={{ textField: { size: 'small', error: false,borderRadius:"0.75em" }, }}
                     value={dayjs(startDate)}
                    onChange={handleChange}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        autoComplete="off"
                        fullWidth
                        {...params}
                        InputProps={{ sx: { borderRadius: "0.25em" } }}
                        name="start_date"
                      // {...register("date", {
                      //   required:
                      //     startDate == "" &&
                      //     "Start Date Is Required",
                      // })}
                      //  error={false}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              {
                startErrorMessage != "" ?
                  <div class="flex justify-start">
                    <p class="text-red-400 text-red capitalize">
                      {startErrorMessage}
                    </p>
                  </div> :
                  null
              }


              <div class="flex flex-col mt-4">
                <div class="self-start" >
                  <label>End Date<span class="text-rose-500">*</span></label>
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    format="DD/MM/YYYY"
                    // disabled = {true}
                    PopperProps={{
                      sx: {
                        "& .MuiPaper-root": {
                          innerHeight: "300px"
                        }
                      }
                    }}
                    disablePast={true}
                    value={dayjs(endDate)}
                    slotProps={{ textField: { size: 'small', error: false } }}
                    InputProps={{
                      disableUnderline: true
                    }}

                    onChange={handleChangeEnd}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        autoComplete="off"
                        fullWidth
                        {...params}
                        name="end_date"
                      // {...register("date", {
                      //   required:
                      //     startDate == "" &&
                      //     "Start Date Is Required",
                      // })}
                      //  error={endDate == null && Boolean(errors.date)}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              {
                endErrorMessage != "" ?
                  <div class="flex justify-start">
                    <p class="text-red-400 text-red capitalize">
                      {endErrorMessage}
                    </p>
                  </div> :
                  null
              }

            </Grid>
          </div>

          <div class="self-center" >

            <button onClick={closeModal} class="text-primaryColor border border-primaryColor w-32 h-10 rounded-lg mt-12">
              Cancel
            </button>

            <button type="submit" class="bg-primaryColor w-32 h-10 rounded-lg shadow-md text-white mt-12 mx-2" >
              {update ? "Update" : " Create"}
            </button>
          </div>


        </Grid>
      </Paper>
    </form>
  )
}




export default ModalWithDatePicker