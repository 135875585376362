//package imports
import React, { useState, useEffect } from 'react'

//component imports
import BreadcrumbsHeader from '../../utils/reusableComponents/BreadCrumbsHeader';
import { ReusableDropDown } from "../../utils/reusableComponents/ReusableDropDown";
import { Controller, useForm } from "react-hook-form";
import { getSupportResults } from '../../services/apiServices/PostServices';
import { TextField } from '@mui/material';
import { getData } from "../../utils/AsyncStorage";
import { showToast } from '../../utils/constants/ToastConstants';
import CustomButton from '../../utils/reusableComponents/CustomButton';
import CustomTextField from '../../utils/reusableComponents/CustomTextField';


const ClientSupport = () => {

    const [clientDescription, setClientDescription] = useState("")
    const [selectedProject, setSelectedProject] = useState("")
    const [projectData, setProjectData] = useState([])
    const [supportMail, setSupportMail] = useState("")
    const [supportNumber, setSupportNumber] = useState("")

    const userData = getData("client_id");
    const parsedUserData = JSON.parse(userData);


    const id = parsedUserData

    useEffect(() => {
        if (id != undefined) {
            callSupportDetails()
        }
    }, []);

    
   //function to call api to get the support details
    const callSupportDetails = async () => {

        const req_body = {
            description: "Testing",
            sendMail: 0
        }


        const res = await getSupportResults(`admin/supportPage/${id}`, req_body);

        if (res?.success) {
            //   showToast(1, res?.message);
            setProjectData(res?.data)
            setSelectedProject(res?.data[0].Project_name)
            setSupportMail(res?.mail)
            setSupportNumber(res?.number)
        } else {
            showToast(3, res?.errors?.msg);
            //   res?.errors?.map((data) =>{ 
            //       showToast(3, data?.msg);
            //   })

        }
    }


    //function to sumbit client request which trigers mail.
    const handleSubmitRequest = async () => {


        if (selectedProject === "") {
            showToast(3, "Please select project")
        }
        if (clientDescription === "") {
            showToast(3, "Please enter details in the description box")
        } if (selectedProject != "" && clientDescription != "") {
           
            const req_body = {
                Project_name: selectedProject,
                description: clientDescription,
                sendMail: 1
            }

            const res = await getSupportResults(`admin/supportPage/${id}`, req_body);
            if (res?.success) {
                   showToast(1, "Your support request has been submitted successfully");
                  setSelectedProject("")
                  setClientDescription("")
            } else {
                // showToast(3, res?.errors?.msg);
                //   res?.errors?.map((data) =>{ 
                //       showToast(3, data?.msg);
                //   })
                console.log("res,message",res?.message)
            }

            



        }



    }


    //function to check string validations
    const checkString = (string) => {
        let string1 = string
        return /^[a-zA-Z0-9\s]*$/.test(string1)
    }

    //function to update state of the description.
    const handleClientNotes = (e) => {
        let name = e
        if (checkString(name.trimStart())) {
            setClientDescription(name.trimStart())
        }

    }

    //function to update state of the selected project
    const handleProjectsSelcted = (e) => {
        setSelectedProject(e.target.value)
    }

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm();


    return (
        <div class="flex flex-col ml-10 mr-4 w-full ">
            <div class="flex my-2 self-start">
                {/* <BreadcrumbsHeader heading1={"Support"}

                /> */}
                <h1 class="text-lg font-bold" >Support</h1>
            </div>
            <div class="flex flex-row w-full h-3/4 border border-primaryColor" >

                <div class="flex flex-col w-1/2 h-4/4   ">

                    <div class=" flex flex-col my-8 mx-8 ">
                        <h1 class="text-lg text-left mx-2 font-bold">For any support or assistance, please contact:</h1>
                        <div class="flex flex-col w-80 rounded-md h-24 justify-center items-center bg-colorTotalClient mx-2  my-4">
                            <h1 class="w-72 text-1xl  text-left font-bold mx-2" >Email :   <span class=" text-1xl font-normal">{supportMail}</span> </h1>
                            <h1 class="w-72 text-left font-bold mx-2">Mobile Number :  <span class=" text-1xl font-normal">{supportNumber}</span>  </h1>
                        </div>

                    </div>

                </div>

                <div class="w-2/5 h-4/4 my-10 mx-8" >
                    <h1 class="text-left font-bold">Select Project</h1>
                    <ReusableDropDown
                        control={control}
                        data={projectData}
                        // defaultValue={selectedProject}
                        value={selectedProject}
                        name={true}
                        onChange={(e) => (handleProjectsSelcted(e))}
                        rules={{
                            required: selectedProject === "" && "Description is required",
                        }}
                        colorChange={{
                            "& .MuiInputBase-root": {
                                "& input": {
                                    textAlign: "right"
                                },
                                // color: handleChangeColor(statusDropDown?.name),
                            }
                        }}


                    />

                    <div>
                        <div class="flex flex-col w-5/5 mt-4 ">
                            <h1 class="self-start font-bold">Description</h1>
                            <CustomTextField
                                control={control}
                                name="Address"
                                size="small"
                                value={clientDescription}
                                placeholder={"Type your message here"}
                               
                                multiline={true}
                                rows={3}
                                rules={{
                                    //required: APP_CONSTANTS?.ADDRESS_REQUIRED_Message,
                                    minLength: {
                                        value: 3,
                                        message: "Enter minimum 3 charaters ",
                                    },
                                    minLength: {
                                        value: 150,
                                    }
                                }}
                                onChange={(e) => {
                                    handleClientNotes(e);
                                }}
                            />
                        </div>
                        <div class="mb-4  mr-2">

                            <CustomButton
                                children={"Submit"}
                                disabled={selectedProject != "" && clientDescription != "" ? false : true}
                                onClick={() => { 
                                    handleSubmitRequest()
                                }}
                                classVal={
                                    selectedProject != "" && clientDescription != "" ? "bg-teal-800 text-white w-36 h-10 hover:bg-teal-700 rounded-lg mt-8	" :
                                        "bg-slate-400 text-white w-36 h-10 rounded-lg mt-8	"
                                }
                            // loading={loading}
                            />

                            {
                                clientDescription === "" ?
                               <h1 class="text-slate-400">
                               Please fill the data in the field
                            </h1> :
                            null 

                            }

                          
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}



export default ClientSupport