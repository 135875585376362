import React, { useState,useEffect } from 'react'

import classNames from "classnames";
import { useRouteMatch } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Bars3Icon } from "@heroicons/react/24/outline";
import PublicRoute from "../.././src/router/PublicRoute";
import PrivateRoute from "../.././src/router/PrivateRoute";
import Sidebar from './dashboard/sidebar';
import Settings from './dashboard/Settings';
import { getData } from '../utils/AsyncStorage';
import Particular from './projectList/Particular';
import FormParticulars from './dashboard/FormParticulars';
import ClientDashboard from './clientDashboard/ClientDashboard';
import Project from './clientDashboard/Projects';
import ParticularList from './clientDashboard/ParticularList';
import ParticularInfo from './clientDashboard/ParticularInfo';
import ClientSupport from './clientDashboard/ClientSupport';


const ClientPanel = (props) => {
    const { path } = useRouteMatch();
    const history = useHistory();
    const [collapsed, setSidebarCollapsed] = useState(false);
    const data = JSON.parse(getData("userInfo"));
    const userLoggedIn = `Logged in as ${data?.name}`

   

    return (
        <div >
            <div class="flex flex-row h-full " >

                <div
                    className={classNames({
                        // 👇 use grid layout
                        "grid min-h-screen": true,
                        // 👇 toggle the width of the sidebar depending on the state
                        // "grid-cols-sidebar  ": !collapsed,
                        "grid-cols-sidebar-collapsed": collapsed,
                        // 👇 transition animation classes
                        "transition-[grid-template-columns] duration-300 ease-in-out": true,
                    })}

                >
                    <Sidebar
                        collapsed={collapsed}
                       
                    // setCollapsed={() => setSidebarCollapsed((prev) => !prev)}
                    />
                </div>

                <div class="flex-1 flex-col w-5/5 ">
                    <div>
                    <nav
                        class="relative flex  w-full  items-center justify-between bg-primaryColor py-2 text-neutral-500 shadow-lg hover:text-neutral-700 focus:text-neutral-700 dark:bg-neutral-600 lg:py-4 h-10">
                        <div class="flex w-full flex-wrap items-center justify-between px-3">
                            <div>
                                <a
                                    class="mx-2 my-1 flex items-center text-neutral-900 hover:text-neutral-900 focus:text-neutral-900 lg:mb-0 lg:mt-0"
                                    >
                                    <div className="bg-primaryColor text-white">
                                        <button onClick={() => setSidebarCollapsed((prev) => !prev)}>
                                            <Bars3Icon className="w-6 h-6 mt-1.5 mx-2" />
                                        </button>
                                    </div>
                                    <span class="font-medium text-white"
                                    >Stridefuture Technologies</span>
                                </a>
                            </div>
                            <div class="flex flex-row">
                                <h1 class="text-white">
                                {userLoggedIn}
                                </h1>
                                {/* <div >
                                <img
            src="https://tecdn.b-cdn.net/img/new/avatars/2.jpg"
            class="rounded-full"
            style={{height: "25px",width: "25px"}}
            alt=""/>
                                </div> */}
                                
                            </div>
                        </div>
                    </nav>
                    </div>
                    
                    <div class=" flex flex-row h-4/5 ">
                        
                    
                    <PrivateRoute
                        restricted={true}
                        component={ClientDashboard}
                        path={`${path}/clientDashboard`}
                        
                    />
                    <PrivateRoute
                        restricted={true}
                        component={Project}
                        path={`${path}/projects`}
                        exact
                    />

                     <PrivateRoute
                        restricted={true}
                        component={ParticularList}
                        path={`${path}/projects/particularList`}
                        exact
                    />

                    <PrivateRoute
                        restricted={true}
                        component={ParticularInfo}
                        path={`${path}/projects/particularList/particularInfo`}
                        exact
                    />

                    <PublicRoute
                        restricted={false}
                        component={ClientSupport}
                        path={`${path}/clientSupport`}
                        exact
                    />

                    <PublicRoute
                        restricted={false}
                        component={Settings}
                        path={`${path}/settings`}
                        exact
                    />


                    {/* <PublicRoute
                        restricted={false}
                        component={SignOut}
                        path={`${path}/signout`}
                        exact
                    /> */}

                    </div>
                  
                </div>



            </div>

        </div>
    )
}

export default ClientPanel