import CustomButton from "./CustomButton"
import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, Grid, IconButton, Paper, TextField } from "@mui/material";
import CustomTextField from "../../utils/reusableComponents/CustomTextField";
import { showToast } from "../constants/ToastConstants";




       
const SimpleModal = ({closeModal,onAction,inputFiled,inputHeader,inputPlaceholder,stateUpdate,data}) => { 
     
 
  const [value,setValue]=useState("")

  useEffect(() => {
    if(stateUpdate){
         
     setValue(data?.name?.trimStart())
      
    }else{
      setValue("")
    }
  }, []);


  const checkString = (string) => {
    let string1=string 
    return  inputHeader === "Frequency" ? /^[0-9\s]*$/.test(string1) :  /^[A-Za-z\s]*$/.test(string1) 
}


  const handleValue = (e) => {
    let value = e
    if(checkString(value)){
      setValue(value.trimStart())
    }
  }

  

   const handleAction = () => {

    let req_body = {
      id:data?.id,
      name:value,
      status:data?.status
    }
     
   if(value.trimStart() === ""){
    showToast(3,`Please enter ${inputHeader} `)
   }if(value != ""){
    onAction(value,req_body)
   }
  
   }

   const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    trigger,
} = useForm({
    defaultValues: {
        checkbox: false,
    },
});

return (
    <div class="flex flex-col w-full">
  <div class="self-center  ">

    {
      inputFiled ? 
       <>
      <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class="self-center my-2 mt-1.5">
      <CustomTextField
          required
          control={control}
          fullwidth
          name="name"
          size="small"
          header={inputHeader}
          value={value.trimStart()}
          placeholder={inputPlaceholder}
          borderRadius={"0.75em"}
          // rules={{
          //   required: APP_CONSTANTS?.PARTICULAR_NAME_REQUIRED,
          // }}
          InputProps={{ sx: { borderRadius: "0.75em" } }}
          onChange={(e) =>handleValue(e)}
      />
  </Grid> 
  
      <button onClick={closeModal}   class=" text-primaryColor border border-primaryColor w-28 h-10 rounded-lg mt-6" >
      Cancel
        </button>
        <button onClick={handleAction} class="bg-primaryColor w-28 h-10 rounded-lg shadow-md text-white mt-6 mx-4">
              {stateUpdate ? "Update" : "Create" }   
                </button>

  </>
  

  :
  <>
  <button onClick={onAction} class="text-primaryColor border border-primaryColor w-28 h-10 rounded-lg mt-6">
                Yes
                </button>
  
      <button onClick={closeModal}   class="bg-primaryColor w-28 h-10 rounded-lg shadow-md text-white mt-6 mx-4" >
            No
        </button>

        </>

    }
  
  </div>
  </div>
)

  }


export default SimpleModal