import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Switch from "@mui/material/Switch";

//component imports 
import editIcon from "../../assests/images/EditIcon.png";
import deleteIcon from "../../assests/images/DeleteIcon.png";



const MuiCustomTabel = (props) => {
  
  const AdjustHeigth = props?.fromDashboard ? 1.6 : 3

  const calHeight = (window.innerHeight - (Math.round(window.innerHeight / AdjustHeigth)))
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [occuranceValues, setOccuranceValues] = useState([]);
  const [windowHeight,setWindowHeight]=useState(calHeight)
  const [countPerPage,setCountPerPage]=useState()
  const [switchValue,setSwitchValue]=useState(
    new Array(props?.data.length).fill(true)
  );
  const [status,setStatus]=useState(1)

  //function to update height of the table according to the window height 
 const updateWindowHeight = () => {
  const calHeight = (window.innerHeight - (Math.round(window.innerHeight / AdjustHeigth)))
  setWindowHeight(calHeight)
 }

 const initialValues = props.data.map((data) => data.name);

 const values= props.data.map((data) => data?.status || data?.is_active === 1 ? true : false)

 let length = initialValues.length 

 const fromHome = props?.fromDashboard

 const AllProjects = props?.fromProjects


  useEffect(() => {
    window.addEventListener('resize', updateWindowHeight)
  }, [props?.data]);

  useEffect(() => {
    setOccuranceValues(initialValues);
    setSwitchValue(values)
  }, [length,calHeight]);

  
  useEffect(() => {
    if(props?.switchDecline === "true"){
      setSwitchValue(values)
    }else{
      setSwitchValue(values)
    }
   
  }, [props?.switchDecline]);


  useEffect(() => {
    if(props?.data?.length != 0){
      setPage(0)
    }
  }, [props?.data?.length]);

  //function to call no of records
  const handleCounter = () => {
      setCountPerPage(countPerPage+5)
  }


  const columns = props?.columnHeader;

  const label = { inputProps: { "aria-label": "Switch demo" } };

  //function to call page change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    handleCounter()
  };

  //function to change rows per page 
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
   }; 

//-----------------------------------color consts for status-------------------------------------//
  const classUnhealthy =
    "text-orange-400 font-bold border border-orange-400 w-20 h-6 text-center rounded-md";

  const classHealthy =
    "text-lime-500 font-bold border border-teal w-20 h-6 text-center rounded-md";

  const classDegraded =
    "text-rose-500 border font-bold border-rose-500 w-20 h-6 text-center rounded-md";


  //function to change date format
    const handleDateFormat = (dateValue) => {

      let date = new Date(dateValue);
      let day =  date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
      let month = date.getMonth()+1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
      let dateMDY = `${day}/${month}/${date.getFullYear()}`
      return dateMDY
    }
  

  const handleDelete = (data) => {
   
    props?.onDelete(data)
  }

  //function to hanlde switch 
  const handleSwitch = (e,position) => {
   
    const updatedCheckedState = switchValue.map((item, index) =>
      index === position ? !item : item
    );

    setSwitchValue(updatedCheckedState)

  }

  //function to handle master data edit
  const handleEditMasterData = (data) => {
        props?.onEditMasterfield(data,status)
  }
  
  // function to pass project edit data
  const handleDateEdit = (data) => {
    props?.onEditClient(data,status)
  }

  //function to split date
  const handleCreatedDate =(date,val) => {
     if(date != undefined ){
      let dataDate = date.split("-")
      if(val === "date"){
        return dataDate[0]
      }else{
        return dataDate[1]
      }
     
    
     }
   
  }

  // function to handle test status 
  const handleTestStatus = (status,testCount,) => {
         if( status === "NO"){
            return "Completed"
         }else{
          return "Yet to complete"
         }
  }
  
  

const clientName = props?.clientName


  const rows = props?.data?.map((data,index) => ({
            
    id: data.user_id || data?.id,
    Sno:index+1,
    email: data?.email,
    name : data?.name,
    test_Project_name : data?.Project_name,
    frequency_tested : (
      <div class="flex flex-row  ml-6">
        {data?.frequency}
        </div>
    ) ,
    tested_count:(
      <div class="flex flex-row  ml-5">
       { data?.tested_count === null ? 0  : data?.tested_count}
        </div>
    ) ,
    test_status : ( 
      
      handleTestStatus(data?.test_status,data?.tested_count,data?.frequency)
      
      ),
    client_Name :props?.path ? (
      
      <Link 
        to={{
          pathname: props?.path,
          state: { heading1:props?.name, heading2:data?.Project_name,info:data,clientid:props?.client_id,NameClient:clientName },
        }}
        class={"primaryColor underline"}
      >
        {data.organisation_name}
      </Link>
    ) : (
      <h1>{data.organisation_name}</h1>)
      ,
    occuranceStatus:(
      <div class="flex  w-8 h-8 justify-center items-center">
       <Switch
          {...label}
          value={switchValue}
          onClick={(e) => {
            handleSwitch(e?.target?.checked,index)
             e.target.checked ? setStatus(1) : setStatus(0)
             props.onStausChange( e.target.checked ? 1 : 0,data)
          }}
          checked={switchValue[index]}
          //defaultChecked
        />
      </div>
      ),
      dataStatus : (
        <div class="flex  w-8 h-8 justify-center items-center">
        <Switch
            {...label}
            value={switchValue}
          onClick={(e) => {
            handleSwitch(e,index)
            e.target.checked ? setStatus(1) : setStatus(0)
            props.onStausChange( e.target.checked ? 1 : 0,data)
          }}
          checked={switchValue[index]}
          // defaultChecked 
          />
          </div>
        ),
      frequencyStatus : (
        <div class="flex  w-8 h-8 justify-center items-center">
 <Switch
            {...label}
            value={switchValue}
            onClick={(e) => {
               handleSwitch(e.target.checked,index)
               e.target.checked ? setStatus(1) : setStatus(0)
               props.onStausChange( e.target.checked ? 1 : 0,data)
            }}
            checked={switchValue[index]}
           // defaultChecked
          />
        </div>
       
        ),
    Project_name:props?.path && AllProjects != "AllProjects" ? (
      
      <Link 
        to={{
          pathname: props?.path,
          state: { heading1:props?.name, heading2:data?.Project_name,info:data,clientid:props?.client_id},
        }}
        class={"primaryColor underline"}
      >
        {data.Project_name}
      </Link>
    ) : (
      <h1>{data.Project_name}</h1>
    ),
    Client_Project_name : 
    props?.path ? (
      
      <Link 
        to={{
          pathname: props?.path,
          state: { heading1:props?.name, heading2:data?.Project_name,info:data,clientid:props?.client_id },
        }}
        class={"primaryColor underline"}
      >
        {data.Project_name}
      </Link>
    ) : (
      <h1>{data.Project_name}</h1>
    ),
    Dashboard_Project_name :data.Project_name,
    // Dashboard_Project_name :  props?.path ? (
      
    //   <Link 
    //     to={{
    //       pathname: props?.path,
    //       state: { heading1:props?.name, heading2:data?.Project_name,info:data,clientid:props?.client_id,NameClient:clientName },
    //     }}
    //     class={"primaryColor underline"}
    //   >
    //     {data.Project_name}
    //   </Link>
    // ) : (
    //   <h1>{data.Project_name}</h1>
    // ),
    Total_Projects : (
      <div class="flex flex-row  "
      >
       <h1>{data?.total_projects}</h1> 
        </div>
    ),
    Total_Active_Projects:(
      <div class="flex flex-row ">
         <h1>{data.active_project_count}</h1>
      </div>
  
    ),
    start_date: 
      (<h1>{handleDateFormat(data?.Start_date)}  
      </h1>)
,
    end_date :
     ( <h1>{handleDateFormat(data?.End_date)}</h1>),
    is_activeProject :(
      <div class=" flex w-10 h-6 justify-center items-center ">
<Switch
        {...label}
        onClick={(e) => {
          handleSwitch(e.target.checked,index)
         
          e.target.checked ? setStatus(1) : setStatus(0)
          props.onStausChange( e.target.checked   ? 1 : 0,data)
          
        }}
       // checked={new Date(data?.End_date) < new Date()  ? false : switchValue[index]}
        checked = {switchValue[index]}
         disabled = {AllProjects == "AllProjects"? true : false}
        
      />
      </div>
    
    ),
    is_activeClient : (
      <div class="w-8 h-8">
         <Switch
          {...label}
          disabled={true}
          checked={ new Date(data?.End_date) < new Date()  ? false : switchValue[index]}
        />
      </div>
      
      ),
    OrganisationName : props?.path ? (
      <Link
        to={{
          pathname: props?.path,
          state: { heading1:props?.name, heading2:data?.organisation_name,info:data },
        }}
        class="primaryColor underline "
      >
        {data.organisation_name}
      </Link>
    ) : (
      <h1>{data.organisation_name}</h1>
    ),
    number : data?.mobile,
    description: props?.path ? (
      <Link
        to={{
          pathname: props?.path,
          state: { title: data.description, name: props?.name,info:data },
        }}
        class="primaryColor underline"
      >
        {data.description}
      </Link>
    ) : (
      <h1>{data.description}</h1>
    ),
    adStatus: (
      <Switch
        {...label}
       
        // onClick={(e) => {
        //   console.log(, "event", data.id);
        // }}
        defaultChecked
      />
    ),
    masterDataName: (
    <h1 class="flex border-2 border-zinc-300 rounded-lg w-40 h-10 justify-start items-center pl-6 cursor-default">
 {initialValues.find((val, index) => {
        return val === data.name;
      })}
    </h1>
    ),
    comapny_name: (
      <div class="flex flex-row ">
        {/* {console.log("props",data,index)} */}
        {" "}
         <img src={deleteIcon} onClick={() => handleDelete(data)} class="ml-2" />{" "}
      </div>
    ),
    transaction_id:(
      <div class="flex flex-row ml-2 ">
      
      <img src={editIcon} onClick={() => handleEditMasterData(data)}  /> 
     
    </div>
    ),
    projectEdit: <div class="flex flex-row"> <img src={editIcon} onClick={() => handleDateEdit(data)} class="ml-2" />  </div>,
    particularSelected :`${data?.tested_particulars}/${props?.particularCount}`,
    title: data.title,
    particularStatus: (
      <button
        class={
          ( data.overallStatus === 11  && `${classDegraded}` ) 
          || (data.overallStatus === 9 &&  `${classHealthy}`)
          || ( data.overallStatus === 10 && `${classUnhealthy}` )
          || (data.overallStatus != 9  && data.overallStatus != 10 && data.overallStatus != 11 && `${classUnhealthy}`)
        }
      >
      
        {( data.overallStatus === 9  && `Healthy` ) ||( data.overallStatus === 10  && `Unhealthy`) || (data.overallStatus === 11 && "Degraded") || (data.overallStatus != 26 && data.overallStatus != 27 && data.overallStatus != 28 && "Others") }
      </button>
    ),
    cilentInfoStatus : (
        <div  class={
          ( data?.data?.status === "Degraded"  && `${classDegraded}` ) 
          || (data.data?.status === "Healthy" &&  `${classHealthy}`)
          || ( data.data?.status === "Unhealthy" && `${classUnhealthy}` )
        }  >
          <h1>{data?.data?.status}</h1>
        </div>
    ),
    particular: data?.particular,
    particularName : data?.data?.name,
    created_date: (
      props?.path ?
        <Link to={{
          pathname: props?.path,
          state: {
            title: data.description,
            name: props?.name,
            info:data,
            projectId:props?.projectId,
            date : data?.date
          }
        }}
          class="primaryColor underline"
        >
        
          {handleCreatedDate(data?.date,"date")}
        </Link>
        :
        <h1>{handleCreatedDate(data?.date)}</h1>
    ),
    created_time:(
      
    <h1>{handleCreatedDate(data?.date,"time")}</h1>
    )

  }));

  let count = props?.data.length ;

  const total_records = count <= 0 || count === countPerPage ? "00" : count;



  return (
    <div
      // sx={{ width: "100%", overflow: "hidden" }}
      class="flex flex-col  "
    >
      <div>
        <TableContainer
         sx={{ maxHeight:props?.height || windowHeight,minHeight:"100px",  maxWidth:"100%",  overflowY: "auto",overflowX:"auto"}}
          //sx={{height:`472px`,overflowY: "auto"}}
        >
          <Table stickyHeader size="small" aria-label="a dense table">
            <TableHead >
              <TableRow

              >
                {columns.map((column) => (
                  <TableCell
                    key={column.dataKey}
                    align={column.align}
                   // align="center"
                    // class="bg-tableHeaderColor"
                    style={{
                      position:"sticky",
                      left: 0,
                      width:column?.width,
                      height: "40px",
                      backgroundColor:"#C1E0E4",
                      fontWeight: "bold",
                      
                    }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {
              props?.data?.length !== 0 ? 
              <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                      {columns.map((column) => {
                        const value = row[column.dataKey];
                        
                        return (
              
                          <TableCell
                            key={column.dataKey}
                            align={column.align}
                            
                            height={20}
                          >
                            {column.render ? column.render(value) : value}
                            
                          </TableCell>
                                       
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody> :
             <TableRow><TableCell colSpan={6} align="center">No data available in table </TableCell></TableRow>
            }
          
          </Table>
        </TableContainer>
        {
          props?.bottomPagination === false ? 
          null : 
          <div class="flex flex-row justify-between">
          <div class="flex w-15 h-12 justify-center items-center">
            <h1>Total Records :{total_records}</h1>
          </div>

          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </div>
        }
    
      </div>
    </div>
  );
};

export default MuiCustomTabel;

