// Forget password constants
const FORGET_PASSWORD = "Forgot Password?";
const FORGET_PASSWORD_DESC = "No worries, reset password link will be shared to your email id";
const ENETR_EMAIL_ID = "Enter Email ID";
const SEND_LINK = " Send Link";
const DIDNOT_RECEIVE_MAIL = "Didn’t receive a mail?";
const CLICK_TO_RESEND = "Click to resend";
const BACK_TO_LOGIN = "Back to log in";

//setPassword constants 
const SET_PASSWORD_HEADER = "New Password"
const SET_PASSWORD_PLACEHOLDER = "please enter password"
const SET_PASSWORD_REQUIRED = "Please enter new password"
const SET_NEW_PASSWORD_REQUIRED_MINVALUE = 8;
const SET_MINVALUE_MESSGAGE = "Please enter new password having atleast 8 characters"
const SET_CONFIRM_PASSWORD_HEADER = "Confirm New Password"
const SET_CONFIRM_PASSWORD_VALIDATE = "Please enter password same as new password"

//changePassword contants
const CHANGE_OLDPASSWORD_HEADER = "Old Password"
const Old_PASSWORD_PlACEHOLDER = "Old Password"
const SET_OLDPASSWORD_REQUIRED = "Please enter Old Password"

//create client 
const CLIENTNAME_MAXLENGTH = 50
const CLIENTNAME_MINLENGTH = 3
const NUMBER_MAXLENGTH = 10

//password secret key

const PASSWORD_SECRET_KEY = "ENCRYPTION_SECRET_KEY = StrId@AhM_@aHM*2023" 



// SignIn screen Constants
const ENTER_EMAILID= "Email ID";
const PLACEHOLDER_EMAIL= "Email ID*"
const REQUIRED_SIGNIN_MESSAGE= "Please enter email ID"
const REQUIRED_PASSWORD_MESSAGE="Please enter password"
const SIGNIN_PASSWORD = "Password"
const SIGNIN_PASSWORD_PLACEHOLDER= "Password*"
const SIGNIN_REMEMBER_ME= "Remember Me"
const SET_NEW_PASSWORD ="Set New Password "
const NOT_VALID_EMAIL ="Please enter valid email ID"
const PASSWORD_VALIDATION_MESSAGE = "Your password should have minimum 8 characters and must contain minimum one digit, one special character, one uppercase and one lowercase letter"
const MINLENGTH_PASSWORD =8
const MAXLENGTH_PASSWORD =15
const MINLENGTH_PASSWORD_MESSAGE = "minimum length should be 8 characters"
const FORGOT_PASSWORD ="Forgot Password?"
// RestPassword screen constants
const TOKENEXPIERED = "Token is expired please click here on"


export const AuthConstants = {
    FORGOT_PASSWORD,
    MINLENGTH_PASSWORD_MESSAGE,
    MINLENGTH_PASSWORD,
    MAXLENGTH_PASSWORD,
    PASSWORD_VALIDATION_MESSAGE,
    NOT_VALID_EMAIL,
    FORGET_PASSWORD,
    TOKENEXPIERED,
    FORGET_PASSWORD_DESC,
    ENETR_EMAIL_ID,
    SEND_LINK,
    DIDNOT_RECEIVE_MAIL,
    CLICK_TO_RESEND,
    BACK_TO_LOGIN,
    ENTER_EMAILID,
    PLACEHOLDER_EMAIL,
    REQUIRED_SIGNIN_MESSAGE,
    SIGNIN_PASSWORD,
    SIGNIN_PASSWORD_PLACEHOLDER,
    SIGNIN_REMEMBER_ME,
    SET_NEW_PASSWORD,
    SET_PASSWORD_HEADER,
    SET_PASSWORD_PLACEHOLDER,
    SET_PASSWORD_REQUIRED,
    SET_NEW_PASSWORD_REQUIRED_MINVALUE,
    SET_MINVALUE_MESSGAGE,
    SET_CONFIRM_PASSWORD_HEADER,
    SET_CONFIRM_PASSWORD_VALIDATE,
    PASSWORD_SECRET_KEY,
    CHANGE_OLDPASSWORD_HEADER,
    Old_PASSWORD_PlACEHOLDER,
    SET_OLDPASSWORD_REQUIRED,
    CLIENTNAME_MAXLENGTH,
    CLIENTNAME_MINLENGTH,
    NUMBER_MAXLENGTH,
    REQUIRED_PASSWORD_MESSAGE
}