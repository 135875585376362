




//header colomn for the clients list in admin
export const columnsClient = [
  {
    width: "3%",
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: "8%",
    label: "Client Name",
    dataKey: "OrganisationName"
  },
  {
    width: "5%",
    label: "All Projects",
    dataKey: "Total_Projects"
  },
  {
    width: "5%",
    label: "Active Projects",
    dataKey: "Total_Active_Projects"
  },
  {
    width: "10%",
    label: "Email ID",
    dataKey: "email",
  },
  {
    width: "8%",
    label: "Mobile Number",
    dataKey: "number"
  },
  {
    width: "8%",
    label: "Contact Name",
    dataKey: "name",

  },
  {
    width: "5%",
    label: "Action",
    dataKey: "comapny_name",
    render: (value) => <button>{value}</button>
  }
];

//header colum for status in master data 
export const columnMetaDataStatus = [
  {
    width: 100,
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: 300,
    label: "Values",
    dataKey: "masterDataName",
    render: (value) => <button>{value}</button>
  },
  {
    width: 150,
    label: "Status",
    dataKey: "dataStatus",
    render: (value) => <button>{value}</button>
  },

  {
    width: 150,
    label: "Action",
    dataKey: "transaction_id",
    render: (value) => <button>{value}</button>
  }
]


//header column for the occurance in the master data 
export const columnsMetaData = [
  {
    width: 100,
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: 300,
    label: "Occurrence",
    dataKey: "masterDataName",
    render: (value) => <button>{value}</button>
  },
  {
    width: 150,
    label: "Status",
    dataKey: "occuranceStatus",
    render: (value) => <button>{value}</button>
  },

  {
    width: 150,
    label: "Action",
    dataKey: "transaction_id",
    render: (value) => <button>{value}</button>
  }
];

//header column for the frequency in the masterdata
export const columnsMetaDataFrequency = [
  {
    width: 100,
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: 300,
    label: "Frequency",
    dataKey: "masterDataName",
    numeric: true
  },
  {
    width: 150,
    label: "Status",
    dataKey: "frequencyStatus",
    render: (value) => <button>{value}</button>
  },

  {
    width: 150,
    label: "Action",
    dataKey: "transaction_id",
    render: (value) => <button>{value}</button>
  }
];

//header column for the projects in admin
export const columnsProject1 = [
  {
    width: 100,
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: 200,
    label: "Project Name",
    dataKey: "Project_name"
  },
  {
    width: 150,
    label: "Start Date",
    dataKey: "start_date",
  },
  {
    width: 150,
    label: "End Date",
    dataKey: "end_date"
  },
  {
    width: 150,
    label: "Status",
    dataKey: "is_activeProject",
    render: (value) => <button>{value}</button>
  },
  {
    width: 150,
    label: "Action",
    dataKey: "projectEdit",
    render: (value) => <button>{value}</button>
  }
];

//header column for all projecs 
export const AllProject = [
  {
    width: 100,
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },{
    width : 200,
    label : "Client Name",
    dataKey :"client_Name"
  },
  {
    width: 200,
    label: "Project Name",
    dataKey: "Project_name"
  },
  {
    width: 150,
    label: "Start Date",
    dataKey: "start_date",
  },
  {
    width: 150,
    label: "End Date",
    dataKey: "end_date"
  },
  {
    width: 150,
    label: "Status",
    dataKey: "is_activeProject",
    render: (value) => <button>{value}</button>
  },
  {
    width: 150,
    label: "Action",
    dataKey: "projectEdit",
    render: (value) => <button>{value}</button>
  }
];

//header column for the projects in client 
export const clientProject1 = [
  {
    width: 100,
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: 200,
    label: "Project Name",
    dataKey: "Client_Project_name"
  },
  {
    width: 150,
    label: "Start Date",
    dataKey: "start_date",
  },
  {
    width: 150,
    label: "End Date",
    dataKey: "end_date"
  },
  {
    width: 150,
    label: "Status",
    dataKey: "is_activeClient",
    render: (value) => <button>{value}</button>
  },
];



export const particularInfoHeader = [
  {
    width: 100,
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: 150,
    label: "Particulars/Items",
    dataKey: "particularName",

  },
  {
    width: 150,
    label: "Status",
    dataKey: "cilentInfoStatus",
    render: (value) => <button>{value}</button>
  }
]

export const columnsProject = [
  {
    width: 100,
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: 250,
    label: "Date",
    dataKey: "created_date"
  },
  {
    width: 250,
    label: " Time",
    dataKey: "created_time"
  },

  {
    width: 150,
    label: "Particulars",
    dataKey: "particularSelected",

  },
  {
    width: 150,
    label: "Status",
    dataKey: "particularStatus",
    render: (value) => <button>{value}</button>
  }
];



export const columnsDashBoard = [
  {
    width: "2%",
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width :"10%",
    label : "Client Name",
    dataKey :"client_Name"
   },
  {
    width: "15%",
    label: "Project Name",
    dataKey: "Dashboard_Project_name"
  },
 
  {
    width: "15%",
    label: "Start Date",
    dataKey: "start_date",
  },
  {
    width: "15%",
    label: "End Date",
    dataKey: "end_date"
  },

];


export const clientDashBoard = [
  {
    width: "2%",
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  {
    width: "15%",
    label: "Project Name",
    dataKey: "Dashboard_Project_name"
  },
 
  {
    width: "15%",
    label: "Start Date",
    dataKey: "start_date",
  },
  {
    width: "15%",
    label: "End Date",
    dataKey: "end_date"
  },

];



export const columnsTested = [
  {
    width: "2%",
    label: "S.No",
    dataKey: "Sno",
    numeric: true
  },
  { width :"10%",
  label : "Client Name",
  dataKey :"client_Name"
  },
  {
    width :"10%",
    label : "Project Name ",
    dataKey :"test_Project_name"
   },
  {
    width: "15%",
    label: "Total Test Count",
    dataKey: "frequency_tested"
  },
 
  {
    width: "15%",
    label: "Tested Count",
    dataKey: "tested_count",
  },
  {
    width: "15%",
    label: "Test Status",
    dataKey: "test_status"
  },

];






export const sample = [
  {
    address: "Stridefuture,hsr_layout",
    email: "stridefuture123@gmail.com",
    mobile: "7281234593",
    name: "StrideFuture",
  },
  {
    address: "Stridefuture,hsr_layout",
    email: "stridefuture123@gmail.com",
    mobile: "7281234593",
    name: "StrideFuture",
  },
  {
    address: "Stridefuture,hsr_layout",
    email: "stridefuture123@gmail.com",
    mobile: "7281234593",
    name: "StrideFuture",
  }
];