import React from "react";
import { Button, Grid } from "@mui/material";
import { useHistory } from "react-router-dom";

export const PageNotFound = () => {
  const history = useHistory();

  const moveToRoot = () => {
    history?.goBack();
  };

  return (
    <div class="flex-1 flex-row h-full w-full justify-center items-center">
        <div class="justify-center items-center">
        <Grid
        className="page-not-found"
        style={{ textAlign: "center" }}
        xs={10}
        sm={10}
        md={4}
        lg={4}
        xl={4}
      >
        <h1>Oops Page Not Found ! </h1>

        <Button
          onClick={moveToRoot}
          variant="contained"
          className="select-btn-active"
        >
          Go Back
        </Button>
      </Grid>
        </div>
     
    </div>
  );
};
