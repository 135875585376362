import { apiRequest } from "../network/Api";

export const SigninApi = (path, data) => {
    return apiRequest({
      method: "POST",
      url: path,
      data,
    });
  };




  //Api to call forgotPassword api for trigrring mail
export const forgotPasswordApi = (path, data) => {
    return apiRequest({
      method: "POST",
      url: path,
      data,
    });
  };


  //Api to call token which is active or not
export const activateOrResetPassword = (path, data) => {
    return apiRequest({
      method: "POST",
      url: path,
      data,
    });
  };

  //Api to call token which is active or not
export const change_Password = (path, data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
};


//Api to change and update password
export const createOrResetPassword = (data, auth) => {
    return apiRequest(
      {
        method: "POST",
        url: "auth/reset_password",
        data: data,
      },
      auth
    );
  };


//Api to create client 
export const createClient = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}


//Api to create client 
export const updateClient = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}

//Api to create client 
export const deleteClient = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}

//Api to create Project
export const createProject = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}


//Api to update project
export const updateProject = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}

//api data create particular fileds 

export const createOccurance = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}


//api data create particular 

export const createParticulars = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}


//api to get particularForm details 
export const getParticularsForm = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}

//api to submit particularform

export const submitParticularForm = (path,data) => {
  return apiRequest({
    method : "POST",
    url: path,
    data,
  });
}

//api to get particular tested details 
export const getTestedResults = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}

//api to get support details 
export const getSupportResults = (path,data) => {
  return apiRequest({
    method: "POST",
    url: path,
    data,
  });
}

  