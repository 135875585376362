import { APP_CONSTANTS } from "./AppConstants"

const headerByPath = {
    auth: APP_CONSTANTS?.SIGN_IN,
    // "": APP_CONSTANTS?.SIGN_IN,
    'forgot-password': APP_CONSTANTS?.FORGOT_PASS,
    'set-password': APP_CONSTANTS?.SET_NEW_PASS,
    'change-password': APP_CONSTANTS?.CHANGE_PASS
  }

const headingDes = {
    'forgot-password': APP_CONSTANTS?.FORGOT_DES
  }  

export const DropDownCon = {
    headerByPath,
    headingDes
}

export  const ITEM_HEIGHT = 48;
export const ITEM_PADDING_TOP = 8;