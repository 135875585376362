import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { getData } from '../utils/AsyncStorage'
import { isLoggedIn } from '../utils/Utils'

const { role_id } = JSON.parse(getData("userInfo") || 0);
const role_redirect =
  role_id === 1
    ? "/panel/dashboard"
    : "/client/clientDashboard"

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn() && restricted ? (
          <Redirect to={role_redirect || "/"}/>
        ) : (
          <Component {...props} />
        )
      }
    />
  )
}

export default PublicRoute