
//component imports
import  React,{ useState,useEffect} from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

//package imports 
import MuiCustomTabel from "../../../utils/reusableComponents/MuiCustomTabel";
import {
  columnsMetaData,
  columnsMetaDataFrequency,
  sample,
  frequencyData
} from "../../../utils/constants/TableData";
import CustomButton from "../../../utils/reusableComponents/CustomButton";
import { CustomModal } from "../../../utils/reusableComponents/CustomModal";
import SimpleModal from "../../../utils/reusableComponents/SimpleModal";
import { getOccuranceList } from "../../../services/apiServices/GetServices";
import { createOccurance } from "../../../services/apiServices/PostServices";
import { showToast } from "../../../utils/constants/ToastConstants";


const Frequency = (props) => {
  const history = useHistory();
  const [showModal, setshowModal] = useState(false);
  const [showStatus,setShowStatus] = useState(false)
  const [frequencyData,setFrequencyData]=useState([])
  const [update,setUpdate]=useState(false)
  const [predefinedData,setPredefinedData] = useState([])
  const [title,setTitle] = useState("Create Frequency")
  const [statusValue,setStatusValue]=useState(1)
  const [updateData,setUpdateData]=useState([])
  const [declineStatus,setDeclineStatus]=useState("")
  

  useEffect(() => {
    callFrequencyList()

  }, []);

  const heading = {
    heading1: "Clients List",
  };

   //function to call list api
   const callFrequencyList =  async () => {
    const res  = await getOccuranceList("master/frequency")
    if(res?.success){
      setFrequencyData(res?.data)
    }else{
     console.log("res",res?.message)
    }
 }

   //function to open modal
   const handleCreateFrequency = () => {
    setshowModal(true)
    setUpdate(false)
    setTitle("Create Frequency")
}

//function to close modal
const modalClose = ()  => {
  setshowModal(false)
}


//function to close modal after api success.
const handleModalFalse = () => {
  callFrequencyList()
  setshowModal(false)
 }

//function to handle api call
const handleApi = async(value,updateBody) => {

  const body = {
    name : value
  }
const update_reqBody = {
  name : updateBody?.name,
  status : statusValue
}

let req_body = update? update_reqBody : body

let endPoint = update ?  `master/frequency/${updateBody?.id}` : "master/frequency"

 const res = await createOccurance(endPoint,req_body) 

  if(res?.success){
    showToast(1, res?.message);
    handleModalFalse()
  }else{
    showToast(3, res?.message);
  
  } 
}


//updated status 

const updateStatus = async() => {
  const  req_body = {
    name : updateData?.name,
    status : statusValue
  }

  const res = await createOccurance(`master/frequency/${updateData?.id}`,req_body) 
  if(res?.success){
    showToast(1, "Status Updated");
    callFrequencyList()
    setShowStatus(false)
  }else{
    showToast(3, res?.message);
  } 
}


 //function call to edit 
 const handleEdit = (data,status) => {
 
  setPredefinedData(data)
  setTitle("Edit Frequency")
  setUpdate(true)
  setshowModal(true)
 }

//function to create frequency
const onCreateFrequency = (value,body) => {
  handleApi(value,body)
}

//function to call onDelete close modal 
const onStatusClose = () => {
  setDeclineStatus("true")
  if(statusValue === 1){
    setStatusValue(0)
  }else{
    setStatusValue(1)
  }
  setShowStatus(false)
}

 
//function to call onDelete Occurence
const handleStatus = (status,data) => {

  setUpdateData(data)
  setStatusValue(status)
  setShowStatus(true)
  setDeclineStatus("")
}

  return (
    <>
      <div class="flex justify-between items-center my-1">
        <h1
        >
          Frequency
        </h1>
        <div class="flex gap-4 ">
          <CustomButton
            children={"Create Frequency"}
            classVal="bg-teal-800 text-white w-48 h-10 hover:bg-teal-700 rounded-lg font-normal font-sans"
            onClick={() => handleCreateFrequency()}
          />
        </div>
      </div>

      <div class="w-full ">

      <CustomModal>
      <SimpleModal 
       title={title}
       width={"w-1/4"}
       height={"h-5/5"}
       shouldShow={showModal}
       closeModal={modalClose} 
       onAction={onCreateFrequency}
       inputFiled={true}
       inputHeader={"Frequency"}
       inputPlaceholder={"Frequency"}
       stateUpdate={update}
       data={predefinedData}
      />
     </CustomModal>

     <CustomModal>
      <SimpleModal 
       title={"Are you sure you want to update status?"}
       shouldShow={showStatus}
       closeModal={onStatusClose} 
       onAction={updateStatus}
      />
     </CustomModal>
       
        <MuiCustomTabel
          name={heading}
          data={frequencyData}
          columnHeader={columnsMetaDataFrequency}
          onEditMasterfield = {handleEdit}
          height = {props}
          onStausChange={handleStatus}
          switchDecline= {declineStatus}
          bottomPagination = {false}
        />
      </div>
    </>
  );
};

export default Frequency;
