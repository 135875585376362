import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useHistory } from "react-router-dom";
 import { Link } from "react-router-dom/cjs/react-router-dom";
 import { getData } from '../AsyncStorage';
//import Link from "@mui/material/Link";


const BreadcrumbsHeader = (props) => {
  const history = useHistory();

  const userData = getData("userInfo"); 
  const parsedUserData = JSON.parse(userData);
  
  const userType = parsedUserData?.role_id === 1 ? "admin" : "client"

  const handleNavigation = () => {
    history.replace('panel/clients/clientProject')
  }

  


  

  return (
    parsedUserData?.role_id === 1 ?
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link to ="/panel/clients" underline="none" >
        <h1 class=" text-black  font-bold text-1xl"  >{props?.heading1}  </h1>
        </Link>
        {
          props?.heading2 ?
          <Link to={{
            pathname: "/panel/clients/clientProject",
            state: { heading1:props?.heading1, heading2:props?.heading2,heading3:props?.heading3,info:props?.client_id,client_info:props?.clientDetails,},
          }}underline="none" >
            <h1  class="text-black  font-bold text-1xl" >{props?.heading2}</h1>
            </Link>
            :
            null
        }
        {
          props?.heading3 ?
          
            <h1 class="text-black  font-bold text-1xl" >{props?.heading3}</h1>
            :
            null
        }
        {/* {breadcrumbs} */}
      </Breadcrumbs>
    </div> :
    <div>
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        <Link to ="/client/projects" underline="none" >
        <h1 class=" text-black  font-bold text-1xl"  >{props?.heading1}  </h1>
        </Link>
        {
          props?.heading2 ?
          <Link to={{
            pathname: "/client/projects/particularList",
            state: { heading1:{heading1 : props?.heading1}, heading2:props?.heading2,info:props?.info,projectId:props?.projectId },
          }}underline="none" >
            <h1  class="text-black  font-bold text-1xl" >{props?.heading2}</h1>
            </Link>
            :
            null
        }
        {
          props?.heading3 ?
          
            <h1 class="text-black font-bold text-sm" >{props?.heading3}</h1>
            :
            null
        }
        {/* {breadcrumbs} */}
      </Breadcrumbs>
    </div>
    

  )
}

export default BreadcrumbsHeader