//Package imports
import React from "react";
import { Typography, TextField } from "@mui/material";
import { useController } from "react-hook-form";

const CustomTextField = ({
  control,
  name,
  label,
  placeholder,
  size,
  value,
  rules,
  onChange,
  rows,
  type,
  multiline,
  header,
  required,
  disabled,
  inputProps,
  backgroundColor,
  borderRadius,
  maxWidth,
  event,
  InputProps,
  onKeyPress,
  onKeyDown,
  marginBottom,
  marginTop,
  maxHeight,
  isSignPage,
  classStyle,
  width,
}) => {

  // entire error handling is happening through use controllers
  const { field, fieldState, formState } = useController({
    control,
    name,
    rules,
    defaultValue: value || "",
  });

  const { error } = fieldState || rules;

  // handle change data is used for on Change functionality
  const handleChangeData = (e) => {
    field.onChange(e);
    if (onChange) {
      onChange(e.target.value);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent the default Enter key behavior (form submission)
    }
  };


  return (
    <>
    <div class="flex flex-col">
      <div class="flex text-gray-400 no-u.nderline justify-between ..">
        <div class="flex">
          <label class="text-gray-400 mb-1 flex  text-dark-grey no-u.nderline font-sans text-sm font-normal leading-none..">
            {header}
          </label>
          {required && (
            <span class="flex text-m no-u.nderline leading-none text-red">
              *
            </span>
          )}
        </div>
      </div>
      <TextField
        {...field}
        type={type}
        label={label}
        variant="outlined"
        fullWidth
        value={value}
        multiline={multiline}
        disabled={disabled}
        size={size}
        onKeyDown={ isSignPage ? null : handleKeyPress}
        style={{
          width: maxWidth,
          height: maxHeight,
          // boxShadow:
          //   "0 3px 1px 0 rgb(0 0 0 / 0.1)",
          borderRadius: borderRadius,
          backgroundColor:"#ffffff",
          // borderBottom:"2px groove ",
          // borderColor:"#D9D9D9"
        }}
        rows={rows}
        placeholder={placeholder}
        error={!!error}
        className={classStyle}
        onChange={handleChangeData}
        inputProps={inputProps}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        maxWidth={maxWidth}
        InputProps={{
          ...InputProps,
           
        }}
      />
     
      <errors class="font-Inter text-left text-sm	self-start text-red-400 text-red capitalize">
            <Typography variant="p5" mb={2}>
              {error?.message}
            </Typography> 
        </errors>
       
        </div>
    </>
  );
};

export default CustomTextField;
