import axios from 'axios'
import { getData, removeData, SessionGetData } from '../../utils/AsyncStorage'
import history from './CustomHistory'
import { TextInputUtils } from '../../utils/TextInputUtils'


const BASE_URL = process.env.REACT_APP_URL
let activeRequests = 0
 
const appClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
})

const onSuccess = (res) => {
  hideLoader()
  const { data } = res
  if (data?.success) {
    removeToken(data?.statusCode);
  }
  return res?.data || res
}

const onError = (err) => {
  hideLoader()
  console.log(err)
  const { status } = err?.response || {}
  const { viewModel, message, statusCode } = err?.response?.data || {}
  removeToken(status)
  if ((status || statusCode) == (405 || 500)) {
    return history.replace('/500')
  }
return err?.response?.data
}

export const hideLoader = () => {
  activeRequests--
  if (!activeRequests) {
    TextInputUtils?.hideLoadingIndicator()
  }
}

export const showLoader = (options) => {
  activeRequests++
  TextInputUtils?.showLoadingIndicator()
}

const removeToken = (statusCode) => {
  if (statusCode === 401 || statusCode === 403) {
    removeData('accessToken')
    sessionStorage.clear('accessToken');
   window.location.href = '/auth'
  }
}

export const apiRequest = async (options, auth) => {
  showLoader(options)
  // const token = await sessionStorage?.accessToken
  const token = await getData('accessToken')
  if (options?.url?.includes('/reset_password')) {
    appClient.defaults.headers['Authorization'] = `Bearer ${auth}`
  } else if (token) {
    appClient.defaults.headers['Authorization'] = `Bearer ${token}`
  }
  return appClient(options).then(onSuccess).catch(onError)
}

export const apiRequestWithoutToken = async (options) => {
  showLoader()
  return appClient(options).then(onSuccess).catch(onError)
}
