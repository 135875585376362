import { getData } from "../../utils/AsyncStorage";
import { apiRequest } from "../network/Api";


//client list api
export const getClientsList = (path) => {
    return apiRequest({
      method: "GET",
      url: path,
    });
  };



//project list api 
export const getProjectsList = (path) => {
  return apiRequest({
    method: "GET",
    url: path,
  });
}

//GET Api for Occurance 
export const getOccuranceList = (path) => {
  return apiRequest({
    method: "GET",
    url: path,
  });
}

//GET Api for particularsList
export const getParticularList = (path) => {
  return apiRequest({
    method: "GET",
    url: path,
  });
}

//Get Api for dashboard 
export const getDashboardDetails = (path) => {
  return apiRequest({
    method: "GET",
    url: path,
  });
}

//Get Api for dashboard 
export const getClientDetails = (path) => {
  return apiRequest({
    method: "GET",
    url: path,
  });
}

