//Package import
import React, { useState,useEffect } from "react";
import { useForm } from "react-hook-form";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useHistory  } from "react-router-dom";
import { Checkbox, Grid, IconButton, Paper, Link } from "@mui/material";
import CryptoJS from "crypto-js";

//Component imports
import { removeData, storeData } from "../../utils/AsyncStorage";
import { SigninApi } from "../../services/apiServices/PostServices";
import CustomTextField from "../../utils/reusableComponents/CustomTextField";
import CustomButton from "../../utils/reusableComponents/CustomButton";
import { AuthConstants } from "../../utils/constants/AuthConstnats";
import { ValidationConstants } from "../../utils/constants/ValidationConstants";
import PasswordEncryption from "../../utils/constants/PasswordEncryption";
import PasswordDecryption from "../../utils/constants/PasswordDecryption";
import { showToast } from "../../utils/constants/ToastConstants";
import { getData } from "../../utils/AsyncStorage";
import { APP_CONSTANTS } from "../../utils/constants/AppConstants";



const SignIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [checked, setChecked] = useState(false);
  const [validePassword,setValidePassword]=useState(true);
  const email_user = JSON.parse(getData("userData"));



  const history = useHistory();
  var CryptoJS = require("crypto-js");


  const {
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      checkbox: false,
    },
  });

  useEffect(() => {
    
    const userData = getData("userData"); 
    const parsedUserData = JSON.parse(userData);

    
    if (parsedUserData !== null) {
      let decryptedPassword = PasswordDecryption(parsedUserData?.user_password)
      setChecked(true);
      setEmail(parsedUserData?.user_email);
      setPassword(decryptedPassword)
      setValue("email",parsedUserData?.user_email)
    }

  
    if (email_user) {   
      const {user_email} = email_user;
      if (email !== "") {
        setEmail(user_email)
        setValue("email", user_email);
        setEmail(user_email);
      }
    }

  }, []);


  const handleEmail  = (e) => {
     setEmail(e)
  }


  let userInfo = {
    email: email,
    password: password,
  };

   //Function to handle routing
   const handleRoute = (role_id) => {
    role_id === 1
      ? history.push("/panel/dashboard")
      : history.push("/client/clientDashboard");
  };


//handle save details  
const handleRemberme =  async() => {
  const encodedPassword = `WEB|${password}|aHm$*2@2309_aHm@`;
  if (checked) {
    const password = PasswordEncryption(encodedPassword)
    const userDetails = {
      user_email: email,
      user_password: password,
      rememberMe: true,
    };
    await storeData("userData", JSON.stringify(userDetails));
  } else {
    await removeData("userData");
  }
}

  const buttonEnable = email !== "" && password !== "";
  //storing data in local storage
  const validateEmailLogin = async (res) => {
    if (res?.message === "Login successful") {
      let objs = JSON.stringify(userInfo);
      if (checked) {
        await storeData("userData", objs);
      } else {
        await removeData("userData");
      }
      handleRoute(res?.data?.role_id);
    } else {
      console.log("message",res?.message);
    }
  };

  // Login with api call
  const handleLoginIn = async (data) => {

    const encodedPassword = `WEB|${password}|aHm$*2@2309_aHm@`;
    try {

      const password = PasswordEncryption(encodedPassword)
      
      // const data = CryptoJS.AES.encrypt(
      //   JSON.stringify(encodedPassword),
      //   secretPass
      // ).toString();
      const reqbody = {
        email: email,
        password: password,
        rememberMe: checked,
      };
      const res = await SigninApi("auth/login", reqbody);
      if (res?.success) {
        let objs = JSON.stringify(res?.data);
        await storeData("userInfo", objs);
        await storeData("accessToken", res?.token);
        handleRemberme()
        await storeData("rememberMe", checked);
        res?.data?.role_id == 1 ? 
        history.push("/panel/dashboard")
        :
        history.push ("/client/clientDashboard")
        await storeData("client_id", res?.data?.user_id );
        validateEmailLogin(res?.message);
        
      } else {
        setValidePassword(false)
        showToast(3, res?.message || res?.errors[0]?.msg);
      }
    } catch (error) {
      console.log("error");
    }
  };

  // show and hide eye icon
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // checkbox checked then storing values in local storage
  const handleChange = async (event) => {
    
    setChecked(event.target.checked);
    // if (event.target.checked) {
    //   const password = PasswordEncryption(encodedPassword)
    //   const userDetails = {
    //     user_email: email,
    //     user_password: password,
    //     rememberMe: true,
    //   };
    //   await storeData("userData", JSON.stringify(userDetails));
    // } else {
    //   await removeData("userData");
    // }
  };

  //forget password navigation
  const handleChangeNavigation = () => {
    history.push("auth/forgot-password");
  };
  return (
    <form onSubmit={handleSubmit(handleLoginIn)} autoComplete="off">
      <Paper elevation={0} className={`paper_margin`} class="w-2/4 m-auto">
        <Grid
          container
          direction={"column"}
          justifyContent={"center"}
          display={"block"}
        >
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            justifyContent={"center"}
          >
            <CustomTextField
              control={control}
              header={AuthConstants.ENTER_EMAILID}
              required
              name="email"
              size="small"
              value={email}
              placeholder={AuthConstants.PLACEHOLDER_EMAIL}
              rules={{
                required: AuthConstants.REQUIRED_SIGNIN_MESSAGE,
                pattern: {
                  value: ValidationConstants.TypeConstants.EmailType,
                  message: AuthConstants.NOT_VALID_EMAIL,
                },
              }}
              borderRadius={"0.75em"}
              InputProps={{ sx: { borderRadius: "0.75em" } }}
              onChange={(e) => {
                handleEmail(e);
              }}
            />
          </Grid>

          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            justifyContent={"center"}
            class="mt-4"
          >
            <CustomTextField
              control={control}
              header={AuthConstants.SIGNIN_PASSWORD}
              required
              name="current_password"
              placeholder={AuthConstants.SIGNIN_PASSWORD_PLACEHOLDER}
              isSignPage={true}
              size="small"
              type={showPassword ? "text" : "password"}
              value={password}
              rules={{
                required: password == "" && AuthConstants.REQUIRED_PASSWORD_MESSAGE,
                
              }}
              borderRadius={"0.75em"}
              inputProps={{ maxLength: AuthConstants?.MAXLENGTH_PASSWORD }}
              InputProps={{
                sx: { borderRadius: "0.75em" },
                endAdornment: (
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? (
                      <VisibilityOutlinedIcon />
                    ) : (
                      <VisibilityOffOutlinedIcon />
                    )}
                  </IconButton>
                ),
              }}
              onChange={(e) => {
                setPassword(e);
              }}
            />


            <Grid
              container
              xs={12}
              direction="row"
              justifyContent="space-between"
              // mt={2}
              alignItems={"baseline"}
            >
              <Grid item>
                <Grid container flexDirection="row" display={"contents"}>
                  <Checkbox
                    checked={checked}
                    onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                  <span class="text-dark-grey">
                  {AuthConstants.SIGNIN_REMEMBER_ME}
                    </span>
                </Grid>
              </Grid>

              <Grid item mt={2}> 
                 <Link
                 class="no-underline text-href-grey cursor-pointer"
                 to="auth/forgot-password"
                 onClick={handleChangeNavigation}
               >
                 {" "}
                 {AuthConstants.FORGOT_PASSWORD}
               </Link>
              </Grid>
            </Grid>
          </Grid>
          <CustomButton
            classVal={
              buttonEnable
                ? "bg-teal-800 text-white w-36 h-10 rounded-lg mt-8 font-semibold	"
                : "bg-slate-50  w-36 h-10 rounded-lg shadow-md text-dark-grey mt-8 font-semibold"
            }
          >
           <h1>Sign In</h1>
          </CustomButton>

          <h1 class="my-2">Don't have an account?  <span class="text-colorLink underline" > 
          {/* <Link>
          Click here 
          </Link>  */}
          <a href="https://forms.gle/1MKAKB1c7Ba4fkep7" target="_blank" >
             Click here 
            </a>
       
             </span> </h1>
        </Grid>
      </Paper>
    </form>
  );
};

export default SignIn;
