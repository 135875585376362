const EmailType = /^[A-Z]+[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
const NumberType = /\D/g;
// eslint-disable-next-line no-useless-escape
const PasswordType = /^[a-zA-Z0-9!@#\$%\^\&*_=+-]{8,}$/i;
const NewPasswordType = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
// const RegexPassword =  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).+$/;
const RegexPassword =/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).+$/
const ErrorResponse = /(?=[A-Z])/


export const ValidationConstants = {
  TypeConstants: {
    PasswordType,
    RegexPassword,
    NewPasswordType,
    EmailType,
    NumberType,
    ErrorResponse
  },
};