import { getData } from "./AsyncStorage"

const isLoggedIn = () => {
    const token = getData('accessToken')
    return token ? true : false
  }

  export {

    isLoggedIn,
  }