
//package imports
import React,{useState,useEffect} from 'react'
import { useHistory  } from "react-router-dom";



//Component imports
import CustomButton from '../../utils/reusableComponents/CustomButton';
import MuiCustomTabel from '../../utils/reusableComponents/MuiCustomTabel';
import { ReusableSearch } from '../../utils/reusableComponents/ReusableSearch';
import { CustomModal} from '../../utils/reusableComponents/CustomModal';
import ModalWithParticulars from '../../utils/reusableComponents/ModalWithParticulars';
import filterIcon from '../../assests/images/FilterOutline.png'
import { sample,columnsProject,sampleData } from '../../utils/constants/TableData';
import BreadcrumbsHeader from '../../utils/reusableComponents/BreadCrumbsHeader';
import { getParticularList } from '../../services/apiServices/GetServices';



const Particular = (props) => {
  const { location } = props;
  const [showModal, setshowModal] = useState(false);
  const [data,setData]=useState([])
  const [totalParticulars,setTotalParticular]=useState(0)
  const [filteredData,setFilteredData]=useState([])
  const [particularData,setParticularData]=useState([])
  const history = useHistory();


  let projectId = props?.location?.state?.info?.id  || props?.location?.state?.projectId

  let clinetId = props?.location?.state?.clientid

  let client_info = props?.location?.state?.heading1?.client_info || props?.location?.state?.client_info
  

  useEffect(() => {
    if(projectId != undefined){
      callParticularlistApi()
    }
   
  }, [projectId]);


  useEffect ( () => {
    if(filteredData.length != 0) {
      setData(filteredData)
    }
  },[filteredData.length])

 


  //function to call list api
  const callParticularlistApi =  async () => {
    const res  = await getParticularList(`admin/project_status/${projectId}`)

    if(res?.success){
     setFilteredData(res?.data)
     setParticularData(res?.data)
     setTotalParticular(res?.total_particulars)
    }else{
     console.log("res",res?.message)
    }
 }
   
  //function to close modal
  const handleClose = () => {
    setshowModal(false)
  }


  //breadcrumbs props passing 
  const heading =[ {
    heading1:props?.location?.state?.heading1?.heading2 ||props?.location?.state?.heading2 ,
    heading2: props?.location?.state?.heading3 ||props?.location?.state?.heading2,
    heading3:"Particulars"
  } ]



  let pathName = `${location?.pathname}/formParticular`

  
  const handleSearchClick = (searchVal) => {

    const newSearchTerm = searchVal;
  

    if (newSearchTerm === "") {  callParticularlistApi(); return; }
    else{
      const filterBySearch = particularData.filter((item) => {
        if (item?.date.toLowerCase()
            .includes(newSearchTerm.toLowerCase())) { return item; }
    })
   setFilteredData(filterBySearch)
    }

   
}

const handleSearchClear = () => {

 callParticularlistApi()
}
  

  return (
    <div class="flex flex-col ml-10 mr-4 w-full ">
      <div class="flex mt-2 self-start">
        <BreadcrumbsHeader
          heading1 = {props?.location?.state?.heading1?.heading1 || props?.location?.state?.heading1}
          heading2 = {props?.location?.state?.heading1?.heading2||props?.location?.state?.heading2}
          heading3 = { props?.location?.state?.heading3 || props?.location?.state?.heading2}
          info = {clinetId}
          clientDetails={client_info}
          
        />

      </div>
      <div class="flex justify-between items-center my-4  flex-row w-full  ">
      
      <ReusableSearch onChange = {handleSearchClick} onClear = {handleSearchClear}/>

      <div class="flex flex-row">


      {/* <div>
      <button  class="flex flex-row justify-around items-center text-primaryColor border border-primaryColor w-20 h-10 rounded-lg mx-4 " >
       <span>Filter</span> <span> <img src={filterIcon} class="w-4 h-4" /> </span>
        </button>
        </div> */}


      <CustomButton
       children={"Create Particular"}
       onClick={() => setshowModal(true)}
       classVal="bg-teal-800 text-white w-36 h-8 hover:bg-teal-700 rounded-lg "
      />

      <CustomButton
       children={"Update"}
       disabled = {props?.location?.state?.info?.is_active === 0  ? true : false }
       onClick = {() => history.push({pathname: pathName,
       state: { name : heading,title:props?.location?.state?.title,projectId : projectId || props?.location?.state?.projectId ,clinetId :props?.location?.state?.clientid,clientDetails:client_info}}
     )}
       classVal= { props?.location?.state?.info?.is_active === 0 ? "bg-slate-400 text-white w-20 h-8 mx-4  rounded-lg " : "bg-teal-800 text-white w-20 h-8 mx-4 hover:bg-teal-700 rounded-lg " }
      />
      </div>

      <CustomModal>
        <ModalWithParticulars
        width = {"w-2/5"}
        height = {"h-5/5"}
        title={"Create Particular"}
        shouldShow={showModal} 
        closeModal={handleClose} 
        projectId = {projectId}
        />
      </CustomModal>
      </div>
      <div class="w-full h-4/5">
      <MuiCustomTabel
      path={pathName}
      name={heading}
      data={filteredData}
      particularCount={totalParticulars}
      columnHeader={columnsProject}
      projectId={projectId}
      />
      </div>
         
    </div>
  )
}

export default Particular