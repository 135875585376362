//Package imports
import React ,{useState}from "react";
import classNames from "classnames";
import cn from "classnames";
import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
} from "@heroicons/react/24/outline";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useRouteMatch ,useHistory} from "react-router-dom";

//component imports
import { removeData } from "../../utils/AsyncStorage";
import signOutIcon from '../../assests/icons/SignOutIcon.svg'
import { navItemsPanel,navItemsClients} from "../../utils/constants/leftSideBarConstants";
import sideBarLogo from '../../assests/images/sidebar-logo1.png';
import collapsedSideLogo from '../../assests/images/navbar-logo.png';
import { CustomModal } from "../../utils/reusableComponents/CustomModal";
import SimpleModal from "../../utils/reusableComponents/SimpleModal";



const  Sidebar = ({ collapsed, setCollapsed }) => {
  const { path } = useRouteMatch();
  const [iconIndex,setIconIndex]=useState()
  const [showLogoutModal,setShowLogout]=useState(false)
  const history = useHistory();

  let pathName = path
  const items = pathName == "/panel" ? navItemsPanel : navItemsClients

  const pathChange = history.location.pathname 


  let pathArray = pathChange.split("/")
  let pathOnChange = `${pathArray[2]}`

  //function to handle logout modal
  const handleLogout = () => {
       setShowLogout(true)
  }

  //function to close lagout modal
  const handleClose = () => {
    setShowLogout(false)
  }

  const onLogout = () => {
    removeData("accessToken");
    history.push("/auth");
  }

  const handleSidebarHover = (item,index) => {
        setIconIndex(index)
  }
 
  // 👇 use the correct icon depending on the state.
  const Icon = collapsed ? ChevronDoubleRightIcon : ChevronDoubleLeftIcon;
  return (
    <div
      className={cn({
        "bg-white-700 text-zinc-50 z-10 shadow-black shadow-lg": true,
      })}
    >
      <div
        className={cn({
          "flex flex-col justify-between": true,
        })}
      >
        {/* logo and collapse button */}
        <div
          className={cn({
            "flex items-center ": true,
            "py-5 justify-center items-center": !collapsed,
            "py-5 justify-center": collapsed,
          })}
          onClick={() => {history.push(`${pathName}/dashboard`)}}
        >
          {!collapsed && 
            
          <img src={sideBarLogo} class="w-1/2" />
            
            }
         
         {
          collapsed && 

          <div 
          className={cn({
            "grid place-content-center": true, // position
            //"hover:bg-indigo-800 ": true, // colors
            "w-16 h-10 ": true, // shape
            "text-black":true
          })}
          >
  <img src={collapsedSideLogo} />
            </div>
         }
        
        </div>
        <nav class="flex-grow">
          <div
            class={classNames({
              "my-2 flex flex-col  gap-2 items-stretch ": true,
            })}
          >
            {items.map((item, index) => {
              let hrefArray = item?.href.split("/")
              return (
                <div
                  key={index} 
                  onClick={() => handleSidebarHover(item,index)}
                  class={classNames(
                    hrefArray[2] === pathOnChange ? 
                    {
                    "text-black bg-teal-50 font-bold text-teal-500 flex": true, //colors
                    "transition-colors duration-300": true, //animation
                    " p-2 mx-3 my-1  ": !collapsed,
                    "justify-center items-center w-16 h-14 ": collapsed,
                  }
                   :
                   {
                    "text-black hover:bg-teal-50  hover:text-teal-500 flex": true, //colors
                    "transition-colors duration-300": true, //animation
                    " p-2 mx-3 my-1 ": !collapsed,
                    "justify-center items-center w-16 h-14 ": collapsed,
                  }
                  )}
                  
                > 
                    <Link to={item.href}  key={index} class ={ !collapsed ? "flex" : null }>
                      <div class={!collapsed ? "flex flex-row w-48" : "flex flex-row w-5/5"}>
                        <div>
                        <img src={ iconIndex ===  index? item?.iconOnchange : item?.icon}  class={ !collapsed ? "w-7"  :"w-7 ml-4" } /> 
                        </div>
                        <div class="mx-4 text-left">
                        {!collapsed && item.label}
                        </div>
                      </div>
                    </Link>
                   
                </div>
                
              );
            })}

            <button onClick={handleLogout} class="flex  gap-3 ml-4">
            <img src={ signOutIcon} />
            {
              !collapsed ?
              <div class="p-1 mr-2">
              <h1 class="text-black text-center">Signout</h1>
              </div>
               :
              null
            }
           
            </button>

          </div>
        </nav>
        <CustomModal>
        <SimpleModal
         title={"Are you sure you want to Signout?"}
         shouldShow={showLogoutModal}
         closeModal={handleClose} 
         onAction={onLogout}
       
        />
        </CustomModal>
      </div>
    </div>
  );
};
export default Sidebar;

