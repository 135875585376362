import React from 'react';
import './index.css';
import ReactDOM from "react-dom";
import App from './App';
import Loader from "./components/loadingScreen/Loader";
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Loader />
  </React.StrictMode>,
  document.getElementById("root")
);
