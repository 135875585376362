//package imports
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, Grid, IconButton, Paper,  } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { Link } from "react-router-dom";

//component imports 
import CustomTextField from "../../utils/reusableComponents/CustomTextField";
import { removeData, storeData } from "../../utils/AsyncStorage";
import CustomButton from "../../utils/reusableComponents/CustomButton"
import { AuthConstants } from "../../utils/constants/AuthConstnats";
import { activateOrResetPassword, createOrResetPassword } from "../../services/apiServices/PostServices";
import { ValidationConstants } from "../../utils/constants/ValidationConstants";
import { showToast } from '../../utils/constants/ToastConstants';
import PasswordEncryption from "../../utils/constants/PasswordEncryption";


const SetNewPassword = (props) => {
  const { location } = props;
  const { pathname, search } = location
  var CryptoJS = require("crypto-js");


  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [tokenVal, setTokenVal] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [activate, setActivate] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [initalLoading, setInitalLoading] = useState(true);
  //const history = useHistory()
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: {
      checkbox: false,
    },
  });

  useEffect(() => {
    callBackFunction();
    // document.body.style.backgroundColor = "#d5e4f1";
  }, []);


  //**In this handler through params we are fetching urls and if we have key or token user will navigate to verify key token else page is blank */
  const callBackFunction = async () => {
    setInitalLoading(true);
    const params = new URLSearchParams(search);

    if (pathname === "/auth/set-password") {
      const token = params.get("token");
      const key = params?.get("key");
      let reqBody = {
        key: key,
        token: token,
      };
   
      if (key) {
        await activateOrResetPassword("auth/verify_key_token", reqBody)

          .then((res) => {
           
            if (res?.success) {
              setActivate(true);
              setTokenVal(res?.token);
            } else {
          
              setActivate(false);
            }
          })
          .catch((err) => {
            setActivate(false);
       
          });
      } else if (token) {
        setActivate(false);
        setTokenVal(token);
      }
    }
    setInitalLoading(false);
  };


  //Function to call api integration after submiting
  const onSubmit = async () => {
    // setLoading(true);

    const secretPass = 'StrId@AhM_@aHM*2023'
    const encodedNewPassword = `WEB|${password?.trim()}|aHm$*2@2309_aHm@`;
    const encodedConfirmPassword=`WEB|${confirmPassword?.trim()}|aHm$*2@2309_aHm@`;

    const PasswordEncoded =PasswordEncryption(encodedNewPassword)
    const ConfirmPasswordEncoded = PasswordEncryption(encodedConfirmPassword);

   

    let body = {
      newPassword: PasswordEncoded,
      confirmPassword: ConfirmPasswordEncoded,
    };
  
    const res = await createOrResetPassword(body, tokenVal)
    if (res?.success) {
      showToast(1, res?.message);
      setLoading(false);
      setTimeout(() => {
        props?.history?.push("/auth");
      }, 2000);
    } else {
      showToast(3, res?.error.message);
       //setError(true);
      setLoading(false);
     
    }
    setLoading(false);
  };

 

  // To view password
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  // To view confirm password
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);



  //function to handle token active
  const handleTokenActivated = () => {
    return (
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <Paper elevation={0} className={`paper_margin`} class="w-2/4 m-auto">
          <Grid container direction={"column"} justifyContent={"center"} class="space-y-10">

            <div>
              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} justifyContent={"center"} class="mt-1.5">
                <CustomTextField
                  control={control}
                  header={AuthConstants?.SET_PASSWORD_HEADER}
                  required
                  name="current_password"
                  placeholder={AuthConstants?.SET_PASSWORD_HEADER}
                  size="small"
                  type={showPassword ? "text" : "password"}
                  value={password}
                  inputProps={{ maxLength: 15,sx: { borderRadius: "0.75em" }  }}
              rules={{
                required:
                AuthConstants?.SET_PASSWORD_REQUIRED,
                pattern: {
                  // eslint-disable-next-line no-useless-escape
                  value: ValidationConstants.TypeConstants.RegexPassword,
                  message:
                  AuthConstants?.PASSWORD_VALIDATION_MESSAGE,
                },
                minLength: {
                  value:
                    AuthConstants?.SET_NEW_PASSWORD_REQUIRED_MINVALUE,
                  message:
                  AuthConstants?.SET_MINVALUE_MESSGAGE,
                },
              }}
              
                  InputProps={{
                    sx: { borderRadius: "0.75em" }  ,
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => {
                    setPassword(e);
                  }}
                />


              </Grid>

              <Grid item xs={3} sm={3} md={3} lg={3} xl={3} justifyContent={"center"} class="mt-1.5">
                <CustomTextField
                  control={control}
                  header={AuthConstants?.SET_CONFIRM_PASSWORD_HEADER}
                  required
                  name="password_repeat"
                  placeholder={AuthConstants?.SET_CONFIRM_PASSWORD_HEADER}
                  size="small"
                  type={showConfirmPassword ? "text" : "password"}
                  value={confirmPassword}
                  inputProps={{maxLength: 15,sx: { borderRadius: "0.75em" }}}
                  rules={{
                    required:AuthConstants?.SET_CONFIRM_PASSWORD_VALIDATE,
                    validate: (value) => {
                      return value.trim() == password
                        ? true
                        : confirmPassword && AuthConstants?.SET_CONFIRM_PASSWORD_VALIDATE;
                    },
                  }}
                  InputProps={{
                    sx: { borderRadius: "0.75em" }  ,
                    endAdornment: (
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowConfirmPassword}
                        edge="end"
                      >
                        {showConfirmPassword ? (
                          <VisibilityOutlinedIcon />
                        ) : (
                          <VisibilityOffOutlinedIcon />
                        )}
                      </IconButton>
                    ),
                  }}
                  onChange={(e) => {
                    setConfirmPassword(e);
                  }}
                />


              </Grid>
            </div>
            <div>
              <CustomButton
                children={"Save Password"}
                classVal={
                  password !== "" && confirmPassword !== ""
                    ? "bg-teal-800 text-white w-36 h-10 rounded-lg mt-12	"
                    : "bg-disabledColor  w-36 h-10 rounded-lg shadow-md text-white mt-12	"
                }
                type={"submit"}

              />
            </div>
          </Grid>
        </Paper>
      </form>
    );
  };

  //function to handle token deactivation.
  const handleTokenDeactivated = () => {
    return (
      <Grid
        container
        xs={12}
        flexDirection="column"
        justifyContent="space-around"
        alignItems="center"
        className="margin-top-reset"
      >
         <h1>
          {AuthConstants.TOKENEXPIERED}
          <Link to="/auth/forgot-password" class="text-blue-700"   >
            ForgotPassword
          </Link>
          </h1>
      </Grid>
    );
  };


  return (
    <div>
      {initalLoading ? (
        <div
          className="loading-reset-password"
        >
          <h1>Loading...</h1>
        </div>
        
      ) : (
        <>{activate ? handleTokenActivated() :handleTokenDeactivated()}</>
      )}
    </div>
  )
}

export default SetNewPassword