import React from 'react';


function CustomButton(props) {
  const { children,size,bgColor,textColor,hover,primary,width,onClick,type,disabled,classVal,loading} = props;

  
  return (
    <>
     <button
    //class={`${primary} ${size}  font-bold py-2 px-4 rounded text-xl ${width}`}
    class={classVal}
    onClick={onClick}
    type={type}
    disabled={disabled}
    loading={loading}
    >
            {children}
        </button>
    </>
   
  );
}

export default CustomButton;