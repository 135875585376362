import { MenuItem, TextField } from "@mui/material";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import { ITEM_HEIGHT, ITEM_PADDING_TOP } from "../../utils/constants/DropDownConstants";

export const ReusableDropDown= (props)=>{
    const{onChange,defaultValue,name,data,colorChange,value,disable} =props
   
    const {
        register,
        handleSubmit,
        formState: { errors },
      } = useForm();
    return(
        <TextField
        fullWidth
        disabled={disable}
        // id="outlined-select-currency"
        select
        size="small"
        defaultValue={ defaultValue != undefined ? defaultValue : ""}
        value={value}
        placeholder="search from dropdown "
       sx={{color:colorChange,textAlign:"start"}}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              style: {
                maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
                maxWidth:"40%",
                borderRadius:"0.25em",
              },
            },
          },
        }}
        onChange={onChange}
      >

        {data.map((option) => (
          
          <MenuItem key={option.id} value={name ?  option?.Project_name|| option.name :option.id } >
            { option?.Project_name || option.name}
          </MenuItem>
        ))}
      </TextField>
    )
}