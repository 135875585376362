
//package imports
import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Checkbox, Grid, IconButton, Paper, TextField } from "@mui/material";

//component imports
import CustomTextField from "../../utils/reusableComponents/CustomTextField";
import { ReusableDropDown } from "./ReusableDropDown";
import { APP_CONSTANTS } from "../constants/AppConstants";
import { getOccuranceList } from "../../services/apiServices/GetServices";
import { createParticulars } from "../../services/apiServices/PostServices";
import { showToast } from "../constants/ToastConstants";




const ModalWithParticulars = ({ closeModal, projectId }) => {

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
    } = useForm({
        defaultValues: {
            checkbox: false,
        },
    });

    const history = useHistory();
    const [selectOpiton, setSelectOption] = useState("")
    const [projectName, setProjectName] = useState("")
    const [occurenceId, setOccurenceId] = useState("")
    const [frequencyId, setFrequencyId] = useState("")
    const [errorOccurence, setErrorOccurence] = useState("")
    const [errorFrequency, setErrorFrequency] = useState("")
    const [errorName, setErrorName] = useState("")
    const [errorOptions, setErrorOptions] = useState("")
    const [particularType, setParticularType] = useState("")
    const [occuranceData, setOccuranceData] = useState([])
    const [frequencyData, setFrequencyData] = useState([])
    const [statusData, setStatusData] = useState([])
    const [arrayids, setArrayIds] = useState([])
    const [constids, setConstIds] = useState([])

    useEffect(() => {
        callOccuranceList()
        callFrequencyList()
        callStatusList()
        allStatusIds()
    }, []);




    //function to call list api
    const callOccuranceList = async () => {
        const res = await getOccuranceList("master/occurrence")
        if (res?.success) {
            setOccuranceData(res?.data.filter((data) => {
                return data?.status === 1
            }))
        } else {
            console.log("res", res?.message)
        }
    }

    //all status ids

    const allStatusIds = () => {

        setConstIds(statusData.map((data) => {
            return data?.id
        }))
    }

    //function to call list api
    const callFrequencyList = async () => {
        const res = await getOccuranceList("master/frequency")
        if (res?.success) {
            setFrequencyData(res?.data.filter((data) => {
                return data?.status === 1
            }))
        } else {
            console.log("res", res?.message)
        }
    }


    //function to call list api
    const callStatusList = async () => {
        const res = await getOccuranceList("master/status")
        if (res?.success) {
            setStatusData(res?.data.filter((data) => {
                return data?.status === 1
            }))
        } else {
            console.log("res", res?.message)
        }
    }


    const handleOccurence = (e) => {
        setOccurenceId(e?.target?.value)
        setErrorOccurence("")
    }

    const handleFrequency = (e) => {
        setFrequencyId(e?.target?.value)
        setErrorFrequency("")
    }

    const handleSelectOption = (option) => {

        setErrorOptions("")
        if (option) {
            setSelectOption(option)

            setParticularType("choice")
        } else {
            setSelectOption(option)

            setParticularType("text")
        }
    }



    //const [startDate,setStartDate]=useState()

    const onSubmit = async () => {

        const reqbody = {
            project_id: projectId,
            name: projectName,
            occurrence_id: occurenceId,
            frequency_id: frequencyId,
            status_id: arrayids.toString() || constids.toString(),
            is_text: selectOpiton == false ? 1 : 0

        }

        if (projectName === "") {
            setErrorName("Please enter particular name")
        }
        if (occurenceId == "") {
            setErrorOccurence("Please enter occurence")
        }
        if (frequencyId == "") {
            setErrorFrequency("Please enter frequency")
        }
        if (particularType == "" || (particularType === "choice" && arrayids.length === 0)) {
            setErrorOptions("Plesese select option")
        }
        if (occurenceId == "" && frequencyId == "") {
            setErrorOccurence("Please enter Occurence")
            setErrorFrequency("Please enter frequency")
        }
        if (occurenceId != "" && frequencyId != "" && ((particularType != "" && arrayids.length != 0) || particularType === "text" ) && projectName != "") {
            setErrorOptions("")
            const res = await createParticulars("admin/project_particulars", reqbody);
            if (res?.success) {
                showToast(1, res?.message);
                closeModal()
            } else {
                showToast(3, res?.message);
            }

        }
    }


    const handleRemoveArray = (id) => {
        let array = arrayids.pop(id)
        setArrayIds(arrayids)
    }

    const handleOnChange = (e, id) => {
        if (e?.target?.checked) {
            setArrayIds([...arrayids, id])
        } else {
            handleRemoveArray(id)
        }

    }


    const checkString = (string) => {
        let string1 = string
        return /^[A-Za-z0-9-._/()&$\s]*$/.test(string1)
    }

    const handleParticularName = (e) => {
        let name = e
        // if (checkString(name.trimStart())) {
            setProjectName(name.trimStart())
            setErrorName("")
        //}

    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Prevent the default Enter key behavior
            // Add your custom logic here
        }
    }



    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                <Paper elevation={0} className={`paper_margin`} class=" m-auto my-1">
                    <Grid container xs={12} direction={"column"} justifyContent={"center"} class=" flex flex-col ">


                        <div class="flex flex-col  justify-center" >

                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} class="w-3/4 self-center my-2 mt-1.5">
                                <CustomTextField
                                    control={control}
                                    required
                                    name="name"
                                    size="small"
                                    header={"Particular Name"}
                                    value={projectName.trimStart()}
                                    placeholder={"Particular Name*"}
                                    borderRadius={"0.75em"}
                                    rules={{
                                        required: APP_CONSTANTS?.PARTICULAR_NAME_REQUIRED,
                                        // pattern: {
                                        //     value: /^[a-zA-Z\s]+[a-zA-Z0-9,.@_():"]*$/,
                                        //     message: "Please enter valid particular name",
                                        // },
                                    }}
                                    InputProps={{ sx: { borderRadius: "0.25em" } }}
                                    onChange={(e) => {
                                        handleParticularName(e);

                                    }}
                                // event={handleEvent}


                                />
                                {
                                    errorName != "" ?
                                        <div class="flex justify-start">
                                            <p class="text-red-400 text-red capitalize">
                                                {errorName}
                                            </p>
                                        </div> :
                                        null
                                }
                            </Grid>


                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} class=" w-3/4 my-2  self-center mt-1.5">
                                <div class="flex flex-start">
                                    <label>
                                        Occurrence<span class="text-rose-500">*</span>
                                    </label>
                                </div>
                                <ReusableDropDown data={occuranceData}
                                    onChange={handleOccurence}
                                    value={occurenceId}

                                />
                                {
                                    errorOccurence != "" ?
                                        <div class="flex justify-start">
                                            <p class="text-red-400 text-red capitalize">
                                                {errorOccurence}
                                            </p>
                                        </div> :
                                        null
                                }
                            </Grid>

                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} class=" w-3/4 my-2  self-center mt-1.5">
                                <div class="flex flex-start">
                                    <label>
                                        Frequency <span class="text-rose-500">*</span>
                                    </label>
                                </div>
                                <ReusableDropDown data={frequencyData}
                                    onChange={handleFrequency}
                                    value={frequencyId}
                                />
                                {
                                    errorFrequency != "" ?
                                        <div class="flex justify-start">
                                            <p class="text-red-400 text-red capitalize">
                                                {errorFrequency}
                                            </p>
                                        </div> :
                                        null
                                }
                            </Grid>



                            <Grid item xs={3} sm={3} md={3} lg={3} xl={3} class=" w-3/4 mt-4 bg-primaryColor self-center rounded-md mt-1.5">
                                <div class="flex my-3 justify-center">
                                    <div class="flex flex-row items-center ml-2   bg-white text-black w-2/5 py-2 text-xl mx-2 rounded-md" >
                                        <input onKeyDown={handleKeyPress} onClick={() => handleSelectOption(true)} id="helper-radio-4" name="helper-radio" type="radio" value="" class="w-4 h-4 ml-2 text-blue-400 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <h1 class="ml-4"> Choice </h1>
                                    </div>
                                    <div class="flex flex-row items-center ml-2  bg-white text-black w-2/5 py-2 text-xl mx-2 rounded-md " >
                                        <input onKeyDown={handleKeyPress} onClick={() => handleSelectOption(false)} id="helper-radio-4" name="helper-radio" type="radio" value="" class="w-4 h-4 ml-2 text-blue-400 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                                        <h1 class="ml-4">Text</h1>
                                    </div>
                                </div>

                            </Grid>
                            {
                                selectOpiton ?
                                    <div class="border border-zinc-300 w-3/4 h-28 self-center divide-y-2 divide-inherit overflow-y-auto ">
                                        {
                                            statusData.map((data) => {
                                                return (
                                                    <div class="flex flex-start py-2" key={Object.values(data)} >
                                                        <div class="mx-2" >
                                                            <input onKeyDown={handleKeyPress} key={Object.values(data)} type="checkbox" onChange={(e) => handleOnChange(e, data?.id)} />
                                                        </div>
                                                        <div class="mb-2 mx-1">
                                                            <h1>{data?.name}</h1>
                                                        </div>

                                                    </div>
                                                )
                                            })

                                        }
                                    </div>


                                    :
                                    null
                            }

                            {
                                errorOptions != "" ?
                                    <div class="flex w-3/4 self-center">
                                        <p class="text-red-400 text-red capitalize">
                                            {errorOptions}
                                        </p>
                                    </div> :
                                    null
                            }

                        </div>


                        <div class="self-center">

                            <button onClick={closeModal} class="text-primaryColor border border-primaryColor w-32 h-10 rounded-lg mt-6">
                                Cancel
                            </button>
                            <button type="submit" class="bg-primaryColor w-32 h-10 rounded-lg shadow-md text-white mt-6 mx-2"  >
                                Create
                            </button>
                        </div>
                    </Grid>
                </Paper>
            </form>
        </div>

    )
}




export default ModalWithParticulars