// Forget password constants
const FORGET_PASSWORD = "Forgot Password?";
const FORGET_PASSWORD_DESC = "No worries, reset password link will be shared to your email id";
const ENETR_EMAIL_ID = "Enter Email ID";
const SEND_LINK = " Send Link";
const DIDNOT_RECEIVE_MAIL = "Didn’t receive a email?";
const CLICK_TO_RESEND = "Click to resend";
const BACK_TO_LOGIN = "Back to log in";

//validation messages for model textFields

const ADDRESS_REQUIRED_Message = "Please enter address"
const PROJECT_NAME_REQUIRED_Message = "Enter Project Name"
const PARTICULAR_NAME_REQUIRED = "Enter Particular Name"
const CLIENT_NAME = "Client Name"
const CONTACT_PERSON_NAME = "Contact Person Name"
const CLIENT_NAME_REQUIRED = "Client Name Required"
const CONTACT_PERSON_NAME_REQUIRED = "Contact Person Name Required"
const EMAIL_REQUIRED = "Email ID Required"
const NUMBER_REQUIRED = "Mobile Number Required"
const ADDRESS_REQUIRED = "Address Required"





const WELCOME= "Welcome To"
const APP_NAME = "Lorem ipsum"
const APP_DES = 'Track and Monitors Application Health with Ease'
const SIGN_IN = 'Sign In'
const FORGOT_PASS = 'Forgot Password?'
const FORGOT_DES = 'No worries, reset password link will be shared to your email ID'
const SET_NEW_PASS = 'Set New Password'
const CHANGE_PASS= 'Change Password'


export const APP_CONSTANTS = {
    WELCOME,
    APP_NAME,
    APP_DES,
    SIGN_IN,
    FORGOT_PASS,
    FORGOT_DES,
    SET_NEW_PASS,
    CHANGE_PASS,
    FORGET_PASSWORD,
    FORGET_PASSWORD_DESC,
    ENETR_EMAIL_ID,
    SEND_LINK,
    DIDNOT_RECEIVE_MAIL,
    CLICK_TO_RESEND,
    BACK_TO_LOGIN,
    ADDRESS_REQUIRED_Message,
    PROJECT_NAME_REQUIRED_Message,
    PARTICULAR_NAME_REQUIRED,
    CLIENT_NAME,
    CONTACT_PERSON_NAME,
    CLIENT_NAME_REQUIRED,
    CONTACT_PERSON_NAME_REQUIRED,
    EMAIL_REQUIRED,
    NUMBER_REQUIRED,
    ADDRESS_REQUIRED
}








