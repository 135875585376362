 

import { getData } from "../AsyncStorage";


const PasswordDecryption = (password) => {
    const secretPass = 'StrId@AhM_@aHM*2023'
    var CryptoJS = require("crypto-js");
    const userData = getData("userData"); 
    const parsedUserData = JSON.parse(userData);

    var passwordString = CryptoJS.AES.decrypt(parsedUserData?.user_password,secretPass);
    var decryptedPassword = JSON.parse(passwordString.toString(CryptoJS.enc.Utf8));

    let char1 =  decryptedPassword.indexOf("|") + 1;
    let char2 =  decryptedPassword.lastIndexOf("|");

    let Password = decryptedPassword.substring(char1,char2);

      return Password
}

export default PasswordDecryption