// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loaderSpin {
  border: 5px solid #f3f3f3; 
  border-top: 5px solid #1976d2; 
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 2s linear infinite;
  top: calc(50% - 24px);
  left: calc(50% - 24px);
  position: absolute;
  z-index: 999999 !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loaderWrapper {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9999;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}


`, "",{"version":3,"sources":["webpack://./src/components/loadingScreen/Loader.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,6BAA6B;EAC7B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,kCAAkC;EAClC,qBAAqB;EACrB,sBAAsB;EACtB,kBAAkB;EAClB,0BAA0B;AAC5B;;AAEA;EACE,KAAK,uBAAuB,EAAE;EAC9B,OAAO,yBAAyB,EAAE;AACpC;;AAEA;EACE,eAAe;EACf,QAAQ;EACR,SAAS;EACT,UAAU;EACV,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB","sourcesContent":[".loaderSpin {\n  border: 5px solid #f3f3f3; \n  border-top: 5px solid #1976d2; \n  border-radius: 50%;\n  width: 50px;\n  height: 50px;\n  animation: spin 2s linear infinite;\n  top: calc(50% - 24px);\n  left: calc(50% - 24px);\n  position: absolute;\n  z-index: 999999 !important;\n}\n\n@keyframes spin {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(360deg); }\n}\n\n.loaderWrapper {\n  position: fixed;\n  top: 0px;\n  left: 0px;\n  right: 0px;\n  bottom: 0px;\n  z-index: 9999;\n  background: rgba(0, 0, 0, 0.6);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
