import * as React from "react";
import { useEffect,useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Accounts from "./master/Accounts";
import Occurrence from "./master/Occurance";
import Frequency from "./master/Frequency";
import Status from "./master/Status";

export default function LabTabs() {
  const [value, setValue] = React.useState("1");
  



  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

 

  

  return (

    <Box sx={{ width: "100%", typography: "body1"  }}>
      <div class="w-3/5 flex ">
      <h1 class=" text-black ml-6 mt-2 font-bold text-1xl" >Master Data</h1>
      </div>
      <TabContext style={{ textTransform: "capitalize" }} value={value} class="h-2/5">
        <Box sx={{ borderBottom: 1, borderColor: "divider",}}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab
              label="Fields"
              value="1"
              sx={{
                textTransform: "capitalize",
                fontFamily: "inter",
                fontWeight:"bold",
              }}
            />

            <Tab
              label="Occurrence"
              value="2"
              sx={{
                textTransform: "capitalize",
                fontFamily: "inter",
                fontWeight:"bold"
              }}
            />
            <Tab
              label="Frequency"
              value="3"
              sx={{
                textTransform: "capitalize",
                fontFamily: "inter",
                fontWeight:"bold"
              }}
            />
            <Tab
              label="Status"
              value="4"
              sx={{
                textTransform: "capitalize",
                fontFamily: "inter",
                fontWeight:"bold"
              }}
            />
          </TabList>
        </Box>
        <TabPanel
          value="1"
        >
          {Accounts()}
        </TabPanel>
        
        <TabPanel
          value="2"
          class="capitalize mx-6 p-0 h-4/5"
        >
          { Occurrence(value)}
        </TabPanel>
        <TabPanel
          value="3"
          class="capitalize mx-6 p-0"
        >
          {Frequency()}
        </TabPanel>
        <TabPanel
          value="4"
          class="capitalize mx-6 p-0"
        >
          {Status()}
        </TabPanel>
      </TabContext>
    </Box>
  );
}
