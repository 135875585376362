import CustomButton from "./CustomButton";

export const CustomModal = ({ children, props }) => {

  let height = children?.props?.height
  let width = children?.props?.width


  let modalWidht = height ? height : "w-2/5"
  let modalHeight = width ? width : "h-48"

  return children?.props?.shouldShow ? (
    <div style={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: `translate(${-50}%, ${-50}%) `,
      zIndex:1000,
    }
    }>
      <div
        class=" flex items-center justify-center z-[0] w-screen h-screen  bg-black/40 overflow-auto "
      // onClick={onRequestClose}
      >
        <div
          class={`flex flex-col justify-center ${modalWidht} ${modalHeight} p-5 bg-white  rounded-lg`}
        >
          <h1 class="text-2xl mb-3 font-bold text-gray font-sans ">{children?.props?.title}</h1>
          <h2 class="w-3/4 self-center"  >{children?.props?.description}</h2>
          {children}
        </div>
      </div>
    </div>
  ) : null;
};