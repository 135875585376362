
//package imports
import React,{useState,useEffect} from 'react'


//component imports
import CustomButton from '../../utils/reusableComponents/CustomButton';
import MuiCustomTabel from '../../utils/reusableComponents/MuiCustomTabel';
import { ReusableSearch } from '../../utils/reusableComponents/ReusableSearch';
import { CustomModal} from '../../utils/reusableComponents/CustomModal';
import ModalWithDatePicker from '../../utils/reusableComponents/ModalWithDatePicker';
import { showToast } from '../../utils/constants/ToastConstants';
import { sample,columnsProject1,sampleData ,AllProject} from '../../utils/constants/TableData';
import BreadcrumbsHeader from '../../utils/reusableComponents/BreadCrumbsHeader';
import { getProjectsList } from '../../services/apiServices/GetServices';
import { removeData, storeData,getData } from "../../utils/AsyncStorage";
import SimpleModal from '../../utils/reusableComponents/SimpleModal';
import { createProject } from '../../services/apiServices/PostServices';
import { ReusableDropDown } from '../../utils/reusableComponents/ReusableDropDown';
import filterIcon from "../../assests/images/FilterOutline.png"





const AllProjects = (props) => {

  const { location } = props;
  const [showModal, setshowModal] = useState(false);
  const [update,setUpdate]=useState(false)
  const [modelHeading,setModalHeading]=useState("Create Project")
  const [data,setData]=useState([])
  const [predefinedData,setPredefinedData] = useState([])
  const [id,setId]=useState(props?.location?.state?.info?.user_id )
  const [statusValue,setStatusValue]=useState(1)
  const [updateData,setUpdateData]=useState([])
  const [showStatus,setShowStatus]=useState(false);
  const [newSearchTerm, setSearchItem] = useState("");
  const [filteredData,setFilteredData]=useState([])
  const [declineStatus,setDeclineStatus]=useState("")
  const [state,setState] = useState("")
  const [resData,setResData]=useState([])
  

  
  useEffect(() => {
      callProjectlistApi()  
  }, []); 


  useEffect ( () => {
    if(filteredData.length != 0) {
      setData(filteredData)
    }
  },[filteredData.length])


  const client_info  = props?.location?.state?.info
  
 
 const filterData = [
  {
  id:"All",
  name:"All"
  },
  {
    id:"Active",
    name:"Active"
  },{
    id:"Inactive",
    name:"Inactive"
  }

 ]

 const handleActiveStates = (e) => {
   
   const  data = resData.filter((data) => {
    if(e?.target?.value === "Active" && data.is_active === 1){
        return data
    }if(e?.target?.value === "Inactive" && data.is_active === 0){
      return data
    }if(e?.target?.value === "All"){
      return data
    }
   })

  setFilteredData(data)

 }

  
  const heading = {
    heading1:props?.location?.state?.heading1,
    heading2:props?.location?.state?.heading2,
    clinet_id :props?.location?.state?.info?.user_id,
    client_info : client_info
  } 
  const pathName = `/panel/clients`


   //function to call list api
   const callProjectlistApi =  async () => {
    const res  = await getProjectsList(`admin/project`)
    if(res?.success){
      setFilteredData(res?.data)
      setResData(res?.data)
    //  setFilteredData(res?.data)
    }else{
     console.log("res",res?.message)
    }
 }


 const reload = ()=>window.location.reload();
  
  //function to close modal
  const handleClose = () => {
    setshowModal(false)
  }

  const handleDateFormat = (dateValue) => {

    let date = new Date(dateValue);
    let day =  date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1  < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `${date.getFullYear()}-${month}-${day}`
    return dateMDY
  }

  //function to update status using api. 
  const updateStatus = async () => {
    
    const req_body =
    {
      Project_name: updateData?.Project_name,
      Start_date:  handleDateFormat(updateData?.Start_date),
      End_date: handleDateFormat(updateData?.End_date),
      is_active: statusValue
    }

    const res = await createProject(`admin/project/${updateData?.id}`, req_body);
    if (res?.success) {
      showToast(1, "Status updated successfully");
       setShowStatus(false)
    } else {
      showToast(3, res?.errors?.msg);
      res?.errors?.map((data) => {
        showToast(3, data?.msg);
      })
    }
  }


  const handleStatus = (status,data) => {
    setStatusValue(status)
     setUpdateData(data)
    setShowStatus(true)
    setDeclineStatus("")
  }
  


   //function to close modalDelete
   const modalClose = () => {
    setDeclineStatus("true")
    if(statusValue === 1){
      setStatusValue(0)
    }else{
      setStatusValue(1)
    }
    setShowStatus(false)
  }



  //function call to open edit modal
  const handleEdit = (data,status) => {
    setPredefinedData(data)
    setUpdate(true)
    setModalHeading("Edit Project")
    setshowModal(true)
   
  }

 //function call to open modal
  const handleShowModal = () => {
    setModalHeading("Create Project")
    setUpdate(false)
    setshowModal(true)

  }

 //function to execute onCreate action
  const onCreate = () => {
     callProjectlistApi()
    setshowModal(false)
    
  }

  //function to handle date format.
  const handleDateFormatSearch = (dateValue) => {

    let date = new Date(dateValue);
    let day =  date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`
    let month = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`
    let dateMDY = `-${day}/${month}/${date.getFullYear()}`
    return dateMDY
  }

  
  const handleSearch = (value) => {
  
    const newSearchTerm = value;
  
   if(newSearchTerm === ""){
    callProjectlistApi()  
   }else{
    setSearchItem(newSearchTerm);
    const filterdata = resData.filter((item) => {
      if (item?.organisation_name.toLowerCase()
          .includes(newSearchTerm.toLowerCase()) || item?.Project_name.toLowerCase()
          .includes(newSearchTerm.toLowerCase())   || handleDateFormatSearch(item?.Start_date).includes(newSearchTerm) || handleDateFormatSearch(item?.End_date).includes(newSearchTerm) ) { return item; }
  })

 // setData(filterdata);
  setFilteredData(filterdata)
   }
 
}

const handleSearchClear = () => {
  callProjectlistApi();
}


  return (
    <div class="flex flex-col ml-6 mr-4 w-full h-4/5">
      <div class="flex mt-2 self-start">
        {/* <BreadcrumbsHeader heading1 = {props?.location?.state?.heading1} 
          heading2 = {props?.location?.state?.heading2}
          client_id={id}
        /> */}
        <h1 class="text-1xl font-bold">Projects List</h1>
      </div>
      <div class="flex justify-between my-2  flex-row w-full  ">
      
      <ReusableSearch  onChange = {handleSearch} onClear = {handleSearchClear}  />
        
       <div class=" flex flex-row justify-between w-1/5 h-10">
       {/* <h1 class="w-48" >Apply Filter</h1> */}
       <img src={filterIcon} class="w-6 h-6 mr-2 self-center" />
      <ReusableDropDown data={filterData}
        defaultValue={filterData[0].name}
        
        onChange={(e) =>handleActiveStates(e)}
        />
        </div>

      



      {/* <CustomButton
       children={"Create Project"}
       onClick={handleShowModal}
       classVal="bg-teal-800 text-white w-36 h-10 hover:bg-teal-700 rounded-lg "
      /> */}

      <CustomModal>
        <ModalWithDatePicker
        width = {"w-2/5"}
        height = {"h-5/5"}
        shouldShow={showModal} 
        closeModal={handleClose} 
        title={modelHeading}
        onAction={onCreate}
        update={update}
        client_id={id}
        status={statusValue}
        data={predefinedData}
        />
      </CustomModal>
      </div>

      <div class="w-full h-5/5">
      <MuiCustomTabel
       path={pathName}
       name={heading}
       data={filteredData}
       columnHeader={AllProject}
       onEditClient = { handleEdit }
       client_id={id}
       onStausChange={handleStatus}
       switchDecline= {declineStatus}
       fromProjects  = "AllProjects"
      />
      </div>

      <CustomModal
  >
    <SimpleModal
         title={"Are you sure you want to change status of the Project?"}
       //  description={"By removing this, all the projects will be removed permanently"}
         shouldShow={showStatus}
         closeModal={modalClose} 
         onAction={updateStatus}
       
        />
   </CustomModal>
         
    </div>
  )
}

export default AllProjects


