
//package imports
import React, { useState, useEffect, } from "react";
import { useForm } from "react-hook-form";
import { Checkbox, Grid, IconButton, Paper, TextField } from "@mui/material";

//component imports
import CustomTextField from "../../utils/reusableComponents/CustomTextField";
import { AuthConstants } from "../constants/AuthConstnats";
import { APP_CONSTANTS } from "../constants/AppConstants";
import { useHistory } from "react-router-dom";
import { ValidationConstants } from "../constants/ValidationConstants";
import { createClient,updateClient } from "../../services/apiServices/PostServices";
import { showToast } from '../../utils/constants/ToastConstants';



const ModalWithTextFields = ({ closeModal,update,onAction,data}) => {

    const {
        register,
        handleSubmit,
        setValue,
        control,
        formState: { errors },
        trigger,
    } = useForm({
        defaultValues: {
            checkbox: false,
        },
    });

    const [contactName, setContactName] = useState("")
    const [email, setEmail] = useState("")
    const [clientName, setClientName] = useState("")
    const [mobileNumber, setMobileNumber] = useState("")
    const [address, SetAddress] = useState("")

    useEffect(() => {
        if(update){
            onUpdate()
            setValue("Client",data?.organisation_name)
            setValue("Contact Person Name",data?.name)
            setValue("email",data?.email)
            setValue("Number",data?.mobile)
            setValue("Address",data?.address)
        }else{
            onCreate()
        }
      }, []);



//function to update data for text fileds
const onUpdate = () => {
    setContactName(data?.name)
        setEmail(data?.email)
        setClientName(data?.organisation_name)
        setMobileNumber(data?.mobile)
        SetAddress(data?.address)
}

//function to clear 
const onCreate = () => {
    setContactName("")
        setEmail("")
        setClientName("")
        setMobileNumber("")
        SetAddress("")
}

     
//function to call update api
      const handleUpdate = async() => {
        const req_body = {
            organisation_name: clientName.trimEnd(),
            name : contactName.trimEnd(),
            mobile: mobileNumber,
            email: email.trimEnd(),
            address: address.trimEnd(),
            is_active:1
        }

        const res = await updateClient(`admin/client/${data?.user_id}`,req_body);

        if(res?.success){
        showToast(1, res?.message);
        onAction()
        setContactName("")
        setEmail("")
        setClientName("")
        setMobileNumber("")
        SetAddress("")
        closeModal() 
        }else{
            showToast(3, res?.errors?.msg);
            res?.errors?.map((data) =>{ 
                showToast(3, data?.msg);
            })
        }
      }

//function to call create api
      const handleCreate = async () => {
        const req_body = {
            organisation_name: clientName.trimEnd(),
            name : contactName.trimEnd(),
            mobile: mobileNumber,
            email: email.trimEnd(),
            address: address.trimEnd(),
            is_active:1
        }

        const res = await createClient("admin/client",req_body);
        if(res?.success){
            showToast(1, res?.message);
            onAction()
            closeModal()
            setContactName("")
            setEmail("")
            setClientName("")
            setMobileNumber("")
            SetAddress("")
            // history.push('clients')
        }else{
            showToast(3, res?.errors?.msg);
            res?.errors?.map((data) =>{ 
                showToast(3, data?.msg);
            })
        }
       
       
        
      }


    const onSubmit = () => {
        update ? handleUpdate() : handleCreate()
                       
    }

    const checkString = (string) => {
        let string1=string 
        return   /^[a-zA-Z0-9\s]*$/.test(string1)  
    }

    const checkAddress = (value) => {
        let string1=value 
        return   /^[A-Za-z0-9.,/\\-\s]*$/.test(string1)  
    }

    const handleClientName = (e) => {
        let name = e
        if(checkString(name)){
         setClientName(name.trimStart())
        }
      
    }

    const handleContactName = (value) => {
        let valueName = value 
        if(checkString(valueName)){
            setContactName(valueName.trimStart())
        }
    }

    const handleAdress = (value) => {
        let valueName = value  
        if(checkAddress(valueName)){
            SetAddress(valueName.trimStart())
        }
    }

    




    return (
        <div>
        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <Paper elevation={0} className={`paper_margin`} class=" m-auto my-3">
                <Grid container xs={12} direction={"column"} justifyContent={"center"} class=" flex flex-col ">


                    <div class="flex flex-row flex-wrap justify-between h-2/5 my-2 " >

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class=" w-2/5 mx-5 my-1 ">
                            <CustomTextField
                                control={control}
                                required
                                header={APP_CONSTANTS?.CLIENT_NAME}
                                name="Client"
                                size="small"
                                value={clientName.trimStart()}
                                placeholder={APP_CONSTANTS?.CLIENT_NAME}
                                borderRadius={"0.75em"}
                                inputProps={{minLength : 3 , maxLength: AuthConstants?.CLIENTNAME_MAXLENGTH }}
                                rules={{
                                    required: APP_CONSTANTS?.CLIENT_NAME_REQUIRED,
                                      pattern: {
                                        value:  /^[a-zA-Z\s]+[a-zA-Z0-9,.@!#$%^&*()?:"}{}]*$/,
                                        message: "Please enter valid client name",
                                      },
                                    minLength: {
                                        value: 3,
                                        message: "Enter minimum 3 charaters",
                                      }
                                }}
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    handleClientName(e);
                                }}
                            />
                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class=" w-2/5  mr-10 my-1 ">
                            <CustomTextField
                                control={control}
                                required
                                header={APP_CONSTANTS?.CONTACT_PERSON_NAME}
                                name="Contact Name"
                                size="small"
                                value={contactName.trimStart()}
                                placeholder={APP_CONSTANTS?.CONTACT_PERSON_NAME}
                                borderRadius={"0.75em"}
                                inputProps={{minLength : 3 ,  maxLength: AuthConstants?.CLIENTNAME_MAXLENGTH }}
                                rules={{
                                    required: APP_CONSTANTS?.CONTACT_PERSON_NAME_REQUIRED,
                                    pattern: {
                                        value:  /^[a-zA-Z\s]+[a-zA-Z0-9,.@!#$%^&*()?:"}{}]*$/,
                                        message: "Please enter valid contact person name",
                                      },
                                    minLength: {
                                        value: 3,
                                        message: "Enter minimum 3 charaters",
                                      }
                                }}
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    handleContactName(e);
                                }}
                            />


                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class="w-2/5 mx-5  my-1  ">
                            <CustomTextField
                                control={control}
                                required
                                header={"Email ID"}
                                name="email"
                                size="small"
                                value={email.trimStart()}
                                placeholder={"Email ID*"}
                                borderRadius={"0.75em"}
                                rules={{
                                    required: APP_CONSTANTS?.EMAIL_REQUIRED,
                                    pattern: {
                                        value: ValidationConstants.TypeConstants.EmailType,
                                        message: AuthConstants.NOT_VALID_EMAIL,
                                    },
                                }}
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    setEmail(e);
                                }}
                            />


                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class="w-2/5 mr-10  my-1 ">
                            <CustomTextField
                                control={control}
                                required
                                header={" Mobile Number"}
                                name="Number"
                                size="small"
                                value={mobileNumber.trimStart()}
                                placeholder={"Mobile Number"}
                                borderRadius={"0.75em"}
                                rules={{
                                    required: mobileNumber === "" &&
                                    APP_CONSTANTS?.NUMBER_REQUIRED,
                                    minLength: {
                                        value: 10,
                                        message: "Enter minimum 10 numbers",
                                      }
                                }}
                                inputProps={{
                                    min: 10,
                                    maxLength: AuthConstants?.NUMBER_MAXLENGTH }}
                               
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    const result = e.replace(/\D/g, "");
                                    setMobileNumber(result);
                                }}
                            />


                        </Grid>

                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} class=" w-2/5  self-start mx-5  ">
                            <CustomTextField
                                control={control}
                                required
                                header={"Address"}
                                // maxWidth={"90%"}
                                name="Address"
                                size="small"
                                value={address.trimStart()}
                                placeholder={"Address"}
                                borderRadius={"0.75em"}
                                multiline={true}
                                rows={3}
                                rules={{
                                    required: APP_CONSTANTS?.ADDRESS_REQUIRED_Message,
                                    minLength: {
                                        value: 3,
                                        message: "Enter minimum 3 charaters ",
                                      }
                                }}
                                InputProps={{ sx: { borderRadius: "0.75em" } }}
                                onChange={(e) => {
                                    handleAdress(e);
                                }}
                            />


                        </Grid>

                    </div>

                    <div class="flex justify-center items-center   self-end mr-12 mt-2" >
                       <div>
                        <button onClick={closeModal} class="text-primaryColor border border-primaryColor w-32 h-10 rounded-lg ">
                            Cancel
                        </button>


                        <button type="submit" class="bg-primaryColor w-32 h-10 rounded-lg shadow-md text-white  mx-4" >
                          {update ? "Update"  : " Create" }
                        </button>
                        </div>
                    </div>

                </Grid>
            </Paper>
        </form>
        </div>
    )
}




export default ModalWithTextFields