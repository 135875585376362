
//package imports
import React,{useState,useEffect} from 'react'

//component imports 
import MuiCustomTabel from '../../utils/reusableComponents/MuiCustomTabel';
import CustomButton from '../../utils/reusableComponents/CustomButton';
import { ReusableSearch } from '../../utils/reusableComponents/ReusableSearch';
import { CustomModal } from '../../utils/reusableComponents/CustomModal';
import ModalWithTextFields from '../../utils/reusableComponents/ModalWithTextFields';
import { showToast } from '../../utils/constants/ToastConstants';
import { sample,columnsClient } from '../../utils/constants/TableData';
import SimpleModal from '../../utils/reusableComponents/SimpleModal';
import BreadcrumbsHeader from '../../utils/reusableComponents/BreadCrumbsHeader';
import { getClientsList } from '../../services/apiServices/GetServices';
import { deleteClient } from '../../services/apiServices/PostServices';




const Clients = (props) => {
  const { location } = props;
  const [showModal, setshowModal] = useState(false);
  const [showDelete,setShowDelete]=useState(false);
  const [modelHeading,setModalHeading]=useState("Create Client")
  const [update,setUpdate]=useState(false)
  const [data,setData]=useState([])
  const [predefinedData,setPredefinedData] = useState([])
  const [roleId,setRoleId]=useState("")
  const [userId,setUserId] = useState("")
  const [added,setAdded]=useState(false)
  const [filteredData,setFilteredData]=useState([])
  const [searchTerm, setSearchTerm] = useState('');
  const [clinetData,setClientData]=useState([])

  const heading = "Clients List" 
  const pathName = `${location?.pathname}/clientProject`


  useEffect(() => {
    callClientlistApi()
    // searchClient()
  }, []);

 

  const searchClient = () => {

      let data = clinetData.filter((data) =>  data?.organisation_name.toLowerCase() === props?.location?.state?.NameClient.toLowerCase() )
      console.log("data",data)
      // setFilteredData(data)
  }

 
 
 //function to call list api
  const callClientlistApi =  async () => {
     const res  = await getClientsList("admin/client")
     if(res?.success){
      setFilteredData(res?.data)
      setClientData(res?.data)
     }else{
      console.log("res",res?.message)
     }
  }

  //function to close modal
  const handleClose = () => {
    setshowModal(false)
  }

  //function to close modalDelete
  const modalClose = () => {

    setShowDelete(false)
  }


  //function to handle editModal
  const handleEdit = (data) => {
    setPredefinedData(data)
    setModalHeading("Edit Client")
    setUpdate(true)
    setshowModal(true)
  }

  //function to show modal
  const handleShowModal = () => {
      setModalHeading("Create Client")
      setUpdate(false)
      setshowModal(true)
  }

  //function to handle delete action api  
  const onDeleteAction =  async() => {
    const req_body = {
      user_id : userId
    }

    const res = await deleteClient(`admin/clientStatus`,req_body);
    if(res?.success){
      showToast(1, res?.message);
      callClientlistApi()
      setShowDelete(false)
      }else{
          showToast(3, res?.errors?.msg);
          res?.errors?.map((data) =>{ 
              showToast(3, data?.msg);
          })
    
  }
}


  //function to open show deleteModal
  const handleDelete = (data) => {
    setRoleId(data?.role)
    setUserId(data?.user_id)
    setShowDelete(true)
    
  }

  //function to call create action
  const onCreate = () => {
    callClientlistApi()
  }


  //function for executing search operations
  const handleSearchClick = (event) => {
            
       const searchTerm = event
      
       if(searchTerm === ""){
        callClientlistApi()
       }else{
        setSearchTerm(searchTerm);
        const filterBySearch = clinetData.filter((item) => {
          if (item?.name.toLowerCase()
              .includes(searchTerm.toLowerCase()) || item?.mobile.includes(searchTerm.trim()) || item?.email.toLowerCase().includes(searchTerm.toLowerCase().trim()) || item?.organisation_name.toLowerCase().includes(searchTerm.toLowerCase().trim())) { return item; }
      })
     
     // setData(filterBySearch);
      setFilteredData(filterBySearch)
       }
   
       
   // setFilteredData(filterBySearch)
}

const handleSearchClear = () => {
  callClientlistApi();
  setSearchTerm("")
}



  return (
    <div class="flex flex-col   ml-6  w-full mr-4  ">
      <div class="self-start mt-2">
        <BreadcrumbsHeader heading1={heading} paths = {props?.location?.pathname} />
      </div>
      <div class="flex justify-between my-4  flex-row w-full  ">
      
      <ReusableSearch  onChange = {handleSearchClick} onClear = {handleSearchClear}   />
      <CustomButton
       children={"Create Client"}
       classVal="bg-teal-800 text-white w-36 h-10 hover:bg-teal-700 rounded-lg "
       onClick={handleShowModal}
      />
      </div>

      <div class="w-5/5 h-5/5  ">
      <MuiCustomTabel
       path={pathName}
       name={heading}
       data={filteredData}
       columnHeader={columnsClient}
       onEdit = { handleEdit }
       onDelete = {handleDelete}
       added = {added}
      />
      </div>
      <CustomModal
  >
    <ModalWithTextFields
         width = {"w-3/5"}
         height = {"h-5/5"}
         shouldShow={showModal}
         title={modelHeading}
         closeModal={handleClose} 
         onAction={onCreate}
         update={update}
         data={predefinedData}
    />
   </CustomModal>
   
   <CustomModal
  >
    <SimpleModal
         title={"Are you sure you want to remove?"}
         description={"By removing this, all the projects will be removed permanently"}
         shouldShow={showDelete}
         closeModal={modalClose} 
         onAction={onDeleteAction}
       
        />
   </CustomModal>
     
      
    </div>
  )
}

export default Clients