

const PasswordEncryption = (password) => {
    const secretPass = 'StrId@AhM_@aHM*2023'
    var CryptoJS = require("crypto-js");
   const encriptedPassword =  CryptoJS.AES.encrypt(
        JSON.stringify(password),
        secretPass
      ).toString();

      return encriptedPassword
}

export default PasswordEncryption



